import { useStore } from "../store";
import { useAssumptionsStore } from "../../../stores/useAssumptionsStore";
import { useStatementsStore } from "../../../stores/useStatementsStore";
import { usePriorYearsStore } from "../../../stores/usePriorYearsStore";
import { updateSOFP, updateSOPL, updateSOCF } from "./6updateActual";

export const updatePriorYears = () => {
  const contexts = useStore.getState().contexts;
  const assumptions = useAssumptionsStore.getState().assumptions;
  const statements = useStatementsStore.getState().statements;
  const priorYears = usePriorYearsStore.getState().priorYears;

  // remove deleted context
  const contextIds = contexts.map((context) => context.id);
  for (let i = priorYears.length - 1; i >= 0; i--) {
    if (!contextIds.includes(priorYears[i].contextId)) {
      priorYears.splice(i, 1);
    }
  }

  // add new context
  contexts.forEach((context) => {
    const index = priorYears.findIndex((item) => item.contextId === context.id);
    if (index === -1) {
      priorYears.push({
        contextId: context.id,
        sofp: [],
        sopl: [],
        socf: [],
      });
    }
  });

  // copy over
  priorYears.forEach((_, index) => {
    copyOver("sofp", statements[index], priorYears[index]);
    copyOver("sopl", statements[index], priorYears[index]);
    copyOver("socf", statements[index], priorYears[index]);
  });

  // fill zero
  priorYears.forEach((_, index) => {
    const { priorLength } = assumptions[index];
    updateLength("sofp", priorYears[index], priorLength);
    updateLength("sopl", priorYears[index], priorLength);
    updateLength("socf", priorYears[index], priorLength);
  });

  // calculate totals
  priorYears.forEach((priorYearItem, index) => {
    const { priorLength } = assumptions[index];
    priorYearItem.sofp = updateSOFP(priorYearItem.sofp, priorLength);
    priorYearItem.sopl = updateSOPL(priorYearItem.sopl, priorLength);
    priorYearItem.socf = updateSOCF(priorYearItem.socf, priorLength);
  });
};

export const copyOver = (section, from, dest) => {
  const fromSOFP = from[section];
  // Layer1
  // - [{}]
  // - description
  // - type - array OR object
  // - long - [] OR
  //      Layer2
  //      - id, type, containerDesc, description, long
  const destSOFP = dest[section];

  // 1. if fromLayer1 = 0, destLayer1 = 0 -> do nothing

  // 2. if fromLayer1 = 0, destLayer1 > 0 -> clear destLayer1
  if (fromSOFP.length === 0 && destSOFP.length > 0) {
    dest[section] = [];
  }

  // 3. if fromLayer1 > 0, destLayer1 = 0 -> copy over everything but blank
  if (fromSOFP.length > 0 && destSOFP.length === 0) {
    dest[section] = fromSOFP.map((fromLayer1) => ({
      ...fromLayer1,
      long:
        fromLayer1.type === "array"
          ? []
          : //
            fromLayer1.long.map((fromLayer2) => ({ ...fromLayer2, long: [] })),
    }));
  }

  // 4. if fromLayer1 > 0, destLayer1 > 0 -> keep existing
  if (fromSOFP.length > 0 && destSOFP.length > 0) {
    const destLayer1Map = new Map(dest[section].map((item) => [item.description, item]));
    // fromLayer1 has
    fromSOFP.forEach((fromLayer1) => {
      if (!destLayer1Map.has(fromLayer1.description)) {
        // destLayer1 doesn't have
        dest[section].push({
          ...fromLayer1,
          long:
            fromLayer1.type === "array"
              ? []
              : //
                fromLayer1.long.map((fromLayer2) => ({ ...fromLayer2, long: [] })),
        });
      } else {
        // destLayer1 also has
        const destLayer1 = destLayer1Map.get(fromLayer1.description);
        if (fromLayer1.type === "array") {
          destLayer1.long = [];
        }
        if (fromLayer1.type === "object") {
          const fromLayer2Ids = fromLayer1.long.map((item) => item.id);
          const destLayer2Ids = destLayer1.long.map((item) => item.id);
          // new - add
          fromLayer1.long.forEach((fromLayer2) => {
            if (!destLayer2Ids.includes(fromLayer2.id)) {
              destLayer1.long.push({ ...fromLayer2, long: [] });
            }
          });
          // deleted - remove
          destLayer1.long = destLayer1.long.filter((item) => fromLayer2Ids.includes(item.id));
          // existing - update
          destLayer1.long.forEach((destLayer2, index) => {
            const fromLayer2 = fromLayer1.long[index];
            destLayer2.type = fromLayer2.type;
            destLayer2.containerDesc = fromLayer2.containerDesc;
            destLayer2.description = fromLayer2.description;
          });
        }
      }
    });

    // destLayer1 has, fromLayer1 doesn't have
    dest[section] = dest[section].filter((destLayer1) =>
      fromSOFP.some((fromLayer1) => fromLayer1.description === destLayer1.description)
    );

    // rearrange
    const rearranged = fromSOFP.map((fromLayer1) => {
      return dest[section].find((destLayer1) => destLayer1.description === fromLayer1.description);
    });
    dest[section] = rearranged;
  }
};

export const updateLength = (section, layer0, months) => {
  layer0[section].forEach((layer1) => {
    if (layer1.type === "object") {
      layer1.long.forEach((layer2) => {
        const lengthBefore = layer2.long.length;
        if (lengthBefore < months) {
          layer2.long = [...layer2.long, ...Array.from({ length: months - lengthBefore }, () => 0)];
        } else if (layer2.long.length > months) {
          layer2.long = layer2.long.slice(0, months);
        }
      });
    } else if (layer1.type === "array") {
      const lengthBefore = layer1.long.length;
      if (lengthBefore < months) {
        layer1.long = [...layer1.long, ...Array.from({ length: months - lengthBefore }, () => 0)];
      } else if (layer1.long.length > months) {
        layer1.long = layer1.long.slice(0, months);
      }
    }
  });
};

// export const fillZero = (section, priorYear, priorLength) => {
//   priorYear[section].forEach((layer1) => {
//     if (layer1.type === "object") {
//       layer1.long.forEach((layer2) => {
//         if (layer2.long.length === 0) {
//           layer2.long = Array.from({ length: priorLength }, () => 0);
//         }
//       });
//     } else if (layer1.type === "array") {
//       if (layer1.long.length === 0) {
//         layer1.long = Array.from({ length: priorLength }, () => 0);
//       }
//     }
//   });
// };
