import { InputNumber } from "primereact/inputnumber";
import { Modal, Box } from "@mui/material";
import { MonthPicker } from "./MonthPicker";
import { TableEdit, TableEditStaff, TableEditCapex } from "./Table";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60rem",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: "1rem",
};

const styleH2 = "text-primaryStrong text-2xl font-semibold";
const styleButton =
  "bg-primaryStrong text-white font-semibold px-2 py-1 rounded-md hover:bg-primary transition duration-300";

export const ModalEditNumber = ({
  open,
  handleClose,
  item,
  index,
  setOutputs,
  setCustomLong,
  resetCustomLong,
  assumptions,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className="flex flex-col gap-8">
          <h2 className={styleH2}>Edit {item.description}</h2>

          <div className="flex flex-col gap-2">
            <InputNumberNormal
              item={item.custom}
              index={index}
              ques="Initial Value"
              keyQues="value"
              setOutputs={setOutputs}
            />
            <InputNumberNormal item={item.custom} index={index} ques="Min" keyQues="min" setOutputs={setOutputs} />
            <InputNumberNormal item={item.custom} index={index} ques="Max" keyQues="max" setOutputs={setOutputs} />
            <InputNumberNormal
              item={item.custom}
              index={index}
              ques="Monthly Change (%)"
              keyQues="change"
              setOutputs={setOutputs}
            />
            <MonthPicker item={item.custom} index={index} ques="Start Date" keyQues="start" setOutputs={setOutputs} />
            <div className="flex justify-between items-center">
              <span className="text-secondary font-semibold">Custom Input</span>
              <button className={styleButton} onClick={() => resetCustomLong(index)}>
                Reset
              </button>
            </div>
            <TableEdit item={item} index={index} setCustomLong={setCustomLong} assumptions={assumptions} />
          </div>

          <button className={styleButton} onClick={handleClose}>
            Close
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export const ModalEditStaff = ({
  open,
  handleClose,
  index,
  param,
  paramIndex,
  setOutputs,
  setCustomLong,
  resetCustomLong,
  assumptions,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className="flex flex-col gap-8">
          <h2 className={styleH2}>Edit {param.description}</h2>

          <div className="flex flex-col gap-2">
            <InputNumberStaff
              item={param.custom}
              index={index}
              paramIndex={paramIndex}
              ques="Initial Value"
              keyQues="value"
              setOutputs={setOutputs}
            />
            <InputNumberStaff
              item={param.custom}
              index={index}
              paramIndex={paramIndex}
              ques="Min"
              keyQues="min"
              setOutputs={setOutputs}
            />
            <InputNumberStaff
              item={param.custom}
              index={index}
              paramIndex={paramIndex}
              ques="Max"
              keyQues="max"
              setOutputs={setOutputs}
            />
            <InputNumberStaff
              item={param.custom}
              index={index}
              paramIndex={paramIndex}
              ques="Monthly Change (%)"
              keyQues="change"
              setOutputs={setOutputs}
            />
            <div className="flex justify-between items-center">
              <span className="text-secondary font-semibold">Custom Input</span>
              <button className={styleButton} onClick={() => resetCustomLong(index, paramIndex)}>
                Reset
              </button>
            </div>
            <TableEditStaff
              item={param}
              index={index}
              paramIndex={paramIndex}
              setCustomLong={setCustomLong}
              assumptions={assumptions}
            />
          </div>

          <button className={styleButton} onClick={handleClose}>
            Close
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export const ModalEditFormulas = ({ open, handleClose, item, index, setOutputs }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className="flex flex-col gap-8">
          <h2 className={styleH2}>Edit {item.description}</h2>

          <div className="flex flex-col gap-2">
            <div className="flex">
              <span className="w-60 text-secondary font-semibold">Impact</span>
              <select
                className="w-24 h-10 px-2 text-gray-500 bg-white border border-gray-300"
                name="select"
                value={item.impact}
                disabled={item.type === "Default"}
                onChange={(e) => setOutputs(index, "impact", e.target.value)}
              >
                <option value=""> </option>
                <option value="SOFP">SOFP</option>
                <option value="SOPL">SOPL</option>
                <option value="SOCF">SOCF</option>
              </select>
              {item.calcType === "B/F" && (
                <InputNumberNormal item={item} index={index} ques="bf" keyQues="bf" setOutputs={setOutputs} />
              )}
              {item.calcType === "last12" && (
                <InputNumberNormal item={item} index={index} ques="last12" keyQues="last12" setOutputs={setOutputs} />
              )}
            </div>
          </div>

          <button className={styleButton} onClick={handleClose}>
            Close
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export const ModalEditCapex = ({ open, handleClose, item, index, setCustomLong, resetCustomLong, assumptions }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className="flex flex-col gap-8">
          <h2 className={styleH2}>Edit {item.description}</h2>

          <div className="flex flex-col gap-2">
            <div className="flex justify-between items-center">
              <span className="text-secondary font-semibold">Custom Input</span>
              <button className={styleButton} onClick={() => resetCustomLong(index, "quantityMultiple")}>
                Reset
              </button>
            </div>
            <TableEditCapex item={item} index={index} setCustomLong={setCustomLong} assumptions={assumptions} />
          </div>

          <button className={styleButton} onClick={handleClose}>
            Close
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const numberStyle = "h-10 w-24 px-2 text-gray-500 bg-white border border-gray-300 rounded-md";

const InputNumberNormal = ({ item, index, ques, keyQues, setOutputs }) => {
  return (
    <div className="flex">
      <span className="w-60 text-secondary font-semibold">{ques}</span>
      <InputNumber
        inputClassName={numberStyle}
        name="InputNumberNormal"
        value={item[keyQues]}
        onValueChange={(e) => setOutputs(index, keyQues, parseFloat(e.value))}
        minFractionDigits={0}
        maxFractionDigits={2}
      />
    </div>
  );
};

const InputNumberStaff = ({ item, index, paramIndex, ques, keyQues, setOutputs }) => {
  return (
    <div className="flex">
      <span className="w-60 text-secondary font-semibold">{ques}</span>
      <InputNumber
        inputClassName={numberStyle}
        name="InputNumberStaff"
        value={item[keyQues]}
        onValueChange={(e) => setOutputs(index, paramIndex, keyQues, parseInt(e.value))}
      />
    </div>
  );
};
