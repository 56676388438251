import { create } from "zustand";
import { useStore } from "../pages/flow/store";
import { useAssumptionsStore } from "./useAssumptionsStore";

export const usePriorYearsStore = create((set, get) => ({
  priorYears: [],

  logout() {
    set({ priorYears: [] });
  },

  getPriorLength(node) {
    if (!node) return;

    const priorYears = get().priorYears.find((priorYear) => priorYear.contextId === node.contextId);
    const priorLength = priorYears.priorLength;
    return priorLength;
  },

  updatePriorYearsDetail(data) {
    data.forEach((priorYear) => (priorYear.priorLength = priorYear.headers[0].length - 2));

    set({ priorYears: data });
    const updateAll = useStore.getState().updateAll;
    updateAll();
  },

  updatePriorYearsData(data) {
    set({ priorYears: data });
    const updateAll = useStore.getState().updateAll;
    updateAll();
  },

  addCol(contextId, data) {
    set({ priorYears: data });

    const { assumptions, updateAssumptionsBasic } = useAssumptionsStore.getState();
    const assumption = assumptions.find((item) => item.contextId === contextId);
    const { priorLength, priorNames, priorNumbers } = assumption;
    updateAssumptionsBasic(contextId, "priorLength", priorLength + 1);
    updateAssumptionsBasic(contextId, "priorNames", [...priorNames, "2000-12"]);
    updateAssumptionsBasic(contextId, "priorNumbers", [...priorNumbers, 12]);

    const updateAll = useStore.getState().updateAll;
    updateAll();
  },

  deleteCol(contextId, data, index) {
    set({ priorYears: data });

    const { assumptions, updateAssumptionsBasic } = useAssumptionsStore.getState();
    const assumption = assumptions.find((item) => item.contextId === contextId);
    const { priorLength, priorNames, priorNumbers } = assumption;
    updateAssumptionsBasic(contextId, "priorLength", priorLength - 1);
    updateAssumptionsBasic(
      contextId,
      "priorNames",
      priorNames.filter((_, idx) => idx !== index)
    );
    updateAssumptionsBasic(
      contextId,
      "priorNumbers",
      priorNumbers.filter((_, idx) => idx !== index)
    );

    const updateAll = useStore.getState().updateAll;
    updateAll();
  },
}));
