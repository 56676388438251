import { useStore } from "../../store";

const HeaderName = () => {
  const contextId = useStore((state) => state.contextId);
  const contexts = useStore((state) => state.contexts);
  const context = contexts.find((context) => context.id === contextId);

  return <p className="text-secondary text-lg font-semibold">{context.data.name}</p>;
};

export default HeaderName;
