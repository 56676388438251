import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Header from "./0components/Header/Header";
import Route from "./Route";

const Main = () => {
  return (
    <div
      //
      style={{ height: "calc(100vh - var(--navbar) - 1rem)" }}
      className="flex flex-col mx-8 gap-2"
    >
      <Breadcrumb />
      <Header />
      <Route />
    </div>
  );
};

export default Main;
