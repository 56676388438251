import { styleOuter } from "./1Connect";

const Learn = () => (
  <svg className={styleOuter} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4503_2362)">
      <g clipPath="url(#clip1_4503_2362)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7916 1.1259C16.1821 0.735377 16.8153 0.735377 17.2058 1.1259L20.8725 4.79257C21.263 5.18309 21.263 5.81626 20.8725 6.20678C20.482 6.59731 19.8488 6.59731 19.4583 6.20678L15.7916 2.54011C15.4011 2.14959 15.4011 1.51643 15.7916 1.1259ZM13.2981 3.84423C13.591 3.74906 13.9065 3.74906 14.1994 3.84423C14.4272 3.91827 14.5914 4.04098 14.6972 4.13077C14.7878 4.20768 14.8823 4.30227 14.9588 4.37891C14.9641 4.38418 14.9693 4.38936 14.9744 4.39445L17.6039 7.02402C17.609 7.02911 17.6142 7.03429 17.6195 7.03955C17.6961 7.11612 17.7907 7.21063 17.8676 7.30123C17.9574 7.40699 18.0801 7.57115 18.1542 7.79902C18.2493 8.09192 18.2493 8.40743 18.1542 8.70032C18.0801 8.9282 17.9574 9.09236 17.8676 9.19812C17.7907 9.28872 17.6961 9.38323 17.6195 9.4598C17.6142 9.46506 17.609 9.47024 17.6039 9.47533L8.13166 18.9476C8.11962 18.9597 8.10766 18.9716 8.09577 18.9835C7.92612 19.1534 7.77057 19.3092 7.59008 19.4407C7.43143 19.5563 7.26119 19.6551 7.08211 19.7355C6.87838 19.827 6.66598 19.8847 6.43431 19.9477C6.41807 19.9521 6.40173 19.9566 6.3853 19.9611L2.09517 21.1311C1.74896 21.2255 1.3787 21.1272 1.12495 20.8734C0.871197 20.6197 0.772869 20.2494 0.867291 19.9032L2.03732 15.6131C2.0418 15.5967 2.04625 15.5803 2.05066 15.5641C2.11367 15.3324 2.17144 15.12 2.26291 14.9163C2.34331 14.7372 2.4421 14.567 2.5577 14.4083C2.68921 14.2278 2.84497 14.0723 3.01487 13.9026C3.02677 13.8907 3.03874 13.8788 3.05078 13.8667L12.5231 4.39445C12.5282 4.38936 12.5333 4.38417 12.5386 4.3789C12.6152 4.30226 12.7097 4.20768 12.8003 4.13077C12.906 4.04098 13.0702 3.91827 13.2981 3.84423ZM13.7487 5.99722L4.46499 15.281C4.23857 15.5074 4.20192 15.5479 4.17416 15.586C4.14016 15.6327 4.1111 15.6828 4.08746 15.7354C4.06815 15.7785 4.05111 15.8304 3.96685 16.1393L3.25727 18.7411L5.85906 18.0315C6.168 17.9473 6.21994 17.9302 6.26294 17.9109C6.31561 17.8873 6.36568 17.8582 6.41235 17.8242C6.45045 17.7965 6.49102 17.7598 6.71744 17.5334L16.0012 8.24967L13.7487 5.99722Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4503_2362">
        <rect width="22" height="22" fill="white" />
      </clipPath>
      <clipPath id="clip1_4503_2362">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Learn;
