import { useState } from "react";
import { nanoid } from "nanoid";
import { Reorder } from "framer-motion";
import { Table } from "./Table";
import { ModalEditNumber, ModalEditFormulas } from "./ModalEdit";
import { formatValue } from "./Format";
import { InputStringBlank } from "./InputStringBlank";
import { ButtonAdd, ButtonEdit, ButtonDelete, ButtonDrag } from "./Button";

export const AssignmentsBox = ({
  assignments,
  setAssignsCustom,
  setAssigns,
  addAssign,
  deleteAssign,
  setCustomLong,
  resetCustomLong,
  assumptions,
}) => {
  const { monthsNamesBasic, monthsArrayBasic } = assumptions;

  const handleDragStart = (e, id) => {
    // assignments => formulas
    const assignmentsFormulas = { id: id };

    const info = { assignmentsFormulas: assignmentsFormulas };
    e.dataTransfer.setData("application/json", JSON.stringify(info));
  };

  const [modalState, setModalState] = useState(-1);
  const handleOpenModal = (index) => {
    setModalState(index);
  };
  const handleCloseModal = () => {
    setModalState(-1);
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Month</th>
              {monthsNamesBasic.map((month, index) => (
                <th key={index}>{month}</th>
              ))}
            </tr>
            <tr>
              <th>Utils</th>
              <th>Month</th>
              {monthsArrayBasic.map((month, index) => (
                <th key={index}>{month}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {assignments.map((item, index) => (
              <tr key={index}>
                <td>
                  <ButtonEdit //
                    onClick={() => handleOpenModal(index)}
                    disabled={item.linkCustom === "Link"}
                  />
                  {modalState === index && (
                    <ModalEditNumber
                      open={true}
                      handleClose={handleCloseModal}
                      item={item}
                      index={index}
                      setOutputs={setAssignsCustom}
                      setCustomLong={setCustomLong}
                      resetCustomLong={resetCustomLong}
                      assumptions={assumptions}
                    />
                  )}
                  <ButtonDelete //
                    onClick={() => deleteAssign(index)}
                    disabled={item.type === "Default"}
                  />
                </td>
                <td>
                  <div
                    className="flex-shrink-0 flex items-center justify-center cursor-grab border"
                    draggable
                    onDragStart={(e) => handleDragStart(e, item.id)}
                  >
                    <ButtonDrag />
                    <InputStringBlank
                      value={item.description}
                      onChange={(e) => setAssigns(index, "description", e.target.value)}
                      disabled={item.type === "Default"}
                    />
                  </div>
                </td>
                {item.long.map((value, colIndex) => (
                  <td key={colIndex}>{formatValue(value)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ButtonAdd onClick={addAssign} description="Add Custom Input" />
    </>
  );
};

export const FormulasBox = ({
  formulas,
  setFormulas,
  addFormula,
  deleteFormula,
  addFormulasParam,
  deleteFormulasParam,
  sortParamsIndex,
}) => {
  const handleDrop = (e, index) => {
    e.preventDefault();

    const infoString = e.dataTransfer.getData("application/json");
    const info = JSON.parse(infoString);

    // formulas <= assignments
    if (info.assignmentsFormulas) {
      const toAdd = {
        id: nanoid(),
        linkType: "linkAssign",
        link: info.assignmentsFormulas.id,
      };
      addFormulasParam(index, toAdd);
    }
    // formulas <= operators
    if (info.operatorsFormulas) {
      const toAdd = {
        id: nanoid(),
        linkType: info.operatorsFormulas.linkType,
        description: info.operatorsFormulas.description,
      };
      addFormulasParam(index, toAdd);
    }
    // formulas <= formulas
    if (info.formulasFormulas) {
      const toAdd = {
        id: nanoid(),
        linkType: "linkFormula",
        link: info.formulasFormulas.id,
      };
      addFormulasParam(index, toAdd);
    }
  };

  const handleDragStart = (e, id) => {
    // formulas => formulas
    const formulasFormulas = { id: id };

    const info = { formulasFormulas: formulasFormulas };
    e.dataTransfer.setData("application/json", JSON.stringify(info));
  };

  const [modalState, setModalState] = useState(-1);
  const handleOpenModal = (index) => {
    setModalState(index);
  };
  const handleCloseModal = () => {
    setModalState(-1);
  };

  return (
    <>
      <Operators />
      <div className="overflow-x-auto">
        {formulas.map((item, index) => (
          <div key={index} className="flex w-full h-12 gap-2">
            <div className="flex-shrink-0 flex items-center justify-center">
              <ButtonEdit //
                onClick={() => handleOpenModal(index)}
                disabled={item.type === "Default"}
              />
              {modalState === index && (
                <ModalEditFormulas
                  open={true}
                  handleClose={handleCloseModal}
                  item={item}
                  index={index}
                  setOutputs={setFormulas}
                />
              )}
              <ButtonDelete //
                onClick={() => deleteFormula(index)}
                disabled={item.type === "Default"}
              />
              <select
                className="w-24 h-full px-2 text-gray-500 bg-white border border-gray-300"
                name="select"
                value={item.calcType}
                disabled={item.type === "Default"}
                onChange={(e) => setFormulas(index, "calcType", e.target.value)}
              >
                <option value="Basic">Basic</option>
                <option value="B/F">B/F</option>
                <option value="C/F">C/F</option>
                <option value="last12">last12</option>
              </select>
            </div>

            <div
              className="flex-shrink-0 flex items-center justify-center cursor-grab border"
              draggable
              onDragStart={(e) => handleDragStart(e, item.id)}
            >
              <ButtonDrag />
              <InputStringBlank
                value={item.description}
                onChange={(e) => setFormulas(index, "description", e.target.value)}
                disabled={item.type === "Default"}
              />
            </div>

            <div className="flex-shrink-0 flex items-center justify-center">
              <span className="">=</span>
            </div>

            <div className="flex-grow">
              {item.type === "Default" && (
                <div className="flex items-center h-full px-2 border bg-gray-50 cursor-not-allowed">
                  {item.params.map((param) => (
                    <span key={param.id} className="px-2 whitespace-nowrap">
                      {param.description}
                    </span>
                  ))}
                </div>
              )}
              {item.type === "Custom" && (
                <div className="flex items-center h-full px-2 border" onDrop={(e) => handleDrop(e, index)}>
                  <Reorder.Group
                    axis="x"
                    values={item.params.map((param) => param.id)}
                    onReorder={(sortedIds) => sortParamsIndex(sortedIds, index)}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {item.params.map((param, paramIndex) => (
                      <Reorder.Item key={param.id} value={param.id}>
                        <div className="px-2 py-2 cursor-pointer relative group whitespace-nowrap">
                          <button
                            className={`absolute top-0 right-0 text-red-500 font-extrabold
                              invisible group-hover:visible`}
                            onClick={() => deleteFormulasParam(index, paramIndex)}
                          >
                            &#x2716;
                          </button>
                          <span>
                            <span>{unicode[param.description] ? unicode[param.description] : param.description}</span>
                          </span>
                        </div>
                      </Reorder.Item>
                    ))}
                  </Reorder.Group>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <ButtonAdd onClick={addFormula} description="Add Custom Formula" />
    </>
  );
};

export const OutputsBox = ({ outputs, assumptions }) => {
  return <Table items={outputs} assumptions={assumptions} />;
};

const unicode = {
  "+": "\u002B",
  "-": "\u2212",
  "*": "\u00D7",
  "/": "\u00F7",
  "(": "\u0028",
  ")": "\u0029",
};

const Operators = () => {
  const handleDragStart = (e, linkType, description) => {
    // operators => formulas
    const operatorsFormulas = { linkType: linkType, description: description };

    const info = { operatorsFormulas: operatorsFormulas };
    e.dataTransfer.setData("application/json", JSON.stringify(info));
  };

  const style = "w-12 h-12 rounded-full hover:bg-gray-100";
  const spanStyle = "text-lg font-medium text-gray-500";

  return (
    <div className="flex">
      <button
        className="w-12 h-12 rounded-full hover:bg-gray-100"
        draggable
        onDragStart={(e) => handleDragStart(e, "operator", "+")}
      >
        <span className={spanStyle}>{unicode["+"]}</span>
      </button>
      <button className={style} draggable onDragStart={(e) => handleDragStart(e, "operator", "-")}>
        <span className={spanStyle}>{unicode["-"]}</span>
      </button>
      <button className={style} draggable onDragStart={(e) => handleDragStart(e, "operator", "*")}>
        <span className={spanStyle}>{unicode["*"]}</span>
      </button>
      <button className={style} draggable onDragStart={(e) => handleDragStart(e, "operator", "/")}>
        <span className={spanStyle}>{unicode["/"]}</span>
      </button>
      <button className={style} draggable onDragStart={(e) => handleDragStart(e, "operator", "(")}>
        <span className={spanStyle}>{unicode["("]}</span>
      </button>
      <button className={style} draggable onDragStart={(e) => handleDragStart(e, "operator", ")")}>
        <span className={spanStyle}>{unicode[")"]}</span>
      </button>
      <button className={style} draggable onDragStart={(e) => handleDragStart(e, "constant", "100")}>
        <span className={spanStyle}>100</span>
      </button>
    </div>
  );
};
