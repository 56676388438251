// import SOFP from "./charts/SOFP";
// import SOPL from "./charts/SOPL";
// import SOCF from "./charts/SOCF";
// import Ratios from "./charts/Ratios";

import { useGeneralStore } from "../../stores/useGeneralStore";
import SOFP from "./newCharts/SOFP";
import SOPL from "./newCharts/SOPL";
import SOCF from "./newCharts/SOCF";
import ChartRevenue from "./newCharts/ChartRevenue";
import ChartSOPL from "./newCharts/ChartSOPL";
import ChartCash from "./newCharts/ChartCash";
import CurrentRatio from "./newCharts/CurrentRatio";
import DebtToEquityRatio from "./newCharts/DebtToEquityRatio";
import ROI from "./newCharts/ROI";
import OwnersEquity from "./newCharts/OwnersEquity";

const Dashboard = ({ monthIndexx, assumption, priorYear, difference }) => {
  const { priorLength, monthsNames, monthsNamesBasic } = assumption;
  const showLabel = useGeneralStore((state) => state.showLabel);
  const showPastData = useGeneralStore((state) => state.showPastData);

  let combinedY = {};
  let combinedX = {};
  let monthIndex = 0;
  if (showPastData) {
    combinedX = monthsNames;
    combinedY = combine(difference, priorYear, "sofp");
    combinedY = combine(combinedY, priorYear, "sopl");
    combinedY = combine(combinedY, priorYear, "socf");
    monthIndex = monthIndexx + priorLength;
  } else {
    combinedX = monthsNamesBasic;
    combinedY = difference;
    monthIndex = monthIndexx;
  }

  const styleDefaultGrid = "h-full bg-white rounded-lg shadow-md overflow-auto p-2";

  return (
    <div className="h-full flex flex-col">
      <div className="h-full grid grid-cols-12 grid-rows-12 gap-4">
        <div className={`${styleDefaultGrid} col-span-4 row-span-5`}>
          <SOFP monthIndex={monthIndex} yaxisData={combinedY} />
        </div>
        <div className={`${styleDefaultGrid} col-span-4 row-span-5`}>
          <SOPL monthIndex={monthIndex} yaxisData={combinedY} />
        </div>
        <div className={`${styleDefaultGrid} col-span-4 row-span-5`}>
          <SOCF monthIndex={monthIndex} yaxisData={combinedY} />
        </div>
        <div className={`${styleDefaultGrid} col-span-4 row-span-4`}>
          <ChartSOPL yaxisData={combinedY} xaxisLabels={combinedX} showLabel={showLabel} />
        </div>
        <div className={`${styleDefaultGrid} col-span-4 row-span-4`}>
          <ChartRevenue monthIndex={monthIndex} yaxisData={combinedY} showLabel={showLabel} />
        </div>
        <div className={`${styleDefaultGrid} col-span-4 row-span-4`}>
          <ChartCash yaxisData={combinedY} xaxisLabels={combinedX} showLabel={showLabel} />
        </div>
        <div className={`${styleDefaultGrid} col-span-3 row-span-3`}>
          <CurrentRatio monthIndex={monthIndex} yaxisData={combinedY} />
        </div>
        <div className={`${styleDefaultGrid} col-span-3 row-span-3`}>
          <DebtToEquityRatio monthIndex={monthIndex} yaxisData={combinedY} />
        </div>
        <div className={`${styleDefaultGrid} col-span-3 row-span-3`}>
          <ROI monthIndex={monthIndex} yaxisData={combinedY} />
        </div>
        <div className={`${styleDefaultGrid} col-span-3 row-span-3`}>
          <OwnersEquity monthIndex={monthIndex} yaxisData={combinedY} />
        </div>
        {/* <div className="bg-white rounded-lg shadow-md col-span-4 row-span-1 p-4">
          <SOFP monthIndex={monthIndex} yaxisData={combinedY} xaxisLabels={combinedX} showLabel={showLabel} />
        </div>
        <div className="bg-white rounded-lg shadow-md col-span-4 row-span-4 p-4">
          <Ratios monthIndex={monthIndex} yaxisData={combinedY} />
        </div>

        <div className="bg-white rounded-lg shadow-md col-span-4 row-span-2 p-4">
          <SOPL yaxisData={combinedY} xaxisLabels={combinedX} showLabel={showLabel} />
        </div>

        <div className="bg-white rounded-lg shadow-md col-span-4 row-span-1 p-4">
          <SOCF yaxisData={combinedY} xaxisLabels={combinedX} showLabel={showLabel} />
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;

const combine = (objA, objB, section) => {
  const result = {
    ...objA,
  };

  result[section] = objA[section].map((layer1A, layer1Aindex) => {
    const layer1B = objB[section][layer1Aindex];

    if (layer1A.type === "array" && layer1B.type === "array") {
      return {
        ...layer1A,
        statements: [...layer1B.long, ...layer1A.statements],
        actual: [...layer1B.long, ...layer1A.actual],
        differenceM: [...layer1B.long, ...layer1A.differenceM],
        differenceP: [...layer1B.long, ...layer1A.differenceP],
      };
    }

    if (layer1A.type === "object" && layer1B.type === "object") {
      return {
        ...layer1A,
        long: layer1A.long.map((layer2A, layer2Aindex) => ({
          ...layer2A,
          statements: [...layer1B.long[layer2Aindex].long, ...layer2A.statements],
          actual: [...layer1B.long[layer2Aindex].long, ...layer2A.actual],
          differenceM: [...layer1B.long[layer2Aindex].long, ...layer2A.differenceM],
          differenceP: [...layer1B.long[layer2Aindex].long, ...layer2A.differenceP],
        })),
      };
    }

    return layer1A;
  });

  return result;
};
