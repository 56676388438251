import { useEffect, useState } from "react";
import { useStore } from "../../flow/store";
import { useSaveContext } from "../../../saveAndRestore/useSaveContext";

const EditCompany = ({ setPage }) => {
  const [formData, setFormData] = useState({
    type: "company",
    companyType: "parent",
    name: "",
    industry: "",
    remark: "",
  });

  const { onSave } = useSaveContext();

  const { contextId, contexts, setContextId, editContext } = useStore.getState();
  const context = contexts.find((context) => context.id === contextId);

  useEffect(() => {
    if (context) {
      setFormData({
        type: context.data.type || "company",
        companyType: context.data.companyType || "parent",
        name: context.data.name || "",
        industry: context.data.industry || "",
        remark: context.data.remark || "",
      });
    }
  }, [context]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleEdit = () => {
    editContext(contextId, formData);
    onSave();
    setContextId(null);
    setPage("summary");
  };

  const handleCancel = () => {
    setContextId(null);
    setPage("summary");
  };

  return (
    <div className="flex flex-col h-full p-4 gap-4">
      <div className="border-b pb-4">
        <h1 className="text-2xl font-bold text-secondary">Edit Company</h1>
      </div>

      <div className="flex-grow flex flex-col gap-4">
        <div className="flex flex-col">
          <h3 className="text-secondary font-semibold">Company Type</h3>

          <div className="flex items-center gap-4">
            <input
              className="w-4 h-4"
              id="companyType"
              type="radio"
              value="parent"
              checked={formData.companyType === "parent"}
              onChange={handleChange}
            />
            <span className={`${formData.companyType === "parent" && "text-secondary"}`}>Parent Company</span>
          </div>
          <div className="flex items-center gap-4">
            <input
              type="radio"
              id="companyType"
              className="w-4 h-4"
              value="subsidiary"
              checked={formData.companyType === "subsidiary"}
              onChange={handleChange}
            />
            <span className={`${formData.companyType === "subsidiary" && "text-secondary"}`}>Subsidiary</span>
          </div>
          {/* {formData.companyType === "subsidiary" && (
            <select className="w-96 border border-gray-300 rounded-md p-2">
              <option>example1</option>
              <option>example2</option>
              <option>example3</option>
              <option>example4</option>
              <option>example5</option>
            </select>
          )} */}
        </div>

        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
          <div>
            <h3 className="font-semibold text-secondary">Company Name</h3>
            <input
              type="text"
              id="name"
              autoComplete="name"
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Enter name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>

          <div>
            <h3 className="font-semibold text-secondary">Industry</h3>
            <input
              type="text"
              id="industry"
              autoComplete="industry"
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Enter industry"
              value={formData.industry}
              onChange={handleChange}
            />
          </div>
        </div>

        <div>
          <h3 className="font-semibold text-secondary">Remark</h3>
          <textarea
            className="w-full border border-gray-300 rounded-md p-2 resize-none"
            id="remark"
            placeholder="Enter remark"
            rows="4"
            value={formData.remark}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex justify-between">
        <button
          className="px-8 py-2 border border-primaryStrong text-primaryStrong font-medium rounded-xl hover:bg-primarySuperLight"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className="px-8 py-2 border bg-primaryStrong text-white font-medium rounded-xl hover:bg-primary"
          onClick={handleEdit}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EditCompany;
