const SOFP = ({ monthIndex, yaxisData }) => {
  const taA = yaxisData.sofp.find((item) => item.description === "totalAssetsLong").actual[monthIndex];
  const teA = yaxisData.sofp.find((item) => item.description === "totalEquityLong").actual[monthIndex];
  const tlA = yaxisData.sofp.find((item) => item.description === "totalLiabilitiesLong").actual[monthIndex];

  const taS = yaxisData.sofp.find((item) => item.description === "totalAssetsLong").statements[monthIndex];
  const teS = yaxisData.sofp.find((item) => item.description === "totalEquityLong").statements[monthIndex];
  const tlS = yaxisData.sofp.find((item) => item.description === "totalLiabilitiesLong").statements[monthIndex];

  return (
    <>
      <div className="flex items-center gap-2 pb-4">
        <svg
          className="text-primary h-8 w-auto"
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m16 11.78l4.24-7.33l1.73 1l-5.23 9.05l-6.51-3.75L5.46 19H22v2H2V3h2v14.54L9.5 8z"
          />
        </svg>
        <h3 className="text-lg font-semibold">SOFP (Balance Sheet) Overview</h3>
      </div>
      <p className="text-secondary font-medium text-lg">Actual</p>
      <div className="flex gap-8">
        <div className="flex flex-col">
          <p className="text-gray-500 font-medium">Total Assets</p>
          <p className="text-2xl font-semibold">${taA.toLocaleString()}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-gray-500 font-medium">Total Equity</p>
          <p className="text-2xl font-semibold">${teA.toLocaleString()}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-gray-500 font-medium">Total Liabilities</p>
          <p className="text-2xl font-semibold">${tlA.toLocaleString()}</p>
        </div>
      </div>
      <p className="text-secondary font-medium text-lg">Projection</p>
      <div className="flex gap-8">
        <div className="flex flex-col">
          <p className="text-gray-500 font-medium">Total Assets</p>
          <p className="text-2xl font-semibold">${taS.toLocaleString()}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-gray-500 font-medium">Total Equity</p>
          <p className="text-2xl font-semibold">${teS.toLocaleString()}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-gray-500 font-medium">Total Liabilities</p>
          <p className="text-2xl font-semibold">${tlS.toLocaleString()}</p>
        </div>
      </div>
    </>
  );
};

export default SOFP;
