// import { useGeneralStore } from "../stores/useGeneralStore";
// import Loading from "../components/noSubscription/Loading";
// import NoSubscription from "../components/noSubscription/NoSubscription";
import { Outlet } from "react-router-dom";

const PremiumRoute = () => {
  // const subscription = useGeneralStore((state) => state.subscription);

  // if (!subscription) {
  //   return <Loading />;
  // }

  // if (subscription.name === "Free") {
  //   return <NoSubscription />;
  // }

  return <Outlet />;
};

export default PremiumRoute;
