import { useGeneralStore } from "../../../../stores/useGeneralStore";

const HeaderLastUpdated = () => {
  const lastUpdated = useGeneralStore((state) => state.lastUpdated);

  return (
    <div className="flex gap-2">
      <svg className="h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M12 21q-1.875 0-3.512-.712t-2.85-1.925t-1.925-2.85T3 12t.713-3.512t1.924-2.85t2.85-1.925T12 3q2.05 0 3.888.875T19 6.35V4h2v6h-6V8h2.75q-1.025-1.4-2.525-2.2T12 5Q9.075 5 7.038 7.038T5 12t2.038 4.963T12 19q2.625 0 4.588-1.7T18.9 13h2.05q-.375 3.425-2.937 5.713T12 21m2.8-4.8L11 12.4V7h2v4.6l3.2 3.2z"
        />
      </svg>
      <span className="text-gray-500">Last updated at {lastUpdated}</span>
    </div>
  );
};

export default HeaderLastUpdated;
