import { useCallback, useMemo, useRef } from "react";
import { useStore } from "./store";
import { useDebounce } from "../../hooks/useDebounce";
import { useSnackbarContext } from "../../hooks/SnackbarContext";
import { getColor } from "./components/Panel/colorManipulation";

const FlowHandlers = ({ contextId, onNodesChange, onEdgesChange, autoSave, rightBar, setRightBar }) => {
  const { showMessage } = useSnackbarContext();

  const initialNodes = useStore.getState().nodes;
  const initialEdges = useStore.getState().edges;

  // changes to nodes and edges
  const nodesChangeQueue = useRef([]);
  const edgesChangeQueue = useRef([]);
  const connectQueue = useRef([]);

  const filteredNodes = useMemo(() => {
    return initialNodes.filter((node) => node.contextId === contextId);
  }, [initialNodes, contextId]);
  const filteredEdges = useMemo(() => {
    return initialEdges
      .filter((edge) => edge.contextId === contextId)
      .map((edge) => {
        const type = filteredNodes.find((node) => node.id === edge.source).type;
        const color = getColor(type, 40);
        return {
          ...edge,
          type: "buttonedge",
          animated: true,
          interactionWidth: 20,
          style: { stroke: color, strokeWidth: "0.2rem" },
          className: "edgeShadow",
        };
      });
  }, [initialEdges, contextId, filteredNodes]);

  // Process nodes changes
  const processNodesChanges = useCallback(() => {
    if (nodesChangeQueue.current.length > 0) {
      const storeNodesChange = useStore.getState().onNodesChange;
      storeNodesChange(nodesChangeQueue.current);
      nodesChangeQueue.current = [];
    }
  }, []);

  // Process edges changes
  const processEdgesChanges = useCallback(() => {
    if (edgesChangeQueue.current.length > 0) {
      const storeEdgesChange = useStore.getState().onEdgesChange;
      storeEdgesChange(edgesChangeQueue.current);
      edgesChangeQueue.current = [];
    }
  }, []);

  // Process connect changes
  const processConnectChanges = useCallback(() => {
    if (connectQueue.current.length > 0) {
      const storeAddEdge = useStore.getState().addEdge;
      connectQueue.current.forEach((connection) => {
        const response = storeAddEdge(connection, contextId);
        if (!response.success) {
          showMessage("Cycle detected", "warning");
        }
      });
      connectQueue.current = [];
    }
  }, [contextId, showMessage]);

  // Handle nodes changes
  const [debouncedNodesChangeStore] = useDebounce(processNodesChanges, 300);
  const handleNodesChange = useCallback(
    (changes) => {
      const filteredChanges = changes.filter((change) => change.type !== "remove");

      for (const change of filteredChanges) {
        if (change.type === "select") {
          if (change.selected === false && change.id === rightBar) {
            setRightBar(null);
          }
        }
      }

      onNodesChange(filteredChanges);
      nodesChangeQueue.current.push(...filteredChanges);
      debouncedNodesChangeStore();
      autoSave();
    },
    [onNodesChange, debouncedNodesChangeStore, rightBar, setRightBar, autoSave]
  );

  // Handle edges changes
  const handleEdgesChange = useCallback(
    (changes) => {
      const filteredChanges = changes.filter((change) => change.type !== "remove");

      onEdgesChange(filteredChanges);
      edgesChangeQueue.current.push(...filteredChanges);
      processEdgesChanges();
      autoSave();
    },
    [onEdgesChange, processEdgesChanges, autoSave]
  );

  // Handle delete
  const handleDelete = useCallback(
    (changes) => {
      const { nodes: nodesDelete, edges: edgesDelete } = changes;
      const nonDeletableNode = nodesDelete.find((node) => ["contSofp", "contProfit", "contCash"].includes(node.type));
      if (!nonDeletableNode) {
        const nodesChanges = nodesDelete.map((node) => ({ id: node.id, type: "remove" }));
        const edgesChanges = edgesDelete.map((edge) => ({ id: edge.id, type: "remove" }));

        onNodesChange(nodesChanges);
        nodesChangeQueue.current.push(...nodesChanges);
        debouncedNodesChangeStore();

        onEdgesChange(edgesChanges);
        edgesChangeQueue.current.push(...edgesChanges);
        processEdgesChanges();

        autoSave();
      } else {
        const match = { contSofp: "SOFP", contProfit: "SOPL", contCash: "SOCF" };
        const type = nonDeletableNode.type;

        showMessage(
          `${match[type]} container cannot be deleted.
              SOFP, SOPL and SOCF containers are required for the final reports.`,
          "warning"
        );
      }
    },
    [
      //
      onNodesChange,
      debouncedNodesChangeStore,
      onEdgesChange,
      processEdgesChanges,
      showMessage,
      autoSave,
    ]
  );

  // Handle connect changes
  const handleConnect = useCallback(
    (connection) => {
      connectQueue.current.push(connection);
      processConnectChanges();
      autoSave();
    },
    [processConnectChanges, autoSave]
  );

  return {
    filteredNodes,
    filteredEdges,
    handleNodesChange,
    handleEdgesChange,
    handleDelete,
    handleConnect,
  };
};

export default FlowHandlers;
