import { nonEditable, editable, emptyTextCell, textCell, numberCell } from "../0reactgrid/cells";
import { nanoid } from "nanoid";

import {
  ROW_HEIGHT_28,
  ROW_HEIGHT_34,
  ROW_HEIGHT_40,
  // ROW_HEIGHT_44
} from "../2projection/getRows";

const green = "rgb(22 163 74)";
const red = "rgb(220 38 38)";

const getBlankRow = () => {
  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_28,
      cells: [...Array.from({ length: 1 + 4 }, () => nonEditable(emptyTextCell()))],
    },
  ];
};

const getHeaderRow = () => {
  const className1 = "font-medium bg-blue-800";
  const className2 = "font-medium bg-blue-800 justify-center";
  const style = { color: "rgb(255 255 255)" };

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell("", className1, style)),
        nonEditable(textCell("Projection", className2, style)),
        nonEditable(textCell("Actual", className2, style)),
        nonEditable(textCell("Difference", className2, style)),
        nonEditable(textCell("Difference (%)", className2, style)),
      ],
    },
  ];
};

const getSubheaderRow = ({ title }) => {
  const className1 = "font-semibold";

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell(title, className1)),
        ...Array.from({ length: 4 }, () => nonEditable(emptyTextCell())),
      ],
    },
  ];
};

const getSubSubheaderRow = ({ title }) => {
  const className1 = "text-sm font-semibold";

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_34,
      cells: [
        nonEditable(textCell(title, className1)),
        ...Array.from({ length: 4 }, () => nonEditable(emptyTextCell())),
      ],
    },
  ];
};

const getSubtotalRow = ({ title, long, monthIndex }) => {
  const statements = long.statements[monthIndex];
  const actual = long.actual[monthIndex];
  const differenceM = long.differenceM[monthIndex];
  const differenceP = long.differenceP[monthIndex];

  const className1 = "text-sm font-medium bg-blue-100";
  const style1 = differenceM > 0 ? { color: green } : { color: red };
  const style2 = differenceP > 0 ? { color: green } : { color: red };

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_34,
      cells: [
        nonEditable(textCell(title, className1)),
        nonEditable(numberCell(statements, className1)),
        nonEditable(numberCell(actual, className1)),
        nonEditable(numberCell(differenceM, className1, style1)),
        nonEditable(numberCell(differenceP, className1, style2)),
      ],
    },
  ];

  return row;
};

const getSubSubtotalRow = ({ title, long, monthIndex }) => {
  const statements = long.statements[monthIndex];
  const actual = long.actual[monthIndex];
  const differenceM = long.differenceM[monthIndex];
  const differenceP = long.differenceP[monthIndex];

  const className1 = "text-sm font-medium bg-blue-50";
  const style1 = differenceM > 0 ? { color: green } : { color: red };
  const style2 = differenceP > 0 ? { color: green } : { color: red };

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_34,
      cells: [
        nonEditable(textCell(title, className1)),
        nonEditable(numberCell(statements, className1)),
        nonEditable(numberCell(actual, className1)),
        nonEditable(numberCell(differenceM, className1, style1)),
        nonEditable(numberCell(differenceP, className1, style2)),
      ],
    },
  ];

  return row;
};

const getTotalRow = ({ title, long, monthIndex }) => {
  const statements = long.statements[monthIndex];
  const actual = long.actual[monthIndex];
  const differenceM = long.differenceM[monthIndex];
  const differenceP = long.differenceP[monthIndex];

  const className1 = "font-semibold bg-blue-200";
  const style1 = differenceM > 0 ? { color: green } : { color: red };
  const style2 = differenceP > 0 ? { color: green } : { color: red };

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell(title, className1)),
        nonEditable(numberCell(statements, className1)),
        nonEditable(numberCell(actual, className1)),
        nonEditable(numberCell(differenceM, className1, style1)),
        nonEditable(numberCell(differenceP, className1, style2)),
      ],
    },
  ];

  return row;
};

const getCheckRow = ({ title, long, monthIndex }) => {
  const statements = long.statements[monthIndex];
  const actual = long.actual[monthIndex];
  const differenceM = long.differenceM[monthIndex];
  const differenceP = long.differenceP[monthIndex];

  const className1 = "text-sm font-normal bg-green-100";
  const isCheck1 = statements !== 0 ? "bg-red-100" : "";
  const isCheck2 = actual !== 0 ? "bg-red-100" : "";
  const isCheck3 = differenceM !== 0 ? "bg-red-100" : "";
  const isCheck4 = differenceP !== 0 ? "bg-red-100" : "";

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_28,
      cells: [
        nonEditable(textCell(title, className1)),
        nonEditable(numberCell(statements, `${className1} ${isCheck1}`)),
        nonEditable(numberCell(actual, `${className1} ${isCheck2}`)),
        nonEditable(numberCell(differenceM, `${className1} ${isCheck3}`)),
        nonEditable(numberCell(differenceP, `${className1} ${isCheck4}`)),
      ],
    },
  ];

  return row;
};

const getContentRow = ({ category, description, longs, monthIndex, editView }) => {
  const rows = longs.map((long) => {
    const statements = long.statements[monthIndex];
    const actual = long.actual[monthIndex];
    const differenceM = long.differenceM[monthIndex];
    const differenceP = long.differenceP[monthIndex];

    const className1 = "text-sm font-normal !pl-8";
    const style1 = differenceM > 0 ? { color: green } : { color: red };
    const style2 = differenceP > 0 ? { color: green } : { color: red };

    return {
      rowId: `${category}|${description}|${long.id}`,
      height: ROW_HEIGHT_28,
      cells: [
        nonEditable(textCell(long.description, className1)),
        nonEditable(numberCell(statements, className1)),
        editView === "edit" ? editable(numberCell(actual, className1)) : nonEditable(numberCell(actual, className1)),
        nonEditable(numberCell(differenceM, className1, style1)),
        nonEditable(numberCell(differenceP, className1, style2)),
      ],
    };
  });

  return rows;
};

const getContentSOPLRow = ({ category, description, longs, monthIndex, editView }) => {
  const rows = longs.map((long) => {
    const statements = long.statements[monthIndex];
    const actual = long.actual[monthIndex];
    const differenceM = long.differenceM[monthIndex];
    const differenceP = long.differenceP[monthIndex];

    const className1 = "text-sm font-normal !pl-8";
    const style1 = differenceM > 0 ? { color: green } : { color: red };
    const style2 = differenceP > 0 ? { color: green } : { color: red };

    return {
      rowId: `${category}|${description}|${long.id}`,
      height: ROW_HEIGHT_28,
      cells: [
        nonEditable(textCell(long.containerDesc, className1)),
        nonEditable(numberCell(statements, className1)),
        editView === "edit" ? editable(numberCell(actual, className1)) : nonEditable(numberCell(actual, className1)),
        nonEditable(numberCell(differenceM, className1, style1)),
        nonEditable(numberCell(differenceP, className1, style2)),
      ],
    };
  });

  return rows;
};

export const getRowsSOFP = ({ monthIndex, differences, editView }) => {
  const longProperties = differences.sofp.reduce((acc, item) => {
    if (item.type === "array") {
      acc[item.description] = item;
    } else if (item.type === "object") {
      acc[item.description] = item.long;
    }
    return acc;
  }, {});
  const {
    equityLongs,
    nonCurrentAssetsLongs,
    currentAssetsLongs,
    nonCurrentLiabilitiesLongs,
    currentLiabilitiesLongs,
    totalAssetsLong,
    totalNonCurrentAssetsLong,
    totalCurrentAssetsLong,
    totalEquityLong,
    totalLiabilitiesLong,
    totalNonCurrentLiabilitiesLong,
    totalCurrentLiabilitiesLong,
    checkLong,
  } = longProperties;

  let rows = [...getHeaderRow()];

  // ASSETS
  rows = [...rows, ...getSubheaderRow({ title: "ASSETS" })];

  rows = [
    ...rows,
    ...getSubSubheaderRow({ title: "Non-current assets" }),
    ...getContentRow({
      category: "sofp",
      description: "nonCurrentAssetsLongs",
      longs: nonCurrentAssetsLongs,
      monthIndex,
      editView,
    }),
    ...getSubtotalRow({ title: "Non-current assets", long: totalNonCurrentAssetsLong, monthIndex }),
    ...getBlankRow(),
  ];
  rows = [
    ...rows,
    ...getSubSubheaderRow({ title: "Current assets" }),
    ...getContentRow({
      category: "sofp",
      description: "currentAssetsLongs",
      longs: currentAssetsLongs,
      monthIndex,
      editView,
    }),
    ...getSubtotalRow({ title: "Current assets", long: totalCurrentAssetsLong, monthIndex }),
  ];
  rows = [...rows, ...getTotalRow({ title: "Total Assets", long: totalAssetsLong, monthIndex }), ...getBlankRow()];

  // EQUITY
  rows = [
    ...rows,
    ...getSubheaderRow({ title: "EQUITY" }),
    ...getContentRow({ category: "sofp", description: "equityLongs", longs: equityLongs, monthIndex, editView }),
    ...getTotalRow({ title: "Total Equity", long: totalEquityLong, monthIndex }),
    ...getBlankRow(),
  ];

  // LIABILITIES
  rows = [...rows, ...getSubheaderRow({ title: "LIABILITIES" })];
  rows = [
    ...rows,
    ...getSubSubheaderRow({ title: "Non-current liabilities" }),
    ...getContentRow({
      category: "sofp",
      description: "nonCurrentLiabilitiesLongs",
      longs: nonCurrentLiabilitiesLongs,
      monthIndex,
      editView,
    }),
    ...getSubtotalRow({ title: "Non-current liabilities", long: totalNonCurrentLiabilitiesLong, monthIndex }),
    ...getBlankRow(),
  ];
  rows = [
    ...rows,
    ...getSubSubheaderRow({ title: "Current liabilities" }),
    ...getContentRow({
      category: "sofp",
      description: "currentLiabilitiesLongs",
      longs: currentLiabilitiesLongs,
      monthIndex,
      editView,
    }),
    ...getSubtotalRow({ title: "Current liabilities", long: totalCurrentLiabilitiesLong, monthIndex }),
  ];
  rows = [
    ...rows,
    ...getTotalRow({ title: "Total Liabilities", long: totalLiabilitiesLong, monthIndex }),
    ...getBlankRow(),
  ];

  // OVERALL
  rows = [...rows, ...getCheckRow({ title: "Check", long: checkLong, monthIndex }), ...getBlankRow()];

  return rows;
};

export const getRowsSOPL = ({ monthIndex, differences, editView }) => {
  const longProperties = differences.sopl.reduce((acc, item) => {
    if (item.type === "array") {
      acc[item.description] = item;
    } else if (item.type === "object") {
      acc[item.description] = item.long;
    }
    return acc;
  }, {});
  const {
    revenueLongs,
    expensesLongs,
    intExpLongs,
    taxLongs,
    totalRevenueLong,
    totalExpensesLong,
    totalIntExpLong,
    totalTaxLong,
    totalPbitLong,
    totalPbtLong,
    totalProfitLong,
  } = longProperties;

  let rows = [...getHeaderRow()];

  rows = [...rows, ...getSubSubtotalRow({ title: "Revenue", long: totalRevenueLong, monthIndex })];
  rows = [
    ...rows,
    ...getContentSOPLRow({ category: "sopl", description: "revenueLongs", longs: revenueLongs, monthIndex, editView }),
  ];
  rows = [...rows, ...getSubSubtotalRow({ title: "(-)Expenses", long: totalExpensesLong, monthIndex })];
  rows = [
    ...rows,
    ...getContentSOPLRow({
      category: "sopl",
      description: "expensesLongs",
      longs: expensesLongs,
      monthIndex,
      editView,
    }),
  ];
  rows = [...rows, ...getSubtotalRow({ title: "PBIT", long: totalPbitLong, monthIndex })];
  rows = [...rows, ...getSubSubtotalRow({ title: "(-)Interest expenses", long: totalIntExpLong, monthIndex })];
  rows = [
    ...rows,
    ...getContentSOPLRow({ category: "sopl", description: "intExpLongs", longs: intExpLongs, monthIndex, editView }),
  ];
  rows = [...rows, ...getSubtotalRow({ title: "PBT", long: totalPbtLong, monthIndex })];
  rows = [...rows, ...getSubSubtotalRow({ title: "(-)Tax expense", long: totalTaxLong, monthIndex })];
  rows = [
    ...rows,
    ...getContentSOPLRow({ category: "sopl", description: "taxLongs", longs: taxLongs, monthIndex, editView }),
  ];
  rows = [...rows, ...getTotalRow({ title: "Profit/(Loss)", long: totalProfitLong, monthIndex }), ...getBlankRow()];

  return rows;
};

export const getRowsSOCF = ({ monthIndex, differences, editView }) => {
  const longProperties = differences.socf.reduce((acc, item) => {
    if (item.type === "array") {
      acc[item.description] = item;
    } else if (item.type === "object") {
      acc[item.description] = item.long;
    }
    return acc;
  }, {});
  const {
    operatingLongs,
    investingLongs,
    financingLongs,
    totalOperatingLong,
    totalInvestingLong,
    totalFinancingLong,
    totalLong,
  } = longProperties;

  let rows = [...getHeaderRow()];

  // OPERATING
  rows = [
    ...rows,
    ...getSubSubheaderRow({ title: "Cash flow from operating activities" }),
    ...getContentRow({ category: "socf", description: "operatingLongs", longs: operatingLongs, monthIndex, editView }),
    ...getSubtotalRow({ title: "Cash flow from operating activities", long: totalOperatingLong, monthIndex }),
    ...getBlankRow(),
  ];

  // INVESTING
  rows = [
    ...rows,
    ...getSubSubheaderRow({ title: "Cash flow from investing activities" }),
    ...getContentRow({ category: "socf", description: "investingLongs", longs: investingLongs, monthIndex, editView }),
    ...getSubtotalRow({ title: "Cash flow from investing activities", long: totalInvestingLong, monthIndex }),
    ...getBlankRow(),
  ];

  // FINANCING
  rows = [
    ...rows,
    ...getSubSubheaderRow({ title: "Cash flow from financing activities" }),
    ...getContentRow({ category: "socf", description: "financingLongs", longs: financingLongs, monthIndex, editView }),
    ...getSubtotalRow({ title: "Cash flow from financing activities", long: totalFinancingLong, monthIndex }),
    ...getBlankRow(),
  ];

  rows = [
    ...rows,
    ...getTotalRow({ title: "Total cash flow movement", long: totalLong, monthIndex }),
    ...getBlankRow(),
  ];

  return rows;
};
