import { useGeneralStore } from "../../stores/useGeneralStore";
import { useStore } from "../flow/store";
import { useAssumptionsStore } from "../../stores/useAssumptionsStore";
import { usePriorYearsStore } from "../../stores/usePriorYearsStore";
import { useDifferencesStore } from "../../stores/useDifferencesStore";
import Dashboard from "./Dashboard";
import NoContext from "../../components/noSubscription/NoContext";
import NoContextSelected from "../../components/noSubscription/NoContextSelected";

const Route = () => {
  const monthIndex = useGeneralStore((state) => state.monthIndex);
  const contexts = useStore((state) => state.contexts);
  const contextId = useStore((state) => state.contextId);
  const assumptions = useAssumptionsStore((state) => state.assumptions);
  const priorYears = usePriorYearsStore((state) => state.priorYears);
  const differences = useDifferencesStore((state) => state.differences);

  const assumption = assumptions.find((item) => item.contextId === contextId);
  const priorYear = priorYears.find((item) => item.contextId === contextId);
  const difference = differences.find((item) => item.contextId === contextId);

  if (contexts.length === 0) {
    return <NoContext />;
  }
  if (!contextId || monthIndex === null) {
    return <NoContextSelected />;
  }

  return (
    <div className="flex-grow overflow-y-auto">
      <Dashboard monthIndexx={monthIndex} assumption={assumption} priorYear={priorYear} difference={difference} />
    </div>
  );
};

export default Route;
