import { createContext } from "react";
// import { useDebounce } from "../hooks/useDebounce";
import { useAuthContext } from "../auth/useAuthContext";
import { useAxiosPrivate } from "../axios/useAxiosPrivate";
import { useSnackbarContext } from "../hooks/SnackbarContext";

import { useGeneralStore } from "../stores/useGeneralStore";
import { useStore } from "../pages/flow/store";
import { usePriorYearsStore } from "../stores/usePriorYearsStore";
import { useAssumptionsStore } from "../stores/useAssumptionsStore";
import { useStatementsStore } from "../stores/useStatementsStore";
import { useStatementsAnnualStore } from "../stores/useStatementsAnnualStore";
import { useActualStore } from "../stores/useActualStore";
import { useDifferencesStore } from "../stores/useDifferencesStore";
import { useValuationStore } from "../stores/useValuationStore";

import { create } from "zustand";
export const useSaveStore = create((set, get) => ({
  isSaved: true,
  setIsSaved(isSaved) {
    if (!get().isSaved && !isSaved) return;
    set({ isSaved });
  },
}));

export const SaveContext = createContext(null);

export const SaveContextProvider = ({ children }) => {
  const { user } = useAuthContext();
  const axiosPrivate = useAxiosPrivate();
  const { showMessage } = useSnackbarContext();

  const { isSaved, setIsSaved } = useSaveStore();

  const onSave = async () => {
    if (!user) {
      return;
    }

    try {
      const response1 = await axiosPrivate.post("/api/flows", {
        contexts: useStore.getState().contexts,
        nodes: useStore.getState().nodes,
        edges: useStore.getState().edges,
        priorYears: usePriorYearsStore.getState().priorYears,
        assumptions: useAssumptionsStore.getState().assumptions,
      });
      const response2 = await axiosPrivate.post("/api/statements", {
        statements: useStatementsStore.getState().statements,
        statementsAnnual: useStatementsAnnualStore.getState().statementsAnnual,
        actual: useActualStore.getState().actual,
        differences: useDifferencesStore.getState().differences,
        valuation: useValuationStore.getState().valuation,
      });

      if (response1.status === 200 && response2.status === 200) {
        setIsSaved(true);
        showMessage("Successfully saved!", "success");
        console.log("Success - save");
        console.log("save1", response1.data);
        console.log("save2", response2.data);

        const updatedDate = new Date(response1.data.updated_at).toLocaleString("en-MY", {
          timeZone: "Asia/Kuala_Lumpur",
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
        const formattedDate = updatedDate.replace(":", ".");
        useGeneralStore.setState((prev) => ({
          ...prev,
          lastUpdated: formattedDate ?? prev.lastUpdated,
        }));
      }
    } catch (e) {
      showMessage("Error saving...", "error");
      console.log("SaveContext", e);
    }
  };

  // // auto save in 10 sec (in milliseconds)
  // // const [debouncedSave, cancelDebounce] = useDebounce(onSave, 10 * 1000);
  const autoSave = () => {
    setIsSaved(false);
    // debouncedSave();
  };
  // const autoSave = () => {};
  const manualSave = () => {
    // cancelDebounce();
    onSave();
  };

  const contextValue = {
    isSaved,
    autoSave,
    onSave: manualSave,
  };

  return <SaveContext.Provider value={contextValue}>{children}</SaveContext.Provider>;
};
