import { Routes, Route } from "react-router-dom";

import Main from "./Main";

const ReportRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
    </Routes>
  );
};

export default ReportRoutes;
