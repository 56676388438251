import { useAssumptionsStore } from "../../../stores/useAssumptionsStore";
import { usePriorYearsStore } from "../../../stores/usePriorYearsStore";
import { useSnackbarContext } from "../../../hooks/SnackbarContext";
import { useSaveContext } from "../../../saveAndRestore/useSaveContext";

import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "../0reactgrid/styles.css";
import { getColumns } from "./getColumns";
import { getRowsSOFP } from "./getRows";

export const selector2 = (contextId) => (store) => ({
  updateMonths: (changes) => {
    const convertToYearMonthFormat = (input) => {
      const [month, year] = input.split(" ");
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const monthIndex = monthNames.findIndex((m) => m.toLowerCase() === month.toLowerCase());
      if (monthIndex === -1 || !year) {
        return null;
      }
      const monthNumber = monthIndex + 1;
      const fullYear = `20${year}`;
      return `${fullYear}-${monthNumber}`;
    };

    const updated = store.assumptions.map((layer1) => {
      if (layer1.contextId === contextId) {
        changes.forEach((change) => {
          const col = change.columnId;
          const text = change.newCell.text;

          if (change.rowId === "monthsNames") {
            const newDate = text.match(/^[A-Za-z]{3} \d{2}$/) ? convertToYearMonthFormat(text) : null;

            layer1.priorNames[col] = newDate !== null ? newDate : layer1.priorNames[col];
          } else if (change.rowId === "monthsNumbers") {
            if (text === "") {
              layer1.priorNumbers[col] = 0;
            } else {
              const parsedNumber = parseInt(text);
              if (!isNaN(parsedNumber)) {
                layer1.priorNumbers[col] = parsedNumber;
              }
            }
          }
        });
      }
      return layer1;
    });

    store.updateAssumptionsData(updated);
  },
});

export const selector = (contextId) => (store) => ({
  updateData: (changes) => {
    const updated = store.priorYears.map((layer1) => {
      if (layer1.contextId === contextId) {
        const layer1Sections = changes.reduce((acc, change) => {
          const split = change.rowId.split("|");
          const section = split[0];
          const description = split[1];
          const id = split[2];
          const col = change.columnId;
          const value = change.newCell.value;

          if (!acc[section]) acc[section] = [...layer1[section]];

          acc[section] = acc[section].map((item) =>
            item.description === description
              ? {
                  ...item,
                  long: item.long.map((longItem) =>
                    longItem.id === id
                      ? {
                          ...longItem,
                          long: longItem.long.map((num, index) => (index === col ? value : num)),
                        }
                      : longItem
                  ),
                }
              : item
          );
          return acc;
        }, {});
        return { ...layer1, ...layer1Sections };
      }
      return layer1;
    });

    store.updatePriorYearsData(updated);
  },
  addCol: () => {
    const updated = store.priorYears.map((layer1) => {
      const updatedSections = Object.keys(layer1).reduce((acc, section) => {
        if (Array.isArray(layer1[section])) {
          acc[section] = layer1[section].map((item) => {
            if (item.type === "object") {
              return {
                ...item,
                long: item.long.map((longItem) => ({
                  ...longItem,
                  long: [...longItem.long, 0],
                })),
              };
            } else if (item.type === "array") {
              return {
                ...item,
                long: [...item.long, 0],
              };
            } else {
              return item;
            }
          });
        } else {
          acc[section] = layer1[section];
        }
        return acc;
      }, {});

      return { ...layer1, ...updatedSections };
    });

    store.addCol(contextId, updated);
  },
  deleteCol: (index) => {
    const updated = store.priorYears.map((layer1) => {
      const updatedSections = Object.keys(layer1).reduce((acc, section) => {
        if (Array.isArray(layer1[section])) {
          acc[section] = layer1[section].map((item) => {
            if (item.type === "object") {
              return {
                ...item,
                long: item.long.map((longItem) => ({
                  ...longItem,
                  long: longItem.long.filter((_, idx) => idx !== index),
                })),
              };
            } else if (item.type === "array") {
              return {
                ...item,
                long: item.long.filter((_, idx) => idx !== index),
              };
            } else {
              return item;
            }
          });
        } else {
          acc[section] = layer1[section];
        }
        return acc;
      }, {});

      return { ...layer1, ...updatedSections };
    });

    store.deleteCol(contextId, updated, index);
  },
});

const SOFP = ({ contextId, assumption, priorYear, editView }) => {
  const columns = getColumns({ assumption });
  const rows = getRowsSOFP({ assumption, priorYear, editView });
  const { updateMonths } = useAssumptionsStore(selector2(contextId));
  const { updateData, addCol, deleteCol } = usePriorYearsStore(selector(contextId));
  const { showMessage } = useSnackbarContext();

  const { autoSave } = useSaveContext();
  const handleChanges = (changes) => {
    const monthsChanges = changes.filter(
      (change) => change.rowId === "monthsNames" || change.rowId === "monthsNumbers"
    );
    const otherChanges = changes.filter((change) => change.rowId !== "monthsNames" && change.rowId !== "monthsNumbers");
    if (monthsChanges.length > 0) {
      updateMonths(monthsChanges);
    }
    if (otherChanges.length > 0) {
      updateData(otherChanges);
    }
    autoSave();
  };
  const handleAddCol = () => {
    addCol();
    autoSave();
  };
  const handleDeleteCol = () => {
    if (assumption.priorLength > 1) {
      deleteCol(assumption.priorLength - 1);
    } else {
      showMessage("At least 1 past data must be present.", "warning");
    }
    autoSave();
  };

  return (
    <>
      <>
        <button onClick={handleAddCol}>add col</button>
        <button onClick={handleDeleteCol}>delete col</button>
      </>
      <ReactGrid
        rows={rows}
        columns={columns}
        onCellsChanged={handleChanges}
        stickyTopRows={3}
        stickyLeftColumns={1}
        enableRangeSelection
      />
    </>
  );
};

export default SOFP;
