import { InputNumberTable } from "./InputNumber";
import { formatValue } from "../utils/Format";

export const Table = ({ items, assumptions }) => {
  const { monthsNamesBasic, monthsArrayBasic } = assumptions;

  return (
    <div className="overflow-x-auto">
      <table className="table">
        <thead>
          <tr>
            <th>Month</th>
            {monthsNamesBasic.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
          <tr>
            <th>Month</th>
            {monthsArrayBasic.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td>{item.description}</td>
              {item.long.map((value, colIndex) => (
                <td key={colIndex}>{formatValue(value)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const TableEdit = ({ item, index, setCustomLong, assumptions }) => {
  const { monthsNamesBasic, monthsArrayBasic } = assumptions;

  return (
    <div className="overflow-x-auto">
      <table className="table">
        <thead>
          <tr>
            <th>Month</th>
            {monthsNamesBasic.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
          <tr>
            <th>Month</th>
            {monthsArrayBasic.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Value</td>
            {item.long.map((value, colIndex) => (
              <td key={colIndex}>
                <InputNumberTable
                  value={value}
                  onValueChange={(e) => setCustomLong(index, colIndex, parseFloat(e.target.value))}
                  red={item.longCustom[colIndex] !== null}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const TableEditStaff = ({ item, index, paramIndex, setCustomLong, assumptions }) => {
  const { monthsNamesBasic, monthsArrayBasic } = assumptions;

  return (
    <div className="overflow-x-auto">
      <table className="table">
        <thead>
          <tr>
            <th>Month</th>
            {monthsNamesBasic.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
          <tr>
            <th>Month</th>
            {monthsArrayBasic.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Value</td>
            {item.long.map((value, colIndex) => (
              <td key={colIndex}>
                <InputNumberTable
                  value={value}
                  onValueChange={(e) => setCustomLong(index, paramIndex, colIndex, parseInt(e.target.value))}
                  red={item.longCustom[colIndex] !== null}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const TableEditCapex = ({ item, index, setCustomLong, assumptions }) => {
  const { monthsNamesBasic, monthsArrayBasic } = assumptions;

  return (
    <div className="overflow-x-auto">
      <table className="table">
        <thead>
          <tr>
            <th>Month</th>
            {monthsNamesBasic.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
          <tr>
            <th>Month</th>
            {monthsArrayBasic.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Value</td>
            {item.quantityMultiple.map((value, colIndex) => (
              <td key={colIndex}>
                <InputNumberTable
                  value={value}
                  onValueChange={(e) => setCustomLong(index, "quantityMultiple", colIndex, parseInt(e.target.value))}
                  red={item.quantityMultiple[colIndex] !== null}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
