import { create } from "zustand";

export const useDifferencesStore = create((set, get) => ({
  differences: [],

  logout() {
    set({ differences: [] });
  },

  setDifferences(data) {
    set({ differences: data });
  },
}));
