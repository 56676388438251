import { useState } from "react";
import { ModalEditNumber } from "./ModalEdit";
import { ButtonAdd, ButtonEdit, ButtonDelete } from "./Button";
import { formatValue } from "./Format";
import { InputStringBlank } from "./InputStringBlank";

export const NestedList = ({
  inputsSelf,
  setInputsCustom,
  setInputs,
  addInput,
  deleteInput,
  setCustomLong,
  resetCustomLong,
  assumptions,
  cannotEdit,
}) => {
  const { monthsNamesBasic, monthsArrayBasic } = assumptions;

  const [modalState, setModalState] = useState(-1);
  const handleOpenModal = (index) => {
    setModalState(index);
  };
  const handleCloseModal = () => {
    setModalState(-1);
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Month</th>
              {monthsNamesBasic.map((month, index) => (
                <th key={index}>{month}</th>
              ))}
            </tr>
            <tr>
              <th>Utils</th>
              <th>Month</th>
              {monthsArrayBasic.map((month, index) => (
                <th key={index}>{month}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {inputsSelf.map((item, index) => (
              <tr key={index}>
                <td>
                  <ButtonEdit //
                    onClick={() => handleOpenModal(index)}
                    disabled={cannotEdit}
                  />
                  {modalState === index && (
                    <ModalEditNumber
                      open={true}
                      handleClose={handleCloseModal}
                      item={item}
                      index={index}
                      setOutputs={setInputsCustom}
                      setCustomLong={setCustomLong}
                      resetCustomLong={resetCustomLong}
                      assumptions={assumptions}
                    />
                  )}
                  <ButtonDelete //
                    onClick={() => deleteInput(index)}
                    disabled={cannotEdit}
                  />
                </td>
                <td>
                  <div className="flex">
                    <InputStringBlank
                      value={item.description}
                      onChange={(e) => setInputs(index, "description", e.target.value)}
                      disabled={cannotEdit}
                    />
                  </div>
                </td>
                {item.long.map((value, colIndex) => (
                  <td key={colIndex}>{formatValue(value)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!cannotEdit && (
        <ButtonAdd //
          onClick={addInput}
          description="Add Custom Input"
        />
      )}
    </>
  );
};
