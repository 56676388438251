const Burger = ({ hasSidebar, setHasSidebar }) => (
  <button onClick={() => setHasSidebar(!hasSidebar)}>
    {hasSidebar ? (
      <svg
        className="h-7 w-auto text-secondary"
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
      >
        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M20 20L4 4m16 0L4 20" />
      </svg>
    ) : (
      <svg
        className="h-7 w-auto text-secondary"
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
      >
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 6h18M3 12h18M3 18h18"
        />
      </svg>
    )}
  </button>
);

export default Burger;
