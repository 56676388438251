import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { useDeviceType } from "./hooks/useDeviceType";
import NotAvailableView from "./components/noSubscription/NotAvailableView";

import { useAuthContext } from "./auth/useAuthContext";
import { useLogout } from "./auth/useLogout";
import { useRestore } from "./saveAndRestore/useRestore";

import Redirect from "./authComponents/Redirect";
import Callback from "./authComponents/Callback";
import PersistLogin from "./authComponents/PersistLogin";
import PremiumRoute from "./authComponents/PremiumRoute";

import Navbar from "./components/navbar/Navbar";
import Sidebar from "./components/sidebar/Sidebar";

import DashboardRoutes from "./pages/dashboard/index";
import DirectoryRoutes from "./pages/directory/index";
import FlowRoutes from "./pages/flow/index";
import ReportRoutes from "./pages/reporting/index";
import SubscriptionRoutes from "./pages/subscription/index";

function App() {
  const [hasSidebar, setHasSidebar] = useState(true);
  const deviceType = useDeviceType();

  const { user } = useAuthContext();
  const { logout } = useLogout();
  useRestore(user);

  useEffect(() => {
    const broadcast = new BroadcastChannel("logout");
    broadcast.onmessage = (event) => {
      if (event.data === "logout") {
        logout(false);
      }
    };
    return () => {
      broadcast.close();
    };
  }, [logout]);

  return (
    <>
      <BrowserRouter>
        {deviceType === "mobile" && <NotAvailableView />}
        {deviceType === "tablet" && <NotAvailableView />}
        {deviceType === "desktop" && (
          <>
            <Navbar hasSidebar={hasSidebar} setHasSidebar={setHasSidebar} />
            <div className="flex">
              {hasSidebar && <Sidebar />}
              <div
                style={{
                  height: "calc(100vh - var(--navbar))",
                  width: hasSidebar ? "calc(100% - var(--sidebar))" : "100%",
                }}
                className="transition-all duration-500 ease-in-out"
              >
                <Routes>
                  <Route path="/" element={<Navigate to="/dashboard" />} />
                  <Route path="/redirect" element={<Redirect />} />
                  <Route path="/callback" element={<Callback />} />

                  <Route element={<PersistLogin />}>
                    <Route element={<PremiumRoute />}>
                      <Route path="/dashboard/*" element={<DashboardRoutes />} />
                      <Route path="/directory/*" element={<DirectoryRoutes />} />
                      <Route path="/modeling/*" element={<FlowRoutes />} />
                      <Route path="/report/*" element={<ReportRoutes />} />
                    </Route>
                    <Route path="/subscription/*" element={<SubscriptionRoutes />} />
                  </Route>
                </Routes>
              </div>
            </div>
          </>
        )}
      </BrowserRouter>
    </>
  );
}

export default App;
