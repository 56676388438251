import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "../0reactgrid/styles.css";
import { getColumns } from "./getColumns";
import { getRowsSOFP } from "./getRows";

const SOFP = ({ monthly, assumption, statement, annual }) => {
  const columns = getColumns({ monthly, assumption });
  let rows = [];
  if (monthly) {
    rows = getRowsSOFP({ monthly, assumption, statement });
  } else {
    rows = getRowsSOFP({ monthly, assumption, statement: annual });
  }

  return (
    <ReactGrid //
      rows={rows}
      columns={columns}
      stickyTopRows={3}
      stickyLeftColumns={1}
      enableRangeSelection
    />
  );
};

export default SOFP;
