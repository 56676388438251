import { useStore } from "../../store";
import { OutputsBox } from "../utils/RightBarBox";
import { AssignmentsBoxStaff, BreakdownBox } from "../utils/RightBarBoxSpecial";
import { nanoid } from "nanoid";

import { colors, descriptions, svgs } from "../../components/Panel/constants";
import { lightenColor } from "../../components/Panel/colorManipulation";
import { DisplayIcon } from "./1ContMarketingDetail";
import { DisplaySection, styleOverall, styleDescription } from "./1ContMarketingDetail";

const selector = (id, data) => (store) => ({
  setGeneral: (key, value) => {
    store.updateNode(id, { general: { ...data.general, [key]: value } });
  },
  setAssigns: (index, key, value) => {
    console.log(index, key, value);
    const updated = [...data.assignments];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    if (key === "start") {
      updated[index] = {
        ...updated[index],
        custom: { ...updated[index].custom, [key]: value },
      };
      store.updateNode(id, { assignments: updated });
      return;
    }

    updated[index] = { ...updated[index], [key]: value };
    store.updateNode(id, { assignments: updated });
  },
  setAssignsParam: (index, paramIndex, key, value) => {
    const updated = [...data.assignments];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    if (
      key === "value" &&
      updated[index].params[paramIndex].custom.value === updated[index].params[paramIndex].custom.max
    ) {
      updated[index].params[paramIndex].custom = {
        ...updated[index].params[paramIndex].custom,
        value: value,
        max: value,
      };
      store.updateNode(id, { assignments: updated });
      return;
    }

    updated[index].params[paramIndex].custom = { ...updated[index].params[paramIndex].custom, [key]: value };
    store.updateNode(id, { assignments: updated });
  },
  setCustomLong: (index, paramIndex, colIndex, value) => {
    const updated = [...data.assignments];

    if (isNaN(value) || !isFinite(value)) {
      value = null;
    }

    if (value === null) {
      updated[index].params[paramIndex].longCustom[colIndex] = value;
      store.updateNode(id, { assignments: updated });
      return;
    }

    if (updated[index].params[paramIndex].long[colIndex] !== value) {
      updated[index].params[paramIndex].longCustom[colIndex] = value;
      store.updateNode(id, { assignments: updated });
    }
  },
  resetCustomLong: (index, paramIndex) => {
    const updated = [...data.assignments];

    updated[index].params[paramIndex].longCustom = updated[index].params[paramIndex].longCustom.map(() => null);
    store.updateNode(id, { assignments: updated });
  },
  addAssign: () => {
    const assignmentId = nanoid();

    store.updateNode(id, {
      assignments: [
        ...data.assignments,
        {
          id: assignmentId,
          description: "",
          custom: {
            start: "",
          },
          long: [],
          params: [
            {
              id: `${assignmentId}_0`,
              description: "Number of Staff",
              linkCustom: "Custom",
              custom: {
                value: 0,
                min: 0,
                max: 0,
                change: 0,
              },
              long: [],
              longCustom: [],
            },
            {
              id: `${assignmentId}_1`,
              description: "Salary",
              linkCustom: "Custom",
              custom: {
                value: 0,
                min: 0,
                max: 0,
                change: 0,
              },
              long: [],
              longCustom: [],
            },
            {
              id: `${assignmentId}_2`,
              description: "Burden Rate (%)",
              linkCustom: "Custom",
              custom: {
                value: 0,
                min: 0,
                max: 0,
                change: 0,
              },
              long: [],
              longCustom: [],
            },
          ],
        },
      ],
    });
  },
  deleteAssign: (index) => {
    const updated = [...data.assignments];
    updated.splice(index, 1);
    store.updateNode(id, { assignments: updated });
  },
});

const ContStaffDetail = ({ node, assumption }) => {
  const { id, data, type } = node;
  const {
    setGeneral, //
    setAssigns,
    setAssignsParam,
    setCustomLong,
    resetCustomLong,
    addAssign,
    deleteAssign,
  } = useStore(selector(id, data));

  const iconColor = colors[type];
  const bgColor = lightenColor(iconColor, 80);
  const Icon = svgs[type];

  return (
    <div className={styleOverall}>
      <div>
        <DisplayIcon description={descriptions[type]} Icon={Icon} iconColor={iconColor} bgColor={bgColor} />
      </div>

      <div>
        <DisplaySection description="General" />
        <div className={styleDescription}>
          <span className="font-medium">Description</span>
          <input
            type="text"
            name="text"
            className="h-full w-60 px-2 border-b"
            maxLength={20}
            value={data.general.description}
            onChange={(e) => setGeneral("description", e.target.value)}
          />
        </div>
      </div>

      <div>
        <DisplaySection description="Inputs" />
        <AssignmentsBoxStaff
          assignments={data.assignments}
          setAssigns={setAssigns}
          setAssignsParam={setAssignsParam}
          setCustomLong={setCustomLong}
          resetCustomLong={resetCustomLong}
          addAssign={addAssign}
          deleteAssign={deleteAssign}
          assumptions={assumption}
          individuals={data.individuals}
        />
      </div>

      <div>
        <DisplaySection description="Breakdown" />
        <BreakdownBox //
          assignments={data.assignments}
          assumptions={assumption}
          individuals={data.individuals}
        />
      </div>

      <div>
        <DisplaySection description="Outputs" />
        <OutputsBox outputs={data.outputs} assumptions={assumption} />
      </div>
    </div>
  );
};

export default ContStaffDetail;
