import { nanoid } from "nanoid";

const COL_HEADER_WIDTH = 420;
const COL_CONTENT_WIDTH = 180;

export const getColumns = () => {
  const columns = [
    {
      columnId: nanoid(),
      width: COL_HEADER_WIDTH,
    },
    {
      columnId: nanoid(),
      width: COL_CONTENT_WIDTH,
    },
    {
      columnId: nanoid(),
      width: COL_CONTENT_WIDTH,
    },
    {
      columnId: nanoid(),
      width: COL_CONTENT_WIDTH,
    },
    {
      columnId: nanoid(),
      width: COL_CONTENT_WIDTH,
    },
  ];

  return columns;
};
