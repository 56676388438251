export const styleOuter = "h-8 w-auto text-primary";

const Connect = () => (
  <svg className={styleOuter} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4503_2379)">
      <path
        d="M16.5009 9.16716C16.5009 8.92405 16.4044 8.69089 16.2325 8.51898C16.0605 8.34707 15.8274 8.2505 15.5843 8.2505H4.96011L7.06844 6.15133C7.24105 5.97872 7.33803 5.74461 7.33803 5.5005C7.33803 5.25639 7.24105 5.02227 7.06844 4.84966C6.89583 4.67705 6.66172 4.58008 6.41761 4.58008C6.1735 4.58008 5.93939 4.67705 5.76678 4.84966L2.10011 8.51633C1.97291 8.64523 1.88675 8.80893 1.85249 8.98675C1.81823 9.16457 1.83741 9.34856 1.90761 9.5155C1.97638 9.6829 2.09316 9.8262 2.24323 9.92734C2.39331 10.0285 2.56997 10.0829 2.75094 10.0838H15.5843C15.8274 10.0838 16.0605 9.98725 16.2325 9.81534C16.4044 9.64344 16.5009 9.41028 16.5009 9.16716ZM20.0943 12.4855C20.0255 12.3181 19.9087 12.1748 19.7586 12.0737C19.6086 11.9725 19.4319 11.9181 19.2509 11.9172H6.41761C6.17449 11.9172 5.94134 12.0137 5.76943 12.1856C5.59752 12.3576 5.50094 12.5907 5.50094 12.8338C5.50094 13.0769 5.59752 13.3101 5.76943 13.482C5.94134 13.6539 6.17449 13.7505 6.41761 13.7505H17.0418L14.9334 15.8497C14.8475 15.9349 14.7793 16.0363 14.7328 16.148C14.6863 16.2597 14.6623 16.3795 14.6623 16.5005C14.6623 16.6215 14.6863 16.7413 14.7328 16.853C14.7793 16.9647 14.8475 17.0661 14.9334 17.1513C15.0187 17.2372 15.12 17.3054 15.2317 17.352C15.3434 17.3985 15.4633 17.4225 15.5843 17.4225C15.7053 17.4225 15.8251 17.3985 15.9368 17.352C16.0485 17.3054 16.1499 17.2372 16.2351 17.1513L19.9018 13.4847C20.029 13.3558 20.1151 13.1921 20.1494 13.0142C20.1837 12.8364 20.1645 12.6524 20.0943 12.4855V12.4855Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4503_2379">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Connect;
