import { useStore } from "../../store";
import { OutputsBox } from "../utils/RightBarBox";
import { AssignmentsBoxCapex } from "../utils/RightBarBoxSpecial";
import { nanoid } from "nanoid";

import { colors, descriptions, svgs } from "../../components/Panel/constants";
import { lightenColor } from "../../components/Panel/colorManipulation";
import { DisplayIcon } from "./1ContMarketingDetail";
import { DisplaySection, styleOverall, styleDescription } from "./1ContMarketingDetail";

const selector = (id, data) => (store) => ({
  setGeneral: (key, value) => {
    store.updateNode(id, { general: { ...data.general, [key]: value } });
  },
  setAssigns: (index, key, value) => {
    const updated = [...data.assignments];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    if (key === "type") {
      updated[index] = { ...updated[index], [key]: value, custom: { start: "" } };
      store.updateNode(id, { assignments: updated });
      return;
    }

    if (key === "start") {
      updated[index] = {
        ...updated[index],
        custom: { ...updated[index].custom, [key]: value },
      };
      store.updateNode(id, { assignments: updated });
      return;
    }

    updated[index] = { ...updated[index], [key]: value };
    store.updateNode(id, { assignments: updated });
  },
  setAssignsArray: (index, key, colIndex, value) => {
    const updated = [...data.assignments];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    updated[index][key][colIndex] = value;
    store.updateNode(id, { assignments: updated });
  },
  resetAssignsArray: (index, key) => {
    const updated = [...data.assignments];

    updated[index][key] = updated[index][key].map(() => 0);
    store.updateNode(id, { assignments: updated });
  },
  addAssign: () => {
    store.updateNode(id, {
      assignments: [
        ...data.assignments,
        {
          id: nanoid(),
          description: "",
          type: "Single",
          linkCustom: "Custom",
          quantitySingle: 0,
          quantityMultiple: [],
          value: 0,
          usefulLife: 0,
          residualValue: 0,
          custom: {
            start: "",
          },
        },
      ],
    });
  },
  deleteAssign: (index) => {
    const updated = [...data.assignments];
    updated.splice(index, 1);
    store.updateNode(id, { assignments: updated });
  },
});

const ContCapexDetail = ({ node, assumption }) => {
  const { id, data, type } = node;
  const {
    setGeneral, //
    setAssigns,
    setAssignsArray,
    resetAssignsArray,
    addAssign,
    deleteAssign,
  } = useStore(selector(id, data));

  const iconColor = colors[type];
  const bgColor = lightenColor(iconColor, 80);
  const Icon = svgs[type];

  return (
    <div className={styleOverall}>
      <div>
        <DisplayIcon description={descriptions[type]} Icon={Icon} iconColor={iconColor} bgColor={bgColor} />
      </div>

      <div>
        <DisplaySection description="General" />
        <div className={styleDescription}>
          <span className="font-medium">Description</span>
          <input
            type="text"
            name="text"
            className="h-full w-60 px-2 border-b"
            maxLength={20}
            value={data.general.description}
            onChange={(e) => setGeneral("description", e.target.value)}
          />
        </div>
      </div>

      <div>
        <DisplaySection description="Inputs" />
        <AssignmentsBoxCapex
          assignments={data.assignments}
          setAssigns={setAssigns}
          setAssignsArray={setAssignsArray}
          resetAssignsArray={resetAssignsArray}
          addAssign={addAssign}
          deleteAssign={deleteAssign}
          assumptions={assumption}
          individuals={data.individuals}
        />
      </div>

      <div>
        <DisplaySection description="Outputs" />
        <OutputsBox outputs={data.outputs} assumptions={assumption} />
      </div>
    </div>
  );
};

export default ContCapexDetail;
