import { useStore } from "../../store";
import { selector, style, DisplayIcon } from "./1ContNormalNode";
import { InputNode, DisplayOutputs } from "./NodeIO";

import { colors, svgs } from "../../components/Panel/constants";
import { lightenColor } from "../../components/Panel/colorManipulation";

const ContCapexNode = ({ editView, handleDelete, ...fromReactFlow }) => {
  const { id, data, type } = fromReactFlow;
  const { keepForRerender, setRightBar } = useStore(selector(id, data));
  keepForRerender();

  const handleDeleteClick = () => {
    const contextId = useStore
      .getState()
      .nodes //
      .find((node) => node.id === id).contextId;
    const edges = useStore
      .getState()
      .edges //
      .filter((edge) => edge.contextId === contextId);
    const nodesDelete = [{ id: id, type: type }];
    const edgesDelete = edges
      .filter((edge) => edge.contextId === contextId)
      .filter((edge) => edge.source === id || edge.target === id);

    const changes = { nodes: nodesDelete, edges: edgesDelete };
    handleDelete(changes);
  };

  const iconColor = colors[type];
  const bgColor = lightenColor(iconColor, 80);
  const Icon = svgs[type];

  return (
    <div className={style.node} style={{ borderColor: iconColor }}>
      <DisplayIcon
        description={data.general.description}
        Icon={Icon}
        iconColor={iconColor}
        bgColor={bgColor}
        handleDeleteClick={handleDeleteClick}
        setRightBar={setRightBar}
        editView={editView}
      />

      <>
        <h3 className={style.h3}>Quantity Multiple</h3>
        <div className={style.innerBox} style={{ backgroundColor: bgColor }}>
          {data.assignments.map((item, index) => (
            <div key={index}>
              {item.type === "Multiple" && (
                <InputNode
                  key={index}
                  name={item.description}
                  value={item.quantityMultiple[0]}
                  handleId={item.id}
                  iconColor={iconColor}
                />
              )}
            </div>
          ))}
        </div>
      </>

      <DisplayOutputs outputs={data.outputs} bgColor={bgColor} iconColor={iconColor} />
    </div>
  );
};

export default ContCapexNode;
