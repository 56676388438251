import MascotWatering from "./MascotWatering";
import { Link } from "react-router-dom";

const NoContextSelected = () => {
  return (
    <div className="flex flex-grow flex-col items-center justify-center overflow-y-auto bg-white rounded-3xl">
      <div className="h-60 w-auto">
        <MascotWatering />
      </div>
      <p className="font-semibold text-gray-600 text-lg mt-8 mb-2">Select a company/project for viewing!</p>
      <p className="font-medium text-gray-500 mb-6">Select from the top left corner.</p>
      <p className="font-medium text-gray-500 mb-6">OR</p>
      <Link to="/directory">
        <button className="font-semibold text-primary border border-primary rounded-xl px-8 py-2">
          Manage in Directory
        </button>
      </Link>
    </div>
  );
};

export default NoContextSelected;
