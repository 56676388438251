import Switch from "@mui/material/Switch";
import { useStore } from "../../pages/flow/store";
import { useGeneralStore } from "../../stores/useGeneralStore";

const OnOffShowLabel = () => {
  const contextId = useStore((state) => state.contextId);
  const showLabel = useGeneralStore((state) => state.showLabel);
  const setShowLabel = useGeneralStore.getState().setShowLabel;

  const handleShow = (event) => {
    setShowLabel(event.target.checked);
  };

  return (
    <>
      {contextId && (
        <div className="flex h-16">
          <div className="flex flex-col h-full">
            <p className="text-secondary font-semibold text-sm">Show Label</p>
            <Switch name="switch" checked={showLabel} onChange={handleShow} />
          </div>
        </div>
      )}
    </>
  );
};

export default OnOffShowLabel;
