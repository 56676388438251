import { BaseEdge, EdgeLabelRenderer, getBezierPath } from "@xyflow/react";
import { useStore } from "../store";

const ButtonEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  editView,
  handleDelete,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const onEdgeClick = () => {
    const edges = useStore.getState().edges;
    const edgeToDelete = edges.find((edge) => edge.id === id);
    const changes = { nodes: [], edges: [edgeToDelete] };
    handleDelete(changes);
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      {editView === "edit" && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              pointerEvents: "all",
            }}
            className="nodrag nopan"
          >
            <button
              className={`w-3 h-3 bg-gray-200 flex rounded-full 
              transition-all duration-300 hover:scale-[3]`}
              onClick={onEdgeClick}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="M17 17L7 7m10 0L7 17"
                />
              </svg>
            </button>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default ButtonEdge;
