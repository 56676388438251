import { nanoid } from "nanoid";

export const assignModel = (id, description) => {
  return {
    id: id,
    description: description,
    type: "Default",
    linkCustom: "Custom",
    custom: {
      value: 0,
      min: 0,
      max: 0,
      change: 0,
      start: "",
    },
    long: [],
    longCustom: [],
  };
};

// EASY
//   leads = budgetValue / cplValue;
export const formulaModelEasy = [
  {
    id: "default_formulas_leads",
    description: "Leads",
    type: "Default",
    calcType: "Basic",
    impact: "",
    params: [
      {
        id: nanoid(),
        linkType: "linkAssign",
        link: "default_assignments_budget",
        description: "Budget",
      },
      {
        id: nanoid(),
        linkType: "operator",
        link: "",
        description: "/",
      },
      {
        id: nanoid(),
        linkType: "linkAssign",
        link: "default_assignments_cpl",
        description: "CPL",
      },
    ],
  },
];

// MEDIUM
//   clicks = budgetValue / cpcValue;
//   leads = (clicks * convRateValue) / 100;
export const formulaModelMedium = [
  {
    id: "default_formulas_clicks",
    description: "Clicks",
    type: "Default",
    calcType: "Basic",
    impact: "",
    params: [
      {
        id: nanoid(),
        linkType: "linkAssign",
        link: "default_assignments_budget",
        description: "Budget",
      },
      {
        id: nanoid(),
        linkType: "operator",
        link: "",
        description: "/",
      },
      {
        id: nanoid(),
        linkType: "linkAssign",
        link: "default_assignments_cpc",
        description: "CPC",
      },
    ],
  },
  {
    id: "default_formulas_leads",
    description: "Leads",
    type: "Default",
    calcType: "Basic",
    impact: "",
    params: [
      {
        id: nanoid(),
        linkType: "linkFormula",
        link: "default_formulas_clicks",
        description: "Clicks",
      },
      {
        id: nanoid(),
        linkType: "operator",
        link: "",
        description: "*",
      },
      {
        id: nanoid(),
        linkType: "linkAssign",
        link: "default_assignments_convRate",
        description: "Conv Rate",
      },
      {
        id: nanoid(),
        linkType: "operator",
        link: "",
        description: "/",
      },
      {
        id: nanoid(),
        linkType: "constant",
        link: "",
        description: "100",
      },
    ],
  },
];

// HARD
//   impressions = (budgetValue / cpmValue) * 1000;
//   clicks = (impressions * ctrValue) / 100;
//   leads = (clicks * convRateValue) / 100;
export const formulaModelHard = [
  {
    id: "default_formulas_impressions",
    description: "Impressions",
    type: "Default",
    calcType: "Basic",
    impact: "",
    params: [
      {
        id: nanoid(),
        linkType: "linkAssign",
        link: "default_assignments_budget",
        description: "Budget",
      },
      {
        id: nanoid(),
        linkType: "operator",
        link: "",
        description: "/",
      },
      {
        id: nanoid(),
        linkType: "linkAssign",
        link: "default_assignments_cpm",
        description: "CPM",
      },
      {
        id: nanoid(),
        linkType: "operator",
        link: "",
        description: "*",
      },
      {
        id: nanoid(),
        linkType: "constant",
        link: "",
        description: "1000",
      },
    ],
  },
  {
    id: "default_formulas_clicks",
    description: "Clicks",
    type: "Default",
    calcType: "Basic",
    impact: "",
    params: [
      {
        id: nanoid(),
        linkType: "linkFormula",
        link: "default_formulas_impressions",
        description: "Impressions",
      },
      {
        id: nanoid(),
        linkType: "operator",
        link: "",
        description: "*",
      },
      {
        id: nanoid(),
        linkType: "linkAssign",
        link: "default_assignments_ctr",
        description: "CTR",
      },
      {
        id: nanoid(),
        linkType: "operator",
        link: "",
        description: "/",
      },
      {
        id: nanoid(),
        linkType: "constant",
        link: "",
        description: "100",
      },
    ],
  },
  {
    id: "default_formulas_leads",
    description: "Leads",
    type: "Default",
    calcType: "Basic",
    impact: "",
    params: [
      {
        id: nanoid(),
        linkType: "linkFormula",
        link: "default_formulas_clicks",
        description: "Clicks",
      },
      {
        id: nanoid(),
        linkType: "operator",
        link: "",
        description: "*",
      },
      {
        id: nanoid(),
        linkType: "linkAssign",
        link: "default_assignments_convRate",
        description: "Conv Rate",
      },
      {
        id: nanoid(),
        linkType: "operator",
        link: "",
        description: "/",
      },
      {
        id: nanoid(),
        linkType: "constant",
        link: "",
        description: "100",
      },
    ],
  },
];
