import { styleOuter } from "./1Connect";

const Match = () => (
  <svg className={styleOuter} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4503_3145)">
      <g clipPath="url(#clip1_4503_3145)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5755 0.845932C17.8928 0.897422 18.166 1.0983 18.3098 1.38581L19.0773 2.921L20.6125 3.68859C20.9 3.83235 21.1009 4.10554 21.1524 4.42284C21.2039 4.74013 21.0997 5.06283 20.8724 5.29013L18.1224 8.04013C17.9349 8.22766 17.6805 8.33302 17.4153 8.33302H15.0795L11.7058 11.7067C11.3152 12.0973 10.6821 12.0973 10.2916 11.7067C9.90104 11.3162 9.90104 10.683 10.2916 10.2925L13.6653 6.9188V4.58302C13.6653 4.3178 13.7707 4.06345 13.9582 3.87591L16.7082 1.12591C16.9355 0.898618 17.2582 0.794443 17.5755 0.845932ZM15.6653 6.33302H17.0011L18.4772 4.85697L17.8848 4.56078C17.6913 4.46402 17.5343 4.30709 17.4376 4.11357L17.1414 3.52119L15.6653 4.99723V6.33302ZM10.9987 2.83302C6.48837 2.83302 2.83203 6.48936 2.83203 10.9997C2.83203 15.51 6.48837 19.1664 10.9987 19.1664C15.509 19.1664 19.1654 15.51 19.1654 10.9997C19.1654 10.4474 19.6131 9.99969 20.1654 9.99969C20.7176 9.99969 21.1654 10.4474 21.1654 10.9997C21.1654 16.6146 16.6136 21.1664 10.9987 21.1664C5.3838 21.1664 0.832031 16.6146 0.832031 10.9997C0.832031 5.38479 5.3838 0.83302 10.9987 0.83302C11.551 0.83302 11.9987 1.28074 11.9987 1.83302C11.9987 2.3853 11.551 2.83302 10.9987 2.83302ZM10.9987 7.41635C9.01968 7.41635 7.41536 9.02067 7.41536 10.9997C7.41536 12.9787 9.01968 14.583 10.9987 14.583C12.9777 14.583 14.582 12.9787 14.582 10.9997C14.582 10.4474 15.0297 9.99969 15.582 9.99969C16.1343 9.99969 16.582 10.4474 16.582 10.9997C16.582 14.0833 14.0823 16.583 10.9987 16.583C7.91511 16.583 5.41536 14.0833 5.41536 10.9997C5.41536 7.9161 7.91511 5.41635 10.9987 5.41635C11.551 5.41635 11.9987 5.86407 11.9987 6.41635C11.9987 6.96864 11.551 7.41635 10.9987 7.41635Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4503_3145">
        <rect width="22" height="22" fill="white" />
      </clipPath>
      <clipPath id="clip1_4503_3145">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Match;
