import { useEffect, useState } from "react";
import { useStore } from "../../flow/store";
import { useSaveContext } from "../../../saveAndRestore/useSaveContext";

const EditProject = ({ setPage }) => {
  const [formData, setFormData] = useState({
    type: "project",
    name: "",
    owner: "",
    goal: "",
    audience: "",
    notes: "",
  });

  const { onSave } = useSaveContext();

  const { contextId, contexts, setContextId, editContext } = useStore.getState();
  const context = contexts.find((context) => context.id === contextId);

  useEffect(() => {
    if (context) {
      setFormData({
        type: context.data.type || "project",
        name: context.data.name || "",
        owner: context.data.owner || "",
        goal: context.data.goal || "",
        audience: context.data.audience || "",
        notes: context.data.notes || "",
      });
    }
  }, [context]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleEdit = () => {
    editContext(contextId, formData);
    onSave();
    setContextId(null);
    setPage("summary");
  };

  const handleCancel = () => {
    setContextId(null);
    setPage("summary");
  };

  return (
    <div className="flex flex-col h-full p-4 gap-4">
      <div className="border-b pb-4">
        <h1 className="text-2xl font-bold text-secondary">Edit Project</h1>
      </div>

      <div className="flex-grow flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
          <div>
            <h3 className="font-semibold text-secondary">Project Name</h3>
            <input
              type="text"
              id="name"
              autoComplete="name"
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Enter name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>

          <div>
            <h3 className="font-semibold text-secondary">Project Owner</h3>
            <input
              type="text"
              id="owner"
              autoComplete="owner"
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Enter owner"
              value={formData.owner}
              onChange={handleChange}
            />
          </div>
        </div>

        <div>
          <h3 className="font-semibold text-secondary">Goal</h3>
          <textarea
            className="w-full border border-gray-300 rounded-md p-2 resize-none"
            id="goal"
            placeholder="Enter goal"
            rows="4"
            value={formData.goal}
            onChange={handleChange}
          />
        </div>

        <div>
          <h3 className="font-semibold text-secondary">Audience</h3>
          <textarea
            className="w-full border border-gray-300 rounded-md p-2 resize-none"
            id="audience"
            placeholder="Enter audience"
            rows="4"
            value={formData.audience}
            onChange={handleChange}
          />
        </div>

        <div>
          <h3 className="font-semibold text-secondary">Notes</h3>
          <textarea
            className="w-full border border-gray-300 rounded-md p-2 resize-none"
            id="notes"
            placeholder="Enter notes"
            rows="4"
            value={formData.notes}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex justify-between">
        <button
          className="px-8 py-2 border border-primaryStrong text-primaryStrong font-medium rounded-xl hover:bg-primarySuperLight"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className="px-8 py-2 border bg-primaryStrong text-white font-medium rounded-xl hover:bg-primary"
          onClick={handleEdit}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EditProject;
