import Chart from "react-apexcharts";

const ChartSOPL = ({ yaxisData, xaxisLabels, showLabel }) => {
  const colors = ["#845ef7", "#4CAF50", "#ff9800"];

  const totalRevenueLong = yaxisData.sopl.find((item) => item.description === "totalRevenueLong").statements;
  const totalProfitLong = yaxisData.sopl.find((item) => item.description === "totalProfitLong").statements;
  const np = { totalRevenueLong, totalProfitLong };

  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: colors,
    xaxis: {
      categories: xaxisLabels,
    },
    yaxis: [
      {
        labels: {
          minWidth: 50,
          maxWidth: 50,
          formatter: (value) => formatMoney(value),
        },
      },
    ],
    dataLabels: {
      enabled: showLabel,
      formatter: (value) => formatMoney(value),
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
    },
  };

  const series = [
    {
      name: "Revenue",
      data: np.totalRevenueLong,
    },
    {
      name: "Net Profit",
      data: np.totalProfitLong,
    },
  ];

  return (
    <>
      <Chart height={200} options={options} series={series} type="line" />
    </>
  );
};

export default ChartSOPL;

const formatMoney = (value) => {
  if (!value) return 0;

  const absValue = Math.abs(value);
  const sign = value < 0 ? "-" : "";

  if (absValue >= 1000000) {
    return `${sign}$${(absValue / 1000000).toLocaleString()}M`;
  } else if (absValue >= 1000) {
    return `${sign}$${(absValue / 1000).toLocaleString()}k`;
  } else {
    return `${sign}$${absValue.toLocaleString()}`;
  }
};
