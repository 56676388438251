export const generateLong = (item, start, assumption) => {
  const { value, min, max, change } = item.custom;
  const { long, longCustom } = item;
  const { startDate, months } = assumption;

  let currentValue = value;

  const [forecastStartYear, forecastStartMonth] = startDate.split("-").map(Number);
  const [startYear, startMonth] = start.split("-").map(Number);
  let monthDifference = (startYear - forecastStartYear) * 12 + (startMonth - forecastStartMonth);

  // handle negative month
  if (monthDifference < 0) {
    const catchUpMonths = Math.abs(monthDifference);
    for (let i = 0; i < catchUpMonths; i++) {
      currentValue = currentValue * (1 + change / 100);
    }
    monthDifference = 0;
  }

  // generateLong
  for (let i = 0; i < months; i++) {
    if (i < monthDifference) {
      long[i] = 0;
    } else {
      long[i] = currentValue;
      currentValue = currentValue * (1 + change / 100);
    }
  }

  // check min max
  for (let i = 0; i < months; i++) {
    if (long[i] < min) {
      long[i] = min;
    } else if (long[i] > max) {
      long[i] = max;
    }
  }

  // check custom long
  for (let i = 0; i < months; i++) {
    if (longCustom[i] !== null) {
      long[i] = longCustom[i];
    }
  }

  return long;
};
