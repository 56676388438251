import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const sx = {
  "& .MuiInputBase-root": {
    height: "3rem",
    width: "12rem",
    padding: "0 1rem",
    backgroundColor: "white",
    borderRadius: "0.375rem",
  },
  "& .MuiInputBase-input": {
    padding: "0rem",
    color: "rgb(107 114 128)",
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgb(209 213 219)",
  },
};

export const MonthPicker = ({ item, index, ques, keyQues, setOutputs }) => {
  return (
    <div className="flex">
      <span className="w-60 text-secondary font-semibold">{ques}</span>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["month", "year"]}
          value={dayjs(item[keyQues])}
          onChange={(date) => {
            if (date) {
              const year = date.year();
              const month = date.month() + 1;
              setOutputs(index, keyQues, `${year}-${month}`);
            }
          }}
          sx={sx}
        />
      </LocalizationProvider>
    </div>
  );
};

const sx2 = {
  "& .MuiInputBase-root": {
    height: "2rem",
    width: "12rem",
    padding: "0 1rem",
    backgroundColor: "white",
    borderRadius: "0.375rem",
  },
  "& .MuiInputBase-input": {
    padding: "0rem",
    color: "rgb(107 114 128)",
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgb(209 213 219)",
  },
};

export const MonthPickerBasic = ({ item, index, setOutputs, disabled }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={["month", "year"]}
        value={dayjs(item.custom.start)}
        onChange={(date) => {
          if (date) {
            const year = date.year();
            const month = date.month() + 1;
            setOutputs(index, "start", `${year}-${month}`);
          }
        }}
        sx={sx2}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};
