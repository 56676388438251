import { nonEditable, editable, textCell, decimalCell } from "../0reactgrid/cells";
import { nanoid } from "nanoid";

import {
  ROW_HEIGHT_28,
  ROW_HEIGHT_34,
  // ROW_HEIGHT_40,
  // ROW_HEIGHT_44
} from "../2projection/getRows";

import { getBlankRow, getHeaderRow, getSubheaderRow, getSubSubheaderRow } from "../2projection/getRows";

// multiple

const standard = (title, long, decimal) => {
  const className1 = "text-sm font-normal";

  if (!decimal) {
    decimal = 0;
  }

  return {
    rowId: nanoid(),
    height: ROW_HEIGHT_28,
    cells: [
      nonEditable(textCell(title, className1)),
      ...long.map((value) => nonEditable(decimalCell(value, className1, decimal))),
    ],
  };
};

const result = (title, long, decimal) => {
  const className1 = "text-sm font-medium bg-blue-100";

  if (!decimal) {
    decimal = 0;
  }

  return {
    rowId: nanoid(),
    height: ROW_HEIGHT_34,
    cells: [
      nonEditable(textCell(title, className1)),
      ...long.map((value) => nonEditable(decimalCell(value, className1, decimal))),
    ],
  };
};

const getFcfRow = ({ fcf }) => {
  const rows = [
    standard("EBIT", fcf.ebit),
    standard("- Taxes", fcf.tax),
    standard("+ Depreciation", fcf.addBackDep),
    standard("- Purchase of Capex", fcf.purchaseCapex),
    standard("- Increase in non-cash working capital (W1)", fcf.incWcNeg),
    result("FCF", fcf.fcf),
  ];

  return rows;
};

const getWcRow = ({ fcf }) => {
  const rows = [
    standard("Current assets", fcf.ca),
    standard("- Cash", fcf.cash),
    standard("- Current liabilities", fcf.cl),
    result("WC", fcf.wc),
  ];

  return rows;
};

export const getRowsFcf = ({ assumption, valuation }) => {
  const { priorLength, totalPeriod } = assumption;
  const months = totalPeriod - priorLength;
  const monthly = false;

  let rows = [
    ...getHeaderRow({ monthly, assumption }),

    ...getSubheaderRow({ months, title: "Free Cash Flow calculation" }),
    ...getFcfRow({ fcf: valuation.fcf }),
    ...getBlankRow({ months }),

    ...getSubheaderRow({ months, title: "W1 – Non-cash working capital" }),
    ...getWcRow({ fcf: valuation.fcf }),
    ...getBlankRow({ months }),
  ];

  return rows;
};

const getPvFcfRow = ({ discounted }) => {
  const rows = [
    standard("FCF", discounted.fcf),
    standard("Discount factor (1 / ((1 + WACC) ^ period))", discounted.df, 3),
    result("PV of FCF", discounted.pv),
  ];

  return rows;
};

export const getRowsDiscounted = ({ assumption, valuation }) => {
  const { priorLength, totalPeriod } = assumption;
  const months = totalPeriod - priorLength;
  const monthly = false;

  let rows = [
    ...getHeaderRow({ monthly, assumption }),

    ...getSubheaderRow({ months, title: "PV of FCF calculation" }),
    ...getPvFcfRow({ discounted: valuation.discounted }),
    ...getBlankRow({ months }),
  ];

  return rows;
};

// single

const standard2 = (title, value, canEdit, rowId, decimal, editView) => {
  const className1 = "text-sm font-normal";
  const className2 = "text-sm font-normal";

  return {
    rowId: rowId,
    height: ROW_HEIGHT_28,
    cells: [
      nonEditable(textCell(title, className1)),
      canEdit && editView === "edit"
        ? editable(decimalCell(value, className1, decimal))
        : nonEditable(decimalCell(value, className2, decimal)),
    ],
  };
};

const result2 = (title, value, decimal) => {
  const className1 = "text-sm font-medium bg-blue-100";

  return {
    rowId: nanoid(),
    height: ROW_HEIGHT_34,
    cells: [nonEditable(textCell(title, className1)), nonEditable(decimalCell(value, className1, decimal))],
  };
};

const getWaccStructureRow = ({ wacc, editView }) => {
  const rows = [
    standard2("Equity (Eq)", wacc.equity, true, "wacc.equity", 0, editView),
    standard2("Debt (De)", wacc.debt, true, "wacc.debt", 0, editView),
    standard2("E/(D+E) (%)", wacc.equityP, false, nanoid(), 2),
    standard2("D/(D+E) (%)", wacc.debtP, false, nanoid(), 2),
  ];

  return rows;
};

const getWaccEquityRow = ({ wacc, editView }) => {
  const rows = [
    standard2("Risk free rate (%) (A)", wacc.riskFreeRate, true, "wacc.riskFreeRate", 2, editView),
    standard2("Expected market return (%) (B)", wacc.marketReturn, true, "wacc.marketReturn", 2, editView),
    standard2("Market risk premium (%) (C = A - B)", wacc.marketPremium, false, nanoid(), 2),
    standard2("Beta (D)", wacc.beta, true, "wacc.beta", 2),
    result2("Cost of equity (%) (Ce = A + (D x C))", wacc.costOfEquity, 2),
  ];

  return rows;
};

const getWaccDebtRow = ({ wacc, editView }) => {
  const rows = [
    standard2("Cost of debt (%) (E)", wacc.costOfDebt, true, "wacc.costOfDebt", 2, editView),
    standard2("Tax rate (%) (F)", wacc.taxRate, true, "wacc.taxRate", 2, editView),
    result2("After tax cost of debt (%) (Cd = E x (1 - F))", wacc.costOfDebtAfterTax, 2),
  ];

  return rows;
};

const getWaccWaccRow = ({ wacc }) => {
  const rows = [result2("WACC (%) = (Eq x Ce) + (De x Cd)", wacc.wacc, 2)];

  return rows;
};

export const getRowsWacc = ({ valuation, editView }) => {
  const months = 1;
  let rows = [
    ...getSubheaderRow({ months, title: "WACC calculation" }),

    ...getSubSubheaderRow({ months, title: "Structure" }),
    ...getWaccStructureRow({ wacc: valuation.wacc, editView }),
    ...getBlankRow({ months }),

    ...getSubSubheaderRow({ months, title: "Cost of equity (CAPM)" }),
    ...getWaccEquityRow({ wacc: valuation.wacc, editView }),
    ...getBlankRow({ months }),

    ...getSubSubheaderRow({ months, title: "Cost of debt" }),
    ...getWaccDebtRow({ wacc: valuation.wacc, editView }),
    ...getBlankRow({ months }),

    ...getWaccWaccRow({ wacc: valuation.wacc, editView }),
    ...getBlankRow({ months }),
  ];

  return rows;
};

const getTvRow = ({ tv, editView }) => {
  const rows = [
    standard2("WACC (%)", tv.wacc, false, nanoid(), 2),
    standard2("Growth rate (%)", tv.growth, true, "tv.growth", 2, editView),
    standard2("Terminal value (Final FCF x (1 + g) / (WACC – g)", tv.terminal, false, nanoid(), 0),
    result2("PV of Terminal value (TV * Final DF)", tv.terminalPv, 0),
  ];

  return rows;
};

export const getRowsTv = ({ valuation, editView }) => {
  const months = 1;
  let rows = [
    ...getSubheaderRow({ months, title: "TV calculation" }),

    ...getTvRow({ tv: valuation.tv, editView }),
    ...getBlankRow({ months }),
  ];

  return rows;
};

const getEnterpriseRow = ({ enterprise }) => {
  const rows = [
    standard2("Sum of PV of FCF", enterprise.fcfPvSum, false, nanoid(), 0),
    standard2("PV of Terminal value", enterprise.terminalPv, false, nanoid(), 0),
    result2("Enterprise Value", enterprise.enterprise, 0),
  ];

  return rows;
};

export const getRowsEnterprise = ({ valuation }) => {
  const months = 1;
  let rows = [
    ...getSubheaderRow({ months, title: "Enterprise Value calculation" }),

    ...getEnterpriseRow({ enterprise: valuation.enterprise }),
    ...getBlankRow({ months }),
  ];

  return rows;
};
