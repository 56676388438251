const SOCF = ({ monthIndex, yaxisData }) => {
  const currentAssetsLongs = yaxisData.sofp.find((item) => item.description === "currentAssetsLongs").long;
  const cashALong = currentAssetsLongs.find((item) => item.description === "Cash c/f");
  const cashSLong = currentAssetsLongs.find((item) => item.description === "Cash c/f");
  if (!cashALong || !cashSLong) {
    return <p className="text-red-500">Reminder to link your Cash container to SOFP's Assets!</p>;
  }
  const cashA = cashALong.actual[monthIndex];
  const cashS = cashSLong.statements[monthIndex];

  return (
    <>
      <div className="flex items-center gap-2 pb-4">
        <svg
          className="text-primary h-8 w-auto"
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m16 11.78l4.24-7.33l1.73 1l-5.23 9.05l-6.51-3.75L5.46 19H22v2H2V3h2v14.54L9.5 8z"
          />
        </svg>
        <h3 className="text-lg font-semibold">SOCF (Cash Flow) Overview</h3>
      </div>
      <p className="text-secondary font-medium text-lg">Actual</p>
      <div className="flex gap-8">
        <div className="flex flex-col">
          <p className="text-gray-500 font-medium">Cash Balance</p>
          <p className="text-2xl font-semibold">${cashA.toLocaleString()}</p>
        </div>
      </div>
      <p className="text-secondary font-medium text-lg">Projection</p>
      <div className="flex gap-8">
        <div className="flex flex-col">
          <p className="text-gray-500 font-medium">Cash Balance</p>
          <p className="text-2xl font-semibold">${cashS.toLocaleString()}</p>
        </div>
      </div>
    </>
  );
};

export default SOCF;
