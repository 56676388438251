export const sortById = (items, ids) => {
  return items.filter((item) => ids.includes(item.id)).sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
};

export const topologicalSortFormulas = (formulas) => {
  const graph = {};
  const inDegree = {};

  formulas.forEach((formula) => {
    graph[formula.id] = [];
    inDegree[formula.id] = 0;
  });

  const formulasLink = formulas.filter((formula) => formula.params.some((param) => param.linkType === "linkFormula"));

  formulasLink.forEach((formula) => {
    formula.params.forEach((param) => {
      if (param.linkType === "linkFormula") {
        graph[param.link].push(formula.id);
        inDegree[formula.id]++;
      }
    });
  });

  const queue = [];
  for (let formulaId in inDegree) {
    if (inDegree[formulaId] === 0) {
      queue.push(formulaId);
    }
  }

  const sortedFormulasIds = [];
  while (queue.length > 0) {
    const current = queue.shift();
    sortedFormulasIds.push(current);
    graph[current].forEach((neighbor) => {
      inDegree[neighbor]--;
      if (inDegree[neighbor] === 0) {
        queue.push(neighbor);
      }
    });
  }

  if (sortedFormulasIds.length !== formulas.length) {
    const remainingFormulas = formulas.map((formula) => formula.id).filter((id) => !sortedFormulasIds.includes(id));
    const remainingSorted = remainingFormulas.sort((a, b) => {
      const formulaA = formulas.find((formula) => formula.id === a);
      const formulaB = formulas.find((formula) => formula.id === b);
      if (formulaA.calcType === "B/F" && formulaB.calcType !== "B/F") {
        return -1;
      }
      if (formulaA.calcType !== "B/F" && formulaB.calcType === "B/F") {
        return 1;
      }
      return 0;
    });
    sortedFormulasIds.push(...remainingSorted);
  }

  return sortedFormulasIds;
};
