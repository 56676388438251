const FlowTab = ({ tab, setTab }) => {
  return (
    <div
      //
      style={{ height: "3rem" }}
      className="flex px-4 text-sm font-medium text-gray-400 gap-2"
    >
      <button
        className={`flex items-center px-6 border-b-2 
          hover:text-primaryStrong hover:border-primaryStrong 
          ${tab === "Build" && "text-primaryStrong border-primaryStrong"}`}
        onClick={() => setTab("Build")}
      >
        Builder
      </button>
      <button
        className={`flex items-center px-6 border-b-2 
          hover:text-primaryStrong hover:border-primaryStrong 
          ${tab === "Settings" && "text-primaryStrong border-primaryStrong"}`}
        onClick={() => setTab("Settings")}
      >
        Settings
      </button>
      <button
        className={`flex items-center px-6 border-b-2 
          hover:text-primaryStrong hover:border-primaryStrong 
          ${tab === "Help" && "text-primaryStrong border-primaryStrong"}`}
        onClick={() => setTab("Help")}
      >
        Help
      </button>
    </div>
  );
};

export default FlowTab;
