export const colors = {
  contBlank: "#000000",
  contMarketing: "#007bff",
  contSales: "#2dc5b8",
  contRevenue: "#2dc5b8",
  contStaff: "#3abeff",
  contDirectCosts: "#ff3366",
  contIndirectCosts: "#ff3366",
  contExpenses: "#ff3366",
  contCapex: "#ff4500",
  contLoan: "#ff4500",
  contReceivables: "#32CD32",
  contPayables: "#32CD32",
  contShareCap: "#7d6aeb",
  contRetained: "#7d6aeb",
  contSofp: "#fd9235",
  contProfit: "#fd9235",
  contCash: "#fd9235",
};

export const descriptions = {
  contBlank: "Blank",
  contMarketing: "Marketing",
  contSales: "Sales",
  contRevenue: "Revenue",
  contStaff: "Staff",
  contDirectCosts: "Direct Costs",
  contIndirectCosts: "Indirect Costs",
  contExpenses: "Expenses",
  contCapex: "CAPEX",
  contLoan: "Loan",
  contReceivables: "Receivables",
  contPayables: "Payables",
  contShareCap: "Share Capital",
  contRetained: "Retained Earnings",
  contSofp: "SOFP\n(Balance Sheet)",
  contProfit: "SOPL\n(Profit/Loss)",
  contCash: "SOCF\n(Cash Flow)",
};

export const adds = {
  contBlank: "addContBlank",
  contMarketing: "addContMarketing",
  contSales: "addContSales",
  contRevenue: "addContRevenue",
  contStaff: "addContStaff",
  contDirectCosts: "addContDirectCosts",
  contIndirectCosts: "addContIndirectCosts",
  contExpenses: "addContExpenses",
  contCapex: "addContCapex",
  contLoan: "addContLoan",
  contReceivables: "addContReceivables",
  contPayables: "addContPayables",
  contShareCap: "addContShareCap",
  contRetained: "addContRetained",
  contSofp: "addContSofp",
  contProfit: "addContProfit",
  contCash: "addContCash",
};

export const svgs = {
  contBlank: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M10.998 1.58a2 2 0 0 1 2.004 0l7.5 4.342a2 2 0 0 1 .998 1.731v8.694a2 2 0 0 1-.998 1.73l-7.5 4.343a2 2 0 0 1-2.004 0l-7.5-4.342a2 2 0 0 1-.998-1.731V7.653a2 2 0 0 1 .998-1.73zM4.5 7.653v.005l6.502 3.764A2 2 0 0 1 12 13.153v7.536l7.5-4.342V7.653L12 3.311zM6.132 12.3c0-.552-.388-1.224-.866-1.5s-.866-.052-.866.5s.388 1.224.866 1.5s.866.052.866-.5m2.597 6.498c.478.276.866.053.866-.5c0-.552-.388-1.224-.866-1.5s-.866-.052-.866.5s.388 1.224.866 1.5M5.266 16.8c.478.276.866.052.866-.5s-.388-1.224-.866-1.5s-.866-.052-.866.5s.388 1.224.866 1.5m3.463-2c.478.277.866.053.865-.5c0-.552-.387-1.223-.866-1.5c-.478-.276-.866-.052-.866.5c0 .553.388 1.224.867 1.5M14.898 8c.478-.276.478-.724 0-1s-1.254-.276-1.732 0c-.479.276-.479.724 0 1c.478.276 1.254.276 1.732 0m-4.8-1c.478.276.478.724 0 1s-1.254.276-1.732 0s-.478-.724 0-1s1.254-.276 1.732 0m5.897 8.35c.598-.346 1.083-1.185 1.083-1.875s-.485-.97-1.082-.625s-1.083 1.184-1.083 1.875c0 .69.485.97 1.082.625"
      />
    </svg>
  ),
  contMarketing: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        color="currentColor"
      >
        <ellipse cx="18" cy="10" rx="4" ry="8" />
        <path d="M18 2C14.897 2 8.465 4.378 4.771 5.854C3.079 6.53 2 8.178 2 10s1.08 3.47 2.771 4.146C8.465 15.622 14.897 18 18 18m-7 4l-1.943-1.07A5.93 5.93 0 0 1 6.045 15" />
      </g>
    </svg>
  ),
  contSales: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        color="currentColor"
      >
        <path d="M17 10.805c0-.346 0-.519.052-.673c.151-.448.55-.621.95-.803c.448-.205.672-.307.895-.325c.252-.02.505.034.721.155c.286.16.486.466.69.714c.943 1.146 1.415 1.719 1.587 2.35c.14.51.14 1.044 0 1.553c-.251.922-1.046 1.694-1.635 2.41c-.301.365-.452.548-.642.655a1.27 1.27 0 0 1-.721.155c-.223-.018-.447-.12-.896-.325c-.4-.182-.798-.355-.949-.803c-.052-.154-.052-.327-.052-.673zm-10 0c0-.436-.012-.827-.364-1.133c-.128-.111-.298-.188-.637-.343c-.449-.204-.673-.307-.896-.325c-.667-.054-1.026.402-1.41.87c-.944 1.145-1.416 1.718-1.589 2.35a2.94 2.94 0 0 0 0 1.553c.252.921 1.048 1.694 1.636 2.409c.371.45.726.861 1.363.81c.223-.018.447-.12.896-.325c.34-.154.509-.232.637-.343c.352-.306.364-.697.364-1.132z" />
        <path d="M5 9c0-3.314 3.134-6 7-6s7 2.686 7 6m0 8v.8c0 1.767-1.79 3.2-4 3.2h-2" />
      </g>
    </svg>
  ),
  contRevenue: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        color="currentColor"
      >
        <path d="M14 2.222q-.97-.198-2-.2c-5.523 0-10 4.472-10 9.989S6.477 22 12 22s10-4.472 10-9.989q-.002-1.027-.2-1.998" />
        <path d="M12 9.014c-1.105 0-2 .671-2 1.499c0 .827.895 1.498 2 1.498s2 .67 2 1.498s-.895 1.499-2 1.499m0-5.994c.87 0 1.612.417 1.886 1m-1.886-1v-.999m0 6.993c-.87 0-1.612-.417-1.886-1m1.886 1v.999M21.995 2L17.82 6.174m-.824-3.653l.118 3.088c0 .728.435 1.182 1.228 1.239l3.124.147" />
      </g>
    </svg>
  ),
  contStaff: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M5 6c-1.1 0-2 .9-2 2s.9 2 2 2s2-.89 2-2s-.89-2-2-2m7-2a2 2 0 1 0 2 2c0-1.11-.89-2-2-2m7-2c-1.1 0-2 .9-2 2s.9 2 2 2s2-.89 2-2s-.89-2-2-2M3.5 11c-.83 0-1.5.67-1.5 1.5V17h1v5h4v-5h1v-4.5c0-.83-.67-1.5-1.5-1.5zm7-2C9.67 9 9 9.67 9 10.5V15h1v5h4v-5h1v-4.5c0-.83-.67-1.5-1.5-1.5zm7-2c-.83 0-1.5.67-1.5 1.5V13h1v5h4v-5h1V8.5c0-.83-.67-1.5-1.5-1.5z"
      />
    </svg>
  ),
  contDirectCosts: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="m12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z" />
        <path
          fill="currentColor"
          d="M12 5c2.592 0 4.986.465 6.77 1.258c.89.396 1.674.893 2.247 1.496C21.596 8.36 22 9.122 22 10v4c0 .878-.404 1.639-.983 2.246c-.573.603-1.356 1.1-2.247 1.496C16.986 18.535 14.592 19 12 19s-4.986-.465-6.77-1.258c-.89-.396-1.674-.893-2.247-1.496C2.404 15.64 2 14.878 2 14v-4c0-.878.404-1.639.983-2.246c.573-.603 1.356-1.1 2.247-1.496C7.014 5.465 9.408 5 12 5m8 8.08c-.375.246-.79.466-1.23.662C16.986 14.535 14.592 15 12 15s-4.986-.465-6.77-1.258A9 9 0 0 1 4 13.08V14c0 .227.1.518.432.868c.337.354.872.719 1.61 1.047C7.516 16.57 9.622 17 12 17s4.484-.43 5.958-1.085c.738-.328 1.273-.693 1.61-1.047c.333-.35.432-.641.432-.868zM12 7c-2.379 0-4.484.43-5.958 1.085c-.738.328-1.273.693-1.61 1.047C4.099 9.482 4 9.773 4 10s.1.518.432.868c.337.354.872.719 1.61 1.047C7.516 12.57 9.622 13 12 13s4.484-.43 5.958-1.085c.738-.328 1.273-.693 1.61-1.047c.333-.35.432-.641.432-.868s-.1-.518-.432-.868c-.337-.354-.872-.719-1.61-1.047C16.484 7.431 14.378 7 12 7"
        />
      </g>
    </svg>
  ),
  contIndirectCosts: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="m12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z" />
        <path
          fill="currentColor"
          d="M12 5c2.592 0 4.986.465 6.77 1.258c.89.396 1.674.893 2.247 1.496C21.596 8.36 22 9.122 22 10v4c0 .878-.404 1.639-.983 2.246c-.573.603-1.356 1.1-2.247 1.496C16.986 18.535 14.592 19 12 19s-4.986-.465-6.77-1.258c-.89-.396-1.674-.893-2.247-1.496C2.404 15.64 2 14.878 2 14v-4c0-.878.404-1.639.983-2.246c.573-.603 1.356-1.1 2.247-1.496C7.014 5.465 9.408 5 12 5m8 8.08c-.375.246-.79.466-1.23.662C16.986 14.535 14.592 15 12 15s-4.986-.465-6.77-1.258A9 9 0 0 1 4 13.08V14c0 .227.1.518.432.868c.337.354.872.719 1.61 1.047C7.516 16.57 9.622 17 12 17s4.484-.43 5.958-1.085c.738-.328 1.273-.693 1.61-1.047c.333-.35.432-.641.432-.868zM12 7c-2.379 0-4.484.43-5.958 1.085c-.738.328-1.273.693-1.61 1.047C4.099 9.482 4 9.773 4 10s.1.518.432.868c.337.354.872.719 1.61 1.047C7.516 12.57 9.622 13 12 13s4.484-.43 5.958-1.085c.738-.328 1.273-.693 1.61-1.047c.333-.35.432-.641.432-.868s-.1-.518-.432-.868c-.337-.354-.872-.719-1.61-1.047C16.484 7.431 14.378 7 12 7"
        />
      </g>
    </svg>
  ),
  contExpenses: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M16 13c-2.761 0-5-1.12-5-2.5S13.239 8 16 8s5 1.12 5 2.5s-2.239 2.5-5 2.5m-5 1.5c0 1.38 2.239 2.5 5 2.5s5-1.12 5-2.5m-18-5C3 10.88 5.239 12 8 12c1.126 0 2.165-.186 3-.5M3 13c0 1.38 2.239 2.5 5 2.5c1.126 0 2.164-.186 3-.5" />
        <path d="M3 5.5v11C3 17.88 5.239 19 8 19c1.126 0 2.164-.186 3-.5m2-10v-3m-2 5v8c0 1.38 2.239 2.5 5 2.5s5-1.12 5-2.5v-8" />
        <path d="M8 8C5.239 8 3 6.88 3 5.5S5.239 3 8 3s5 1.12 5 2.5S10.761 8 8 8" />
      </g>
    </svg>
  ),
  contCapex: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M2 22V9.975L9 7v2l5-2v3h8v12zm2-2h16v-8h-8V9.95l-5 2V10l-3 1.325zm7-2h2v-4h-2zm-4 0h2v-4H7zm8 0h2v-4h-2zm7-8h-5l1-8h3zM4 20h16z"
      />
    </svg>
  ),
  contLoan: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M6.5 10h-2v7h2zm6 0h-2v7h2zm8.5 9H2v2h19zm-2.5-9h-2v7h2zm-7-6.74L16.71 6H6.29zm0-2.26L2 6v2h19V6z"
      />
    </svg>
  ),
  contReceivables: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        color="currentColor"
      >
        <path d="M18 6c1.3.129 2.175.42 2.828 1.077C22 8.256 22 10.154 22 13.949s0 5.693-1.172 6.872S17.771 22 14 22h-4c-3.771 0-5.657 0-6.828-1.18C2 19.643 2 17.745 2 13.95s0-5.693 1.172-6.872C3.825 6.42 4.7 6.129 6 6" />
        <path d="M9.5 5.5C9.992 6.006 11.3 8 12 8m2.5-2.5C14.008 6.006 12.7 8 12 8m0 0V2m6.508 12h-.01m-12.99 0h-.01m9.002 0a2.5 2.5 0 1 1-5 0a2.5 2.5 0 0 1 5 0" />
      </g>
    </svg>
  ),
  contPayables: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="m17.5 16.82l2.44 1.41l-.75 1.3L16 17.69V14h1.5zM24 17c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-.34.03-.67.08-1H2V4h18v6.68c2.36 1.13 4 3.53 4 6.32m-13.32-3c.18-.36.37-.7.6-1.03c-.09.03-.18.03-.28.03c-1.66 0-3-1.34-3-3s1.34-3 3-3s3 1.34 3 3c0 .25-.04.5-.1.73c.94-.46 1.99-.73 3.1-.73c.34 0 .67.03 1 .08V8a2 2 0 0 1-2-2H6c0 1.11-.89 2-2 2v4a2 2 0 0 1 2 2zM22 17c0-2.76-2.24-5-5-5s-5 2.24-5 5s2.24 5 5 5s5-2.24 5-5"
      />
    </svg>
  ),
  contShareCap: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M20 17c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H9.5c.3.6.5 1.3.5 2h10v11h-9v2m4-10v2H9v13H7v-6H5v6H3v-8H1.5V9c0-1.1.9-2 2-2zM8 4c0 1.1-.9 2-2 2s-2-.9-2-2s.9-2 2-2s2 .9 2 2m9 2h2v8h-2zm-3 4h2v4h-2zm-3 0h2v4h-2z"
      />
    </svg>
  ),
  contRetained: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M8.4 21q-2.275 0-3.838-1.562T3 15.6q0-.95.325-1.85t.925-1.625L7.8 7.85L5.375 3h13.25L16.2 7.85l3.55 4.275q.6.725.925 1.625T21 15.6q0 2.275-1.575 3.838T15.6 21zm3.6-5q-.825 0-1.412-.587T10 14t.588-1.412T12 12t1.413.588T14 14t-.587 1.413T12 16M9.625 7h4.75l1-2h-6.75zM8.4 19h7.2q1.425 0 2.413-.987T19 15.6q0-.6-.213-1.162t-.587-1.013L14.525 9H9.5l-3.7 4.4q-.375.45-.587 1.025T5 15.6q0 1.425.988 2.413T8.4 19"
      />
    </svg>
  ),
  contSofp: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="none" stroke="currentColor" strokeWidth="2">
        <path d="M1 21V3a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2Z" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 7h4m-4 8.5h4m-4 3h4M5 7h2m2 0H7m0 0V5m0 2v2m-1.414 9.414L7 17m1.415-1.414L7 17m0 0l-1.414-1.414M7 17l1.415 1.414"
        />
      </g>
    </svg>
  ),
  contProfit: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="currentColor" d="m16 11.78l4.24-7.33l1.73 1l-5.23 9.05l-6.51-3.75L5.46 19H22v2H2V3h2v14.54L9.5 8z" />
    </svg>
  ),
  contCash: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M15 10c0-.55.45-1 1-1s1 .45 1 1s-.45 1-1 1s-1-.45-1-1M8 9h5V7H8zm14-1.5v6.97l-2.82.94L17.5 21H12v-2h-2v2H4.5S2 12.54 2 9.5S4.46 4 7.5 4h5c.91-1.21 2.36-2 4-2a1.498 1.498 0 0 1 1.38 2.08c-.14.34-.26.73-.32 1.15l2.27 2.27zm-2 2h-1L15.5 6c0-.65.09-1.29.26-1.91c-.97.25-1.76.97-2.09 1.91H7.5C5.57 6 4 7.57 4 9.5c0 1.88 1.22 6.65 2 9.5h2v-2h6v2h2l1.56-5.15l2.44-.82z"
      />
    </svg>
  ),
};
