import { create } from "zustand";

export const useGeneralStore = create((set, get) => ({
  subscription: null,
  lastUpdated: null,
  showLabel: false,
  showPastData: false,

  reportType: "Variance", // Variance, Projection, Actual, PastData, Valuation
  statementType: "SOFP", // SOFP, SOPL, SOCF
  monthIndex: 0, // Variance
  monthly: true, // Projection

  logout() {
    const initialState = {
      subscription: null,
      lastUpdated: null,
      showLabel: false,
      showPastData: false,

      reportType: "Variance",
      statementType: "SOFP",
      monthIndex: 0,
      monthly: true,
    };
    set(initialState);
  },

  setSubscription(data) {
    set({ subscription: data });
  },

  setLastUpdated(data) {
    set({ lastUpdated: data });
  },

  setShowLabel(data) {
    set({ showLabel: data });
  },

  setShowPastData(data) {
    set({ showPastData: data });
  },

  setReportType(data) {
    set({ reportType: data });
  },

  setStatementType(data) {
    set({ statementType: data });
  },

  setMonthIndex(data) {
    set({ monthIndex: data });
  },

  setMonthly(data) {
    set({ monthly: data });
  },
}));
