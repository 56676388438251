import { useCallback, useEffect, useRef } from "react";
import { useAxiosPrivate } from "../axios/useAxiosPrivate";

import { useGeneralStore } from "../stores/useGeneralStore";
import { useStore } from "../pages/flow/store";
import { usePriorYearsStore } from "../stores/usePriorYearsStore";
import { useAssumptionsStore } from "../stores/useAssumptionsStore";
import { useStatementsStore } from "../stores/useStatementsStore";
import { useStatementsAnnualStore } from "../stores/useStatementsAnnualStore";
import { useActualStore } from "../stores/useActualStore";
import { useDifferencesStore } from "../stores/useDifferencesStore";
import { useValuationStore } from "../stores/useValuationStore";

export const useRestore = (user) => {
  const axiosPrivate = useAxiosPrivate();
  const prevUserRef = useRef(null);

  const onRestore = useCallback(async (axiosPrivate) => {
    const [flowResponse, statementResponse] = await Promise.all([
      axiosPrivate.get("/api/flows"),
      axiosPrivate.get("/api/statements"),
    ]);

    const response1 = flowResponse.data;
    const response2 = statementResponse.data;

    if (response1) {
      const updatedDate = new Date(response1.updated_at).toLocaleString("en-MY", {
        timeZone: "Asia/Kuala_Lumpur",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      const formattedDate = updatedDate.replace(":", ".");
      useGeneralStore.setState((prev) => ({
        ...prev,
        lastUpdated: formattedDate ?? prev.lastUpdated,
      }));

      useStore.setState((prev) => ({
        ...prev,
        contexts: response1.contexts ?? prev.contexts,
        nodes: response1.nodes ?? prev.nodes,
        edges: response1.edges ?? prev.edges,
      }));
      usePriorYearsStore.setState((prev) => ({
        priorYears: response1.priorYears ?? prev.priorYears,
      }));
      useAssumptionsStore.setState((prev) => ({
        assumptions: response1.assumptions ?? prev.assumptions,
      }));
    }

    if (response2) {
      useStatementsStore.setState((prev) => ({
        statements: response2.statements ?? prev.statements,
      }));
      useStatementsAnnualStore.setState((prev) => ({
        statementsAnnual: response2.statementsAnnual ?? prev.statementsAnnual,
      }));
      useActualStore.setState((prev) => ({
        actual: response2.actual ?? prev.actual,
      }));
      useDifferencesStore.setState((prev) => ({
        differences: response2.differences ?? prev.differences,
      }));
      useValuationStore.setState((prev) => ({
        valuation: response2.valuation ?? prev.valuation,
      }));
    }

    useStore.getState().updateAll();
  }, []);

  useEffect(() => {
    if (!prevUserRef.current && user) {
      onRestore(axiosPrivate);
    }
    prevUserRef.current = user;
  }, [user, onRestore, axiosPrivate]);
};
