import HeaderBack from "./HeaderBack";
import HeaderName from "./HeaderName";
// import HeaderExampleSave from "./HeaderExampleSave";
// import HeaderTemplate from "./HeaderTemplate";
import HeaderEditView from "./HeaderEditView";
import HeaderTools from "./HeaderTools";
import HeaderLastUpdated from "./HeaderLastUpdated";
import Save from "../../../../components/saveProgress/Save";

const Header = ({ contextId, editView, setEditView }) => {
  return (
    <div
      //
      style={{ height: "4rem" }}
      className="flex justify-between px-8"
    >
      <div className="flex h-full items-center gap-4">
        <HeaderBack />
        <HeaderName />
      </div>
      <div className="flex h-full items-center gap-4">
        {/* <HeaderExampleSave contextId={contextId} /> */}
        {/* <HeaderTemplate contextId={contextId} /> */}
      </div>
      <div className="flex h-full items-center gap-4">
        <HeaderEditView editView={editView} setEditView={setEditView} />
        <SeparateSvg />
        <HeaderTools contextId={contextId} />
        <SeparateSvg />
        <HeaderLastUpdated />
        <Save />
      </div>
    </div>
  );
};

export default Header;

const SeparateSvg = () => {
  return (
    <svg className="h-6 w-6 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 19V5"
      />
    </svg>
  );
};
