import Switch from "@mui/material/Switch";
import { useStore } from "../../pages/flow/store";
import { useGeneralStore } from "../../stores/useGeneralStore";

const OnOffShowPastData = () => {
  const contextId = useStore((state) => state.contextId);
  const showPastData = useGeneralStore((state) => state.showPastData);
  const setShowPastData = useGeneralStore.getState().setShowPastData;

  const handleShow = (event) => {
    setShowPastData(event.target.checked);
  };

  return (
    <>
      {contextId && (
        <div className="flex h-16">
          <div className="flex flex-col h-full">
            <p className="text-secondary font-semibold text-sm">Show Past Data</p>
            <Switch name="switch" checked={showPastData} onChange={handleShow} />
          </div>
        </div>
      )}
    </>
  );
};

export default OnOffShowPastData;
