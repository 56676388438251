const SOPL = ({ monthIndex, yaxisData }) => {
  const revA = yaxisData.sopl.find((item) => item.description === "totalRevenueLong").actual[monthIndex];
  const npA = yaxisData.sopl.find((item) => item.description === "totalProfitLong").actual[monthIndex];
  const npmA = revA === 0 ? 0 : (npA / revA) * 100;

  const revS = yaxisData.sopl.find((item) => item.description === "totalRevenueLong").statements[monthIndex];
  const npS = yaxisData.sopl.find((item) => item.description === "totalProfitLong").statements[monthIndex];
  const npmS = revS === 0 ? 0 : (npS / revS) * 100;

  return (
    <>
      <div className="flex items-center gap-2 pb-4">
        <svg
          className="text-primary h-8 w-auto"
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m16 11.78l4.24-7.33l1.73 1l-5.23 9.05l-6.51-3.75L5.46 19H22v2H2V3h2v14.54L9.5 8z"
          />
        </svg>
        <h3 className="text-lg font-semibold">SOPL (Profit/Loss) Overview</h3>
      </div>
      <p className="text-secondary font-medium text-lg">Actual</p>
      <div className="flex gap-8">
        <div className="flex flex-col">
          <p className="text-gray-500 font-medium">Revenue</p>
          <p className="text-2xl font-semibold">${revA.toLocaleString()}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-gray-500 font-medium">Net Profit/(Loss)</p>
          <p className="text-2xl font-semibold">${npA.toLocaleString()}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-gray-500 font-medium">Net Profit Margin</p>
          <p className="text-2xl font-semibold">{npmA.toFixed(2)}%</p>
        </div>
      </div>
      <p className="text-secondary font-medium text-lg">Projection</p>
      <div className="flex gap-8">
        <div className="flex flex-col">
          <p className="text-gray-500 font-medium">Revenue</p>
          <p className="text-2xl font-semibold">${revS.toLocaleString()}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-gray-500 font-medium">Net Profit/(Loss)</p>
          <p className="text-2xl font-semibold">${npS.toLocaleString()}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-gray-500 font-medium">Net Profit Margin</p>
          <p className="text-2xl font-semibold">{npmS.toFixed(2)}%</p>
        </div>
      </div>
    </>
  );
};

export default SOPL;
