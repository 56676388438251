export const InputStringBlank = ({ value, disabled = false, onChange, maxLength = 30 }) => {
  return (
    <input //
      type="text"
      name="text"
      className={`px-2 w-52 h-10 ${disabled ? "cursor-not-allowed" : ""}`}
      value={value}
      onChange={onChange}
      disabled={disabled}
      maxLength={maxLength}
    />
  );
};
