import { useStore } from "../store";
import { useAssumptionsStore } from "../../../stores/useAssumptionsStore";
import { useStatementsStore } from "../../../stores/useStatementsStore";
import { useActualStore } from "../../../stores/useActualStore";
import { copyOver, updateLength } from "./4updatePriorYears";

export const updateActual = () => {
  const contexts = useStore.getState().contexts;
  const assumptions = useAssumptionsStore.getState().assumptions;
  const statements = useStatementsStore.getState().statements;
  const actual = useActualStore.getState().actual;

  // remove deleted context
  const contextIds = contexts.map((context) => context.id);
  for (let i = actual.length - 1; i >= 0; i--) {
    if (!contextIds.includes(actual[i].contextId)) {
      actual.splice(i, 1);
    }
  }

  // add new context
  contexts.forEach((context) => {
    const index = actual.findIndex((item) => item.contextId === context.id);
    if (index === -1) {
      actual.push({
        contextId: context.id,
        sofp: [],
        sopl: [],
        socf: [],
      });
    }
  });

  // copy over
  actual.forEach((_, index) => {
    copyOver("sofp", statements[index], actual[index]);
    copyOver("sopl", statements[index], actual[index]);
    copyOver("socf", statements[index], actual[index]);
  });

  // fill zero
  actual.forEach((_, index) => {
    const { months } = assumptions[index];
    updateLength("sofp", actual[index], months);
    updateLength("sopl", actual[index], months);
    updateLength("socf", actual[index], months);
  });

  // calculate totals
  actual.forEach((actualItem, index) => {
    const { months } = assumptions[index];
    actualItem.sofp = updateSOFP(actualItem.sofp, months);
    actualItem.sopl = updateSOPL(actualItem.sopl, months);
    actualItem.socf = updateSOCF(actualItem.socf, months);
  });
};

export const updateSOFP = (items, months) => {
  const mapFormat = items.reduce((acc, { description, long }) => {
    acc[description] = long;
    return acc;
  }, {});

  const {
    equityLongs,
    nonCurrentAssetsLongs,
    currentAssetsLongs,
    nonCurrentLiabilitiesLongs,
    currentLiabilitiesLongs,
  } = mapFormat;

  // get total figures - return array of numbers
  const totalNonCurrentAssetsLong = getTotalLong(nonCurrentAssetsLongs, months);
  const totalCurrentAssetsLong = getTotalLong(currentAssetsLongs, months);
  const totalAssetsLong = Array.from({ length: months }).map((_, index) => {
    const nca = totalNonCurrentAssetsLong[index] || 0;
    const ca = totalCurrentAssetsLong[index] || 0;
    return nca + ca;
  });
  const totalEquityLong = getTotalLong(equityLongs, months);
  const totalNonCurrentLiabilitiesLong = getTotalLong(nonCurrentLiabilitiesLongs, months);
  const totalCurrentLiabilitiesLong = getTotalLong(currentLiabilitiesLongs, months);
  const totalLiabilitiesLong = Array.from({ length: months }).map((_, index) => {
    const ncl = totalNonCurrentLiabilitiesLong[index] || 0;
    const cl = totalCurrentLiabilitiesLong[index] || 0;
    return ncl + cl;
  });
  const checkLong = Array.from({ length: months }).map((_, index) => {
    const assets = totalAssetsLong[index] || 0;
    const equity = totalEquityLong[index] || 0;
    const liabilities = totalLiabilitiesLong[index] || 0;
    return assets - equity - liabilities;
  });

  const result = {
    totalAssetsLong,
    totalNonCurrentAssetsLong,
    totalCurrentAssetsLong,
    totalEquityLong,
    totalLiabilitiesLong,
    totalNonCurrentLiabilitiesLong,
    totalCurrentLiabilitiesLong,
    checkLong,
  };

  items.forEach((item) => {
    if (result.hasOwnProperty(item.description)) {
      item.long = result[item.description];
    }
  });

  return items;
};

export const updateSOPL = (items, months) => {
  const mapFormat = items.reduce((acc, { description, long }) => {
    acc[description] = long;
    return acc;
  }, {});

  const { revenueLongs, expensesLongs, intExpLongs, taxLongs } = mapFormat;

  // get total figures
  const totalRevenueLong = getTotalLong(revenueLongs, months);
  const totalExpensesLong = getTotalLong(expensesLongs, months);
  const totalIntExpLong = getTotalLong(intExpLongs, months);
  const totalTaxLong = getTotalLong(taxLongs, months);

  const totalPbitLong = Array.from({ length: months }).map((_, index) => {
    const revenue = totalRevenueLong[index] || 0;
    const expenses = totalExpensesLong[index] || 0;
    return revenue - expenses;
  });
  const totalPbtLong = Array.from({ length: months }).map((_, index) => {
    const pbit = totalPbitLong[index] || 0;
    const intExp = totalIntExpLong[index] || 0;
    return pbit - intExp;
  });
  const totalProfitLong = Array.from({ length: months }).map((_, index) => {
    const pbt = totalPbtLong[index] || 0;
    const tax = totalTaxLong[index] || 0;
    return pbt - tax;
  });

  const result = {
    totalRevenueLong,
    totalExpensesLong,
    totalIntExpLong,
    totalTaxLong,
    totalPbitLong,
    totalPbtLong,
    totalProfitLong,
  };

  items.forEach((item) => {
    if (result.hasOwnProperty(item.description)) {
      item.long = result[item.description];
    }
  });

  return items;
};

export const updateSOCF = (items, months) => {
  const mapFormat = items.reduce((acc, { description, long }) => {
    acc[description] = long;
    return acc;
  }, {});

  const { operatingLongs, investingLongs, financingLongs } = mapFormat;

  // get total figures
  const totalOperatingLong = getTotalLong(operatingLongs, months);
  const totalInvestingLong = getTotalLong(investingLongs, months);
  const totalFinancingLong = getTotalLong(financingLongs, months);
  const totalLong = Array.from({ length: months }).map((_, index) => {
    const operating = totalOperatingLong[index] || 0;
    const investing = totalInvestingLong[index] || 0;
    const financing = totalFinancingLong[index] || 0;
    return operating + investing + financing;
  });

  const result = {
    totalOperatingLong,
    totalInvestingLong,
    totalFinancingLong,
    totalLong,
  };

  items.forEach((item) => {
    if (result.hasOwnProperty(item.description)) {
      item.long = result[item.description];
    }
  });

  return items;
};

const getTotalLong = (longs, months) => {
  if (!longs || longs.length === 0) return Array(months).fill(0);

  return longs.reduce((acc, curr) => {
    return acc.map((num, idx) => num + curr.long[idx]);
  }, Array(months).fill(0));
};
