import { useState } from "react";
import { useStore } from "../../flow/store";
import Table from "./component/Table";
import MascotWatering from "../../../components/noSubscription/MascotWatering";

const Summary = ({ setPage }) => {
  const [tab, setTab] = useState("company");

  const contexts = useStore((state) => state.contexts);
  const filteredContexts = contexts.filter((item) => item.data.type === tab);

  return (
    <div className="flex flex-col h-full">
      <div
        //
        style={{ height: "3rem" }}
        className="flex text-sm font-semibold text-gray-400 gap-2 shrink-0"
      >
        <button
          className={`flex items-center px-8 border-b-2
              hover:text-primaryStrong hover:border-primaryStrong
              ${tab === "company" && "text-primaryStrong border-primaryStrong"}`}
          onClick={() => setTab("company")}
        >
          Company
        </button>
        <button
          className={`flex items-center px-8 border-b-2
              hover:text-primaryStrong hover:border-primaryStrong
              ${tab === "project" && "text-primaryStrong border-primaryStrong"}`}
          onClick={() => setTab("project")}
        >
          Project
        </button>
      </div>

      {filteredContexts.length > 0 && (
        <div className="h-full p-4">
          <Table tab={tab} filteredContexts={filteredContexts} setPage={setPage} />
        </div>
      )}
      {filteredContexts.length === 0 && (
        <div className="flex flex-grow flex-col items-center justify-center p-4 rounded-tr-3xl rounded-bl-3xl rounded-br-3xl">
          <div className="h-60 w-auto">
            <MascotWatering />
          </div>
          <p className="font-semibold text-gray-600 text-lg mt-8 mb-2">None added yet.</p>
          <p className="font-medium text-gray-500 mb-6">Let's create one and start using it!</p>
        </div>
      )}
    </div>
  );
};

export default Summary;
