import { useStore } from "../../store";
import { InputNode, DisplayOutputs } from "./NodeIO";

import { colors, svgs } from "../../components/Panel/constants";
import { lightenColor } from "../../components/Panel/colorManipulation";

export const selector = (id, data) => (store) => ({
  keepForRerender: () => {},
  setRightBar: () => {
    store.setRightBar(id);
  },
});

export const style = {
  node: `p-4 w-72 border-2 rounded-2xl transition-filter duration-300
    bg-white hover:bg-gray-100 hover:scale-105`,
  h2: "text-xl font-semibold",
  h3: "font-semibold",
  h4: "font-medium",
  innerBox: "rounded-2xl px-4 py-2 mb-2",
};

const ContNormalNode = ({ editView, handleDelete, ...fromReactFlow }) => {
  const { id, data, type } = fromReactFlow;
  const { keepForRerender, setRightBar } = useStore(selector(id, data));
  keepForRerender();

  const handleDeleteClick = () => {
    const contextId = useStore
      .getState()
      .nodes //
      .find((node) => node.id === id).contextId;
    const edges = useStore
      .getState()
      .edges //
      .filter((edge) => edge.contextId === contextId);
    const nodesDelete = [{ id: id, type: type }];
    const edgesDelete = edges
      .filter((edge) => edge.contextId === contextId)
      .filter((edge) => edge.source === id || edge.target === id);

    const changes = { nodes: nodesDelete, edges: edgesDelete };
    handleDelete(changes);
  };

  const iconColor = colors[type];
  const bgColor = lightenColor(iconColor, 80);
  const Icon = svgs[type];

  return (
    <div
      className={`p-4 w-72 border-2 rounded-2xl transition-filter duration-300
        bg-white hover:bg-gray-100 hover:scale-105`}
      style={{ borderColor: iconColor }}
    >
      <DisplayIcon
        description={data.general.description}
        Icon={Icon}
        iconColor={iconColor}
        bgColor={bgColor}
        handleDeleteClick={handleDeleteClick}
        setRightBar={setRightBar}
        editView={editView}
      />

      <>
        <h3 className="font-semibold">Inputs</h3>
        <div className="rounded-2xl px-4 py-2 mb-2" style={{ backgroundColor: bgColor }}>
          {data.assignments.map((item) => (
            <InputNode
              key={item.id}
              name={item.description}
              value={item.long[0]}
              handleId={item.id}
              iconColor={iconColor}
            />
          ))}
        </div>
      </>

      <DisplayOutputs outputs={data.outputs} bgColor={bgColor} iconColor={iconColor} />
    </div>
  );
};

export default ContNormalNode;

export const DisplayIcon = ({
  description, //
  Icon,
  iconColor,
  bgColor,
  setRightBar,
  handleDeleteClick,
  editView,
}) => {
  return (
    <div className="flex justify-between w-full border-b border-gray-400 pb-2">
      <div className="flex items-center gap-2">
        <div
          className="w-10 h-10 p-2 rounded-full flex-shrink-0"
          style={{
            color: iconColor,
            backgroundColor: bgColor,
          }}
        >
          <Icon />
        </div>
        <h2 className="text-lg font-semibold overflow-hidden text-ellipsis">{description}</h2>
      </div>
      {editView === "edit" && (
        <div className="flex items-center">
          <svg
            className="h-8 w-auto text-gray-500 p-1.5 rounded-full hover:bg-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            onClick={handleDeleteClick}
          >
            <path
              fill="currentColor"
              d="M7 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h4a1 1 0 1 1 0 2h-1.069l-.867 12.142A2 2 0 0 1 17.069 22H6.93a2 2 0 0 1-1.995-1.858L4.07 8H3a1 1 0 0 1 0-2h4zm2 2h6V4H9zM6.074 8l.857 12H17.07l.857-12zM10 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1m4 0a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1"
            />
          </svg>
          <svg
            className="h-8 w-auto text-gray-500 p-1.5 rounded-full hover:bg-gray-200"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            onClick={setRightBar}
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 21h18M12.222 5.828L15.05 3L20 7.95l-2.828 2.828m-4.95-4.95l-5.607 5.607a1 1 0 0 0-.293.707v4.536h4.536a1 1 0 0 0 .707-.293l5.607-5.607m-4.95-4.95l4.95 4.95"
            />
          </svg>
        </div>
      )}
    </div>
  );
};
