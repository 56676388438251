import { useSaveContext } from "../../saveAndRestore/useSaveContext";
import { useStore } from "../../pages/flow/store";

const Save = () => {
  const { isSaved, onSave } = useSaveContext();
  const contextId = useStore((state) => state.contextId);

  return (
    <>
      {contextId && (
        <div className="flex h-10">
          <button
            className={`h-full w-24 rounded-xl text-white font-semibold transition duration-300 
          ${isSaved ? "bg-gray-400 cursor-not-allowed" : "bg-primaryStrong hover:bg-primary"}`}
            disabled={isSaved}
            onClick={onSave}
          >
            Save
          </button>
        </div>
      )}
    </>
  );
};

export default Save;
