import { useState } from "react";
import { useStore } from "../../store";
import { useSaveContext } from "../../../../saveAndRestore/useSaveContext";

const HeaderTools = ({ contextId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const generateExample = useStore.getState().generateExample;
  const deleteAll = useStore.getState().deleteAll;

  // save
  const { autoSave } = useSaveContext();
  const generateEmpty = () => {
    generateExample(contextId, "empty");
    setIsOpen(false);
    autoSave();
  };
  const generateFilled = () => {
    generateExample(contextId, "filled");
    setIsOpen(false);
    autoSave();
  };
  const handleDelete = () => {
    deleteAll(contextId);
    setIsOpen(false);
    autoSave();
  };

  const classNameSvg = "h-6 w-6 text-gray-500";
  const classNameSpan = "text-gray-700";

  return (
    <div className="relative">
      <button className="h-10 bg-white flex items-center justify-between gap-2" onClick={() => setIsOpen(!isOpen)}>
        <div className="flex items-center gap-2">
          <ToolsSvg classNameSvg={classNameSvg} />
          <span className={classNameSpan}>Tools</span>
        </div>
        <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M13.06 16.06a1.5 1.5 0 0 1-2.12 0l-5.658-5.656a1.5 1.5 0 1 1 2.122-2.121L12 12.879l4.596-4.596a1.5 1.5 0 0 1 2.122 2.12l-5.657 5.658Z"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute min-w-max bg-white border border-gray-100 rounded-md shadow-md z-30">
          <div //
            className="flex items-center gap-2 p-2 hover:bg-gray-100 cursor-pointer"
            onClick={generateEmpty}
          >
            <EmptySvg classNameSvg={classNameSvg} />
            <span className={classNameSpan}>Empty Example</span>
          </div>
          <div //
            className="flex items-center gap-2 p-2 hover:bg-gray-100 cursor-pointer"
            onClick={generateFilled}
          >
            <FilledSvg classNameSvg={classNameSvg} />
            <span className={classNameSpan}>Filled Example</span>
          </div>
          <div //
            className="flex items-center gap-2 p-2 hover:bg-gray-100 cursor-pointer"
            onClick={handleDelete}
          >
            <DeleteSvg classNameSvg={classNameSvg} />
            <span className={classNameSpan}>Delete All</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderTools;

const ToolsSvg = ({ classNameSvg }) => {
  return (
    <svg className={classNameSvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="m21.71 20.29l-1.42 1.42a1 1 0 0 1-1.41 0L7 9.85A3.8 3.8 0 0 1 6 10a4 4 0 0 1-3.78-5.3l2.54 2.54l.53-.53l1.42-1.42l.53-.53L4.7 2.22A4 4 0 0 1 10 6a3.8 3.8 0 0 1-.15 1l11.86 11.88a1 1 0 0 1 0 1.41M2.29 18.88a1 1 0 0 0 0 1.41l1.42 1.42a1 1 0 0 0 1.41 0l5.47-5.46l-2.83-2.83M20 2l-4 2v2l-2.17 2.17l2 2L18 8h2l2-4Z"
      />
    </svg>
  );
};

const EmptySvg = ({ classNameSvg }) => {
  return (
    <svg className={classNameSvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 7h11a2 2 0 0 1 2 2v.5a.5.5 0 0 0 .5.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5a.5.5 0 0 0-.5.5v.5a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2m1 3v4"
      />
    </svg>
  );
};

const FilledSvg = ({ classNameSvg }) => {
  return (
    <svg className={classNameSvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 7h11a2 2 0 0 1 2 2v.5a.5.5 0 0 0 .5.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5a.5.5 0 0 0-.5.5v.5a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2m1 3v4m3-4v4m3-4v4"
      />
    </svg>
  );
};

const DeleteSvg = ({ classNameSvg }) => {
  return (
    <svg className={classNameSvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zM17 6H7v13h10zM9 17h2V8H9zm4 0h2V8h-2zM7 6v13z"
      />
    </svg>
  );
};
