import { useStore } from "../../store";
import { AssignmentsBox, FormulasBox, OutputsBox } from "../utils/RightBarBox";
import { nanoid } from "nanoid";

import { colors, descriptions, svgs } from "../../components/Panel/constants";
import { lightenColor } from "../../components/Panel/colorManipulation";

export const selector = (id, data) => (store) => ({
  setGeneral: (key, value) => {
    store.updateNode(id, { general: { ...data.general, [key]: value } });
  },
  setCore: (key, value) => {
    store.updateNode(id, { core: { ...data.core, [key]: value } });
  },

  setAssignsCustom: (index, key, value) => {
    const updated = [...data.assignments];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    if (key === "value" && updated[index].custom.value === updated[index].custom.max) {
      updated[index].custom = { ...updated[index].custom, value: value, max: value };
      store.updateNode(id, { assignments: updated });
      return;
    }

    updated[index].custom = { ...updated[index].custom, [key]: value };
    store.updateNode(id, { assignments: updated });
  },
  setAssigns: (index, key, value) => {
    const updated = [...data.assignments];

    if (key === "type") value === "Default" ? (value = "Custom") : (value = "Default");
    if (key === "linkCustom") value === "Link" ? (value = "Custom") : (value = "Link");

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    if (key === "value" && updated[index].value === updated[index].max) {
      updated[index] = { ...updated[index], value: value, max: value };
      store.updateNode(id, { assignments: updated });
      return;
    }

    updated[index] = { ...updated[index], [key]: value };
    store.updateNode(id, { assignments: updated });
  },
  addAssign: () => {
    store.updateNode(id, {
      assignments: [
        ...data.assignments,
        {
          id: nanoid(),
          description: "",
          type: "Custom",
          linkCustom: "Link",
          custom: {
            value: 0,
            min: 0,
            max: 0,
            change: 0,
            start: "",
          },
          long: [],
          longCustom: [],
        },
      ],
    });
  },
  deleteAssign: (index) => {
    const updated = [...data.assignments];
    updated.splice(index, 1);
    store.updateNode(id, { assignments: updated });
  },
  setCustomLong: (index, colIndex, value) => {
    const updated = [...data.assignments];

    if (isNaN(value) || !isFinite(value)) {
      value = null;
    }

    if (value === null) {
      updated[index].longCustom[colIndex] = value;
      store.updateNode(id, { assignments: updated });
      return;
    }

    if (updated[index].long[colIndex] !== value) {
      updated[index].longCustom[colIndex] = value;
      store.updateNode(id, { assignments: updated });
    }
  },
  resetCustomLong: (index) => {
    const updated = [...data.assignments];
    updated[index].longCustom = updated[index].longCustom.map(() => null);
    store.updateNode(id, { assignments: updated });
  },

  setFormulas: (index, key, value) => {
    const updated = [...data.formulas];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    updated[index] = { ...updated[index], [key]: value };
    store.updateNode(id, { formulas: updated });
  },
  addFormula: () => {
    store.updateNode(id, {
      formulas: [
        ...data.formulas,
        {
          id: nanoid(),
          description: "",
          type: "Custom",
          calcType: "Basic",
          impact: "",
          params: [],
        },
      ],
    });
  },
  deleteFormula: (index) => {
    const updated = [...data.formulas];
    updated.splice(index, 1);
    store.updateNode(id, { formulas: updated });
  },

  addFormulasParam: (index, info) => {
    const updated = [...data.formulas];
    updated[index].params.push(info);
    store.updateNode(id, { formulas: updated });
  },
  deleteFormulasParam: (parentIndex, index) => {
    const updated = [...data.formulas];
    updated[parentIndex].params.splice(index, 1);
    store.updateNode(id, { formulas: updated });
  },
  sortParamsIndex: (sortedParamsIndex, parentIndex) => {
    const updatedParams = sortedParamsIndex.map((id) =>
      data.formulas[parentIndex].params.find((param) => param.id === id)
    );
    const updatedFormulas = [...data.formulas];
    updatedFormulas[parentIndex] = { ...updatedFormulas[parentIndex], params: updatedParams };
    store.updateNode(id, { formulas: updatedFormulas });
  },
});

export const styleOverall = "w-full pt-4 pl-4 flex flex-col gap-2";
export const styleDescription = "flex flex-shrink-0 items-center h-10 p-0 gap-4";

const ContMarketingDetail = ({ node, assumption }) => {
  const { id, data, type } = node;
  const {
    setGeneral,
    setCore,
    setAssignsCustom,
    setAssigns,
    addAssign,
    deleteAssign,
    setCustomLong,
    resetCustomLong,
    setFormulas,
    addFormula,
    deleteFormula,
    addFormulasParam,
    deleteFormulasParam,
    sortParamsIndex,
  } = useStore(selector(id, data));

  const iconColor = colors[type];
  const bgColor = lightenColor(iconColor, 80);
  const Icon = svgs[type];

  // const checking = "border border-red-300";
  const checking = "";

  return (
    <div className={styleOverall}>
      <div className={checking}>
        <DisplayIcon description={descriptions[type]} Icon={Icon} iconColor={iconColor} bgColor={bgColor} />
      </div>

      <div className={checking}>
        <DisplaySection description="General" />
        <div className={styleDescription}>
          <span className="font-medium">Description</span>
          <input
            type="text"
            name="text"
            className="h-full w-60 px-2 border-b"
            maxLength={20}
            value={data.general.description}
            onChange={(e) => setGeneral("description", e.target.value)}
          />
        </div>
      </div>

      <div className={checking}>
        <DisplaySection description="Core" />
        <div className={styleDescription}>
          <span className="font-medium">Leads Calculation Type</span>
          <select
            className="h-full w-24 px-2 bg-white border border-b"
            name="select"
            value={data.core.leadsCalcType}
            onChange={(e) => setCore("leadsCalcType", e.target.value)}
          >
            <option value="Easy">Easy</option>
            <option value="Medium">Medium</option>
            <option value="Hard">Hard</option>
            <option value="Custom">Custom</option>
          </select>
        </div>
      </div>

      <div className={checking}>
        <DisplaySection description="Inputs" />
        <AssignmentsBox
          assignments={data.assignments}
          setAssignsCustom={setAssignsCustom}
          setAssigns={setAssigns}
          addAssign={addAssign}
          deleteAssign={deleteAssign}
          setCustomLong={setCustomLong}
          resetCustomLong={resetCustomLong}
          assumptions={assumption}
        />
      </div>

      <div className={checking}>
        <DisplaySection description="Formulas" />
        <FormulasBox
          formulas={data.formulas}
          setFormulas={setFormulas}
          sortParamsIndex={sortParamsIndex}
          addFormula={addFormula}
          deleteFormula={deleteFormula}
          addFormulasParam={addFormulasParam}
          deleteFormulasParam={deleteFormulasParam}
        />
      </div>

      <div className={checking}>
        <DisplaySection description="Outputs" />
        <OutputsBox outputs={data.outputs} assumptions={assumption} />
      </div>
    </div>
  );
};

export default ContMarketingDetail;

export const DisplayIcon = ({ description, Icon, iconColor, bgColor }) => {
  const setRightBar = useStore.getState().setRightBar;

  return (
    <div className="flex justify-between border-b border-gray-400 pt-2 pb-4">
      <div className="flex items-center gap-2">
        <div
          className="w-10 h-10 p-2 rounded-full"
          style={{
            color: iconColor,
            backgroundColor: bgColor,
          }}
        >
          <Icon />
        </div>
        <h2 className="text-lg font-semibold">{description}</h2>
      </div>
      <svg
        className="w-10 h-10 p-2 mr-4 cursor-pointer rounded-full text-gray-500 hover:bg-gray-100 transition duration-300"
        onClick={() => setRightBar(null)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
        />
      </svg>
    </div>
  );
};

export const DisplaySection = ({ description }) => {
  return (
    <h3
      className={`text-secondary bg-background rounded-lg font-semibold 
      py-1 px-12 inline-block`}
    >
      {description}
    </h3>
  );
};
