export const onDragStart = (event, nodeType) => {
  event.dataTransfer.setData("application/reactflow", nodeType);
  event.dataTransfer.effectAllowed = "move";
};

export const onDragOver = (event) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = "move";
};

export const onDrop = (event, reactFlowInstance, store) => {
  event.preventDefault();

  const type = event.dataTransfer.getData("application/reactflow");
  if (!type) {
    return;
  }

  const position = reactFlowInstance.screenToFlowPosition({
    x: event.clientX,
    y: event.clientY,
  });

  const storeFunctions = {
    addContBlank: store.addContBlank,
    addContMarketing: store.addContMarketing,
    addContSales: store.addContSales,
    addContRevenue: store.addContRevenue,
    addContStaff: store.addContStaff,
    addContDirectCosts: store.addContDirectCosts,
    addContIndirectCosts: store.addContIndirectCosts,
    addContExpenses: store.addContExpenses,
    addContProfit: store.addContProfit,
    addContCapex: store.addContCapex,
    addContLoan: store.addContLoan,
    addContReceivables: store.addContReceivables,
    addContPayables: store.addContPayables,
    addContShareCap: store.addContShareCap,
    addContRetained: store.addContRetained,
    addContCash: store.addContCash,
    addContSofp: store.addContSofp,
  };

  const storeFunction = storeFunctions[type];
  if (storeFunction) {
    storeFunction(position.x, position.y);
  }
};
