import { useState } from "react";

import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Header from "./components/Header";
import Summary from "./components/Summary";
import AddCompany from "./components/AddCompany";
import AddProject from "./components/AddProject";
import EditCompany from "./components/EditCompany";
import EditProject from "./components/EditProject";

const Main = () => {
  const [page, setPage] = useState("summary");

  return (
    <div
      //
      style={{ height: "calc(100vh - var(--navbar) - 1rem)" }}
      className="flex flex-col mx-8 gap-2"
    >
      <Breadcrumb />
      {page === "summary" && <Header setPage={setPage} />}
      <div className="flex-grow overflow-y-auto p-4 bg-white rounded-3xl">
        {page === "summary" && <Summary setPage={setPage} />}
        {page === "addCompany" && <AddCompany setPage={setPage} />}
        {page === "addProject" && <AddProject setPage={setPage} />}
        {page === "editCompany" && <EditCompany setPage={setPage} />}
        {page === "editProject" && <EditProject setPage={setPage} />}
      </div>
    </div>
  );
};

export default Main;
