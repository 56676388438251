import { useActualStore } from "../../../stores/useActualStore";
import { useSaveContext } from "../../../saveAndRestore/useSaveContext";

import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "../0reactgrid/styles.css";
import { getColumns } from "./getColumns";
import { getRowsSOFP } from "./getRows";

export const selector = (contextId, monthIndex) => (store) => ({
  updateData: (changes) => {
    const updated = store.actual.map((layer1) => {
      if (layer1.contextId !== contextId) return layer1;

      return {
        ...layer1,
        ...changes.reduce(
          (layer1Acc, change) => {
            const [category, description, id] = change.rowId.split("|");
            const col = monthIndex;
            const value = change.newCell.value;

            layer1Acc[category] = layer1Acc[category].map((layer2) => {
              if (layer2.description !== description) return layer2;

              return {
                ...layer2,
                long: layer2.long.map((layer3) => {
                  if (layer3.id !== id) return layer3;

                  return {
                    ...layer3,
                    long: layer3.long.map((layer4, index) => {
                      if (index !== col) return layer4;

                      return value;
                    }),
                  };
                }),
              };
            });
            return layer1Acc;
          },
          { ...layer1 }
        ),
      };
    });

    store.updateActualDetail(updated);
  },
});

const SOFP = ({ contextId, monthIndex, differences, editView }) => {
  const columns = getColumns();
  const rows = getRowsSOFP({ monthIndex, differences, editView });
  const { updateData } = useActualStore(selector(contextId, monthIndex));

  const { autoSave } = useSaveContext();
  const handleChanges = (changes) => {
    updateData(changes);
    autoSave();
  };

  return (
    <ReactGrid
      rows={rows}
      columns={columns}
      onCellsChanged={handleChanges}
      stickyTopRows={1}
      stickyLeftColumns={1}
      enableRangeSelection
    />
  );
};

export default SOFP;
