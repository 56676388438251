import { useActualStore } from "../../../stores/useActualStore";
import { useSaveContext } from "../../../saveAndRestore/useSaveContext";

import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "../0reactgrid/styles.css";
import { getColumns } from "./getColumns";
import { getRowsSOFP } from "./getRows";

export const selector = (contextId) => (store) => ({
  updateData: (changes) => {
    const updated = store.actual.map((layer1) => {
      if (layer1.contextId === contextId) {
        const layer1Sections = changes.reduce((acc, change) => {
          const split = change.rowId.split("|");
          const section = split[0];
          const description = split[1];
          const id = split[2];
          const col = change.columnId;
          const value = change.newCell.value;

          if (!acc[section]) acc[section] = [...layer1[section]];

          acc[section] = acc[section].map((item) =>
            item.description === description
              ? {
                  ...item,
                  long: item.long.map((longItem) =>
                    longItem.id === id
                      ? {
                          ...longItem,
                          long: longItem.long.map((num, index) => (index === col ? value : num)),
                        }
                      : longItem
                  ),
                }
              : item
          );
          return acc;
        }, {});
        return { ...layer1, ...layer1Sections };
      }
      return layer1;
    });

    store.updateActualDetail(updated);
  },
});

const SOFP = ({ contextId, assumption, actual, editView }) => {
  const columns = getColumns({ assumption });
  const rows = getRowsSOFP({ assumption, actual, editView });
  const { updateData } = useActualStore(selector(contextId));

  const { autoSave } = useSaveContext();
  const handleChanges = (changes) => {
    updateData(changes);
    autoSave();
  };

  return (
    <ReactGrid
      rows={rows}
      columns={columns}
      onCellsChanged={handleChanges}
      stickyTopRows={3}
      stickyLeftColumns={1}
      enableRangeSelection
    />
  );
};

export default SOFP;
