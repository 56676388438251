import ContNormalNode from "./containerNodes/nodes/1ContNormalNode";
import ContCostsNode from "./containerNodes/nodes/2ContCostsNode";
import ContCapexNode from "./containerNodes/nodes/3ContCapexNode";
import ContLoanNode from "./containerNodes/nodes/4ContLoanNode";
import ContStaffNode from "./containerNodes/nodes/5ContStaffNode";

export const nodeTypesDesc = {
  contBlank: "Blank",
  contMarketing: "Marketing",
  contSales: "Sales",
  contRevenue: "Revenue",
  contStaff: "Staff",
  contDirectCosts: "Direct Costs",
  contIndirectCosts: "Indirect Costs",
  contExpenses: "Expenses",
  contCapex: "CAPEX",
  contLoan: "Loan",
  contReceivables: "Receivables",
  contPayables: "Payables",
  contShareCap: "Share Capital",
  contRetained: "Retained earnings",
  contSofp: "SOFP",
  contProfit: "Profit / (Loss)",
  contCash: "Cash",
};

const withCustomProps = (NodeComponent, editView, handleDelete) => (nodeProps) => (
  <NodeComponent {...nodeProps} editView={editView} handleDelete={handleDelete} />
);

export const createNodeTypes = (editView, handleDelete) => ({
  contBlank: withCustomProps(ContNormalNode, editView, handleDelete),
  contMarketing: withCustomProps(ContNormalNode, editView, handleDelete),
  contSales: withCustomProps(ContNormalNode, editView, handleDelete),
  contRevenue: withCustomProps(ContNormalNode, editView, handleDelete),
  contStaff: withCustomProps(ContStaffNode, editView, handleDelete),
  contDirectCosts: withCustomProps(ContCostsNode, editView, handleDelete),
  contIndirectCosts: withCustomProps(ContCostsNode, editView, handleDelete),
  contExpenses: withCustomProps(ContNormalNode, editView, handleDelete),
  contCapex: withCustomProps(ContCapexNode, editView, handleDelete),
  contLoan: withCustomProps(ContLoanNode, editView, handleDelete),
  contReceivables: withCustomProps(ContNormalNode, editView, handleDelete),
  contPayables: withCustomProps(ContNormalNode, editView, handleDelete),
  contShareCap: withCustomProps(ContNormalNode, editView, handleDelete),
  contRetained: withCustomProps(ContNormalNode, editView, handleDelete),
  contSofp: withCustomProps(ContNormalNode, editView, handleDelete),
  contProfit: withCustomProps(ContNormalNode, editView, handleDelete),
  contCash: withCustomProps(ContNormalNode, editView, handleDelete),
});

export const selector = (contextId) => (store) => {
  return {
    addContBlank: (x, y) => store.createNode("contBlank", contextId, x, y),
    addContMarketing: (x, y) => store.createNode("contMarketing", contextId, x, y),
    addContSales: (x, y) => store.createNode("contSales", contextId, x, y),
    addContRevenue: (x, y) => store.createNode("contRevenue", contextId, x, y),
    addContStaff: (x, y) => store.createNode("contStaff", contextId, x, y),
    addContDirectCosts: (x, y) => store.createNode("contDirectCosts", contextId, x, y),
    addContIndirectCosts: (x, y) => store.createNode("contIndirectCosts", contextId, x, y),
    addContExpenses: (x, y) => store.createNode("contExpenses", contextId, x, y),
    addContCapex: (x, y) => store.createNode("contCapex", contextId, x, y),
    addContLoan: (x, y) => store.createNode("contLoan", contextId, x, y),
    addContReceivables: (x, y) => store.createNode("contReceivables", contextId, x, y),
    addContPayables: (x, y) => store.createNode("contPayables", contextId, x, y),
    addContShareCap: (x, y) => store.createNode("contShareCap", contextId, x, y),
    addContRetained: (x, y) => store.createNode("contRetained", contextId, x, y),
    addContSofp: (x, y) => store.createNode("contSofp", contextId, x, y),
    addContProfit: (x, y) => store.createNode("contProfit", contextId, x, y),
    addContCash: (x, y) => store.createNode("contCash", contextId, x, y),
  };
};
