const CurrentRatio = ({ monthIndex, yaxisData }) => {
  const caA = yaxisData.sofp.find((item) => item.description === "totalCurrentAssetsLong").actual[monthIndex];
  const clA = yaxisData.sofp.find((item) => item.description === "totalCurrentLiabilitiesLong").actual[monthIndex];
  const crA = clA === 0 ? 0 : caA / clA;

  const caS = yaxisData.sofp.find((item) => item.description === "totalCurrentAssetsLong").statements[monthIndex];
  const clS = yaxisData.sofp.find((item) => item.description === "totalCurrentLiabilitiesLong").statements[monthIndex];
  const crS = clS === 0 ? 0 : caS / clS;

  return (
    <>
      <div className="flex items-center gap-2 pb-4">
        <svg
          className="text-primary h-8 w-auto"
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m16 11.78l4.24-7.33l1.73 1l-5.23 9.05l-6.51-3.75L5.46 19H22v2H2V3h2v14.54L9.5 8z"
          />
        </svg>
        <h3 className="text-lg font-semibold">Current ratio</h3>
      </div>
      <div className="flex gap-8">
        <div className="flex flex-col">
          <p className="text-secondary font-medium">Actual</p>
          <p className="text-2xl font-semibold">{Number(crA.toFixed(2)).toLocaleString()}</p>
        </div>
        <p>vs</p>
        <div className="flex flex-col">
          <p className="text-secondary font-medium">Projection</p>
          <p className="text-2xl font-semibold">{Number(crS.toFixed(2)).toLocaleString()}</p>
        </div>
      </div>
    </>
  );
};

export default CurrentRatio;
