import { useStore } from "../../store";
import { OutputsBox } from "../utils/RightBarBox";
import { nanoid } from "nanoid";
import { NestedList } from "../utils/NestedList";

import { colors, descriptions, svgs } from "../../components/Panel/constants";
import { lightenColor } from "../../components/Panel/colorManipulation";
import { DisplayIcon } from "./1ContMarketingDetail";
import { DisplaySection, styleOverall, styleDescription } from "./1ContMarketingDetail";

export const selector = (id, data) => (store) => ({
  setGeneral: (key, value) => {
    store.updateNode(id, { general: { ...data.general, [key]: value } });
  },
  setInputsCustom: (index, key, value) => {
    const updated = [...data.inputsSelf];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    if (key === "value" && updated[index].custom.value === updated[index].custom.max) {
      updated[index].custom = { ...updated[index].custom, value: value, max: value };
      store.updateNode(id, { inputsSelf: updated });
      return;
    }

    updated[index].custom = { ...updated[index].custom, [key]: value };
    store.updateNode(id, { inputsSelf: updated });
  },
  setInputs: (index, key, value) => {
    const updated = [...data.inputsSelf];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    if (key === "value" && updated[index].value === updated[index].max) {
      updated[index] = { ...updated[index], value: value, max: value };
      store.updateNode(id, { inputsSelf: updated });
      return;
    }

    updated[index] = { ...updated[index], [key]: value };
    store.updateNode(id, { inputsSelf: updated });
  },
  addInput: () => {
    store.updateNode(id, {
      inputsSelf: [
        ...data.inputsSelf,
        {
          id: nanoid(),
          description: "",
          custom: {
            value: 0,
            min: 0,
            max: 0,
            change: 0,
            start: "",
          },
          long: [],
          longCustom: [],
          impact: "",
        },
      ],
    });
  },
  deleteInput: (index) => {
    const updated = [...data.inputsSelf];
    updated.splice(index, 1);
    store.updateNode(id, { inputsSelf: updated });
  },
  setCustomLong: (index, colIndex, value) => {
    const updated = [...data.inputsSelf];

    if (isNaN(value) || !isFinite(value)) {
      value = null;
    }

    if (value === null) {
      updated[index].longCustom[colIndex] = value;
      store.updateNode(id, { inputsSelf: updated });
      return;
    }

    if (updated[index].long[colIndex] !== value) {
      updated[index].longCustom[colIndex] = value;
      store.updateNode(id, { inputsSelf: updated });
    }
  },
  resetCustomLong: (index) => {
    const updated = [...data.inputsSelf];
    updated[index].longCustom = updated[index].longCustom.map(() => null);
    store.updateNode(id, { inputsSelf: updated });
  },
});

const ContCostsDetail = ({ node, assumption }) => {
  const { id, data, type } = node;
  const {
    setGeneral, //
    setInputsCustom,
    setInputs,
    addInput,
    deleteInput,
    setCustomLong,
    resetCustomLong,
  } = useStore(selector(id, data));

  const iconColor = colors[type];
  const bgColor = lightenColor(iconColor, 80);
  const Icon = svgs[type];

  return (
    <div className={styleOverall}>
      <div>
        <DisplayIcon description={descriptions[type]} Icon={Icon} iconColor={iconColor} bgColor={bgColor} />
      </div>

      <div>
        <DisplaySection description="General" />
        <div className={styleDescription}>
          <span className="font-medium">Description</span>
          <input
            type="text"
            name="text"
            className="h-full w-60 px-2 border-b"
            maxLength={20}
            value={data.general.description}
            onChange={(e) => setGeneral("description", e.target.value)}
          />
        </div>
      </div>

      <div>
        <DisplaySection description="Inputs (Linked from other containers)" />
        <NestedList //
          inputsSelf={data.inputsLink}
          assumptions={assumption}
          cannotEdit={true}
        />
      </div>

      <div>
        <DisplaySection description="Inputs (Added in this container)" />
        <NestedList
          inputsSelf={data.inputsSelf}
          setInputsCustom={setInputsCustom}
          setInputs={setInputs}
          addInput={addInput}
          deleteInput={deleteInput}
          setCustomLong={setCustomLong}
          resetCustomLong={resetCustomLong}
          assumptions={assumption}
          cannotEdit={false}
        />
      </div>

      <div>
        <DisplaySection description="Outputs" />
        <OutputsBox outputs={data.outputs} assumptions={assumption} />
      </div>
    </div>
  );
};

export default ContCostsDetail;
