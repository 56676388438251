export const nonEditable = (cell) => {
  return {
    ...cell,
    nonEditable: true,
    className: `${cell.className}`,
  };
};

export const editable = (cell) => {
  const hasBgClass = cell.className && /bg-[\w-]+/.test(cell.className);

  return {
    ...cell,
    nonEditable: false,
    className: cell.className //
      ? hasBgClass
        ? `${cell.className}`
        : `${cell.className} bg-yellow-50`
      : "bg-yellow-50",
  };
};

export const emptyTextCell = (className, style) => {
  return {
    type: "text",
    text: "",
    className: `${className}`,
    style,
  };
};

export const textCell = (text, className, style = {}) => {
  return {
    type: "text",
    text,
    className: `${className}`,
    style,
  };
};

export const numberCell = (value, className, style) => {
  const numberFormat = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return {
    type: "number",
    value,
    className: `${className}`,
    style,
    format: numberFormat,
    nanToZero: true,
    hideZero: false,
  };
};

export const decimalCell = (value, className, style, decimal) => {
  const numberFormat = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });

  return {
    type: "number",
    value,
    className: `${className}`,
    style,
    format: numberFormat,
    nanToZero: true,
    hideZero: false,
  };
};
