const ROI = ({ monthIndex, yaxisData }) => {
  const npA = yaxisData.sopl.find((item) => item.description === "totalProfitLong").actual[monthIndex];
  const teA = yaxisData.sofp.find((item) => item.description === "totalEquityLong").actual[monthIndex];
  const roiA = teA === 0 ? 0 : (npA / teA) * 100;

  const npS = yaxisData.sopl.find((item) => item.description === "totalProfitLong").statements[monthIndex];
  const teS = yaxisData.sofp.find((item) => item.description === "totalEquityLong").statements[monthIndex];
  const roiS = teS === 0 ? 0 : (npS / teS) * 100;

  return (
    <>
      <div className="flex items-center gap-2 pb-4">
        <svg
          className="text-primary h-8 w-auto"
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m16 11.78l4.24-7.33l1.73 1l-5.23 9.05l-6.51-3.75L5.46 19H22v2H2V3h2v14.54L9.5 8z"
          />
        </svg>
        <h3 className="text-lg font-semibold">Return on Investment</h3>
      </div>
      <div className="flex gap-8">
        <div className="flex flex-col">
          <p className="text-secondary font-medium">Actual</p>
          <p className="text-2xl font-semibold">{roiA.toFixed(2)}%</p>
        </div>
        <p>vs</p>
        <div className="flex flex-col">
          <p className="text-secondary font-medium">Projection</p>
          <p className="text-2xl font-semibold">{roiS.toFixed(2)}%</p>
        </div>
      </div>
    </>
  );
};

export default ROI;
