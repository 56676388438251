import { useActualStore } from "../../../stores/useActualStore";
import { selector } from "./SOFP";
import { useSaveContext } from "../../../saveAndRestore/useSaveContext";

import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "../0reactgrid/styles.css";
import { getColumns } from "./getColumns";
import { getRowsSOCF } from "./getRows";

const SOCF = ({ contextId, monthIndex, differences, editView }) => {
  const columns = getColumns();
  const rows = getRowsSOCF({ monthIndex, differences, editView });
  const { updateData } = useActualStore(selector(contextId, monthIndex));

  const { autoSave } = useSaveContext();
  const handleChanges = (changes) => {
    updateData(changes);
    autoSave();
  };

  return (
    <ReactGrid
      rows={rows}
      columns={columns}
      onCellsChanged={handleChanges}
      stickyTopRows={1}
      stickyLeftColumns={1}
      enableRangeSelection
    />
  );
};

export default SOCF;
