import { useAssumptionsStore } from "../../../stores/useAssumptionsStore";
import { usePriorYearsStore } from "../../../stores/usePriorYearsStore";
import { useSnackbarContext } from "../../../hooks/SnackbarContext";
import { useSaveContext } from "../../../saveAndRestore/useSaveContext";
import { selector, selector2 } from "./SOFP";

import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "../0reactgrid/styles.css";
import { getColumns } from "./getColumns";
import { getRowsSOCF } from "./getRows";

const SOCF = ({ contextId, assumption, priorYear, editView }) => {
  const columns = getColumns({ assumption });
  const rows = getRowsSOCF({ assumption, priorYear, editView });
  const { updateMonths } = useAssumptionsStore(selector2(contextId));
  const { updateData, addCol, deleteCol } = usePriorYearsStore(selector(contextId));
  const { showMessage } = useSnackbarContext();

  const { autoSave } = useSaveContext();
  const handleChanges = (changes) => {
    const monthsChanges = changes.filter(
      (change) => change.rowId === "monthsNames" || change.rowId === "monthsNumbers"
    );
    const otherChanges = changes.filter((change) => change.rowId !== "monthsNames" && change.rowId !== "monthsNumbers");
    if (monthsChanges.length > 0) {
      updateMonths(monthsChanges);
    }
    if (otherChanges.length > 0) {
      updateData(otherChanges);
    }
    autoSave();
  };
  const handleAddCol = () => {
    addCol();
    autoSave();
  };
  const handleDeleteCol = () => {
    if (assumption.priorLength > 1) {
      deleteCol(assumption.priorLength - 1);
    } else {
      showMessage("At least 1 past data must be present.", "warning");
    }
    autoSave();
  };

  return (
    <>
      <>
        <button onClick={handleAddCol}>add col</button>
        <button onClick={handleDeleteCol}>delete col</button>
      </>
      <ReactGrid
        rows={rows}
        columns={columns}
        onCellsChanged={handleChanges}
        stickyTopRows={3}
        stickyLeftColumns={1}
        enableRangeSelection
      />
    </>
  );
};

export default SOCF;
