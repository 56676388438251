import { useEffect } from "react";
import axios from "../axios/axios";
import { useAuthContext } from "../auth/useAuthContext";
import { useNavigate } from "react-router-dom";

const Callback = () => {
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      // get params from url
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      // POST /oauth/token
      const response = await axios.post(
        "/api/user/oauth/login",
        { code },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const { email, accessToken } = response.data;
      dispatch({ type: "LOGIN", payload: { email, accessToken } });

      const path = sessionStorage.getItem("path") || "/";
      sessionStorage.removeItem("path");
      navigate(path);
    };

    handleCallback();
  }, [dispatch, navigate]);

  return <div>Processing OAuth Callback...</div>;
};

export default Callback;
