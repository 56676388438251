import { Routes, Route } from "react-router-dom";

import Main from "./Main";
import Success from "./Success";

const SubscriptionRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/stripesuccess" element={<Success />} />
    </Routes>
  );
};

export default SubscriptionRoutes;
