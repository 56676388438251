const Expand = ({ expand }) => (
  <svg
    className="h-6 w-auto text-gray-500 cursor-pointer"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    {expand ? (
      <path fill="none" stroke="currentColor" strokeWidth="2" d="m18 9l-6 6l-6-6" />
    ) : (
      <path fill="none" stroke="currentColor" strokeWidth="2" d="m9 6l6 6l-6 6" />
    )}
  </svg>
);

export default Expand;
