import SubscriptionCSS from "./Subscription.module.css";
import { useAxiosPrivate } from "../../axios/useAxiosPrivate";
import { useAuthContext } from "../../auth/useAuthContext";

import { useGeneralStore } from "../../stores/useGeneralStore";

const Main = () => {
  const { user } = useAuthContext();

  const axiosPrivate = useAxiosPrivate();
  const subscription = useGeneralStore((state) => state.subscription);
  const setSubscription = useGeneralStore.getState().setSubscription;

  const handleBuy = async (name) => {
    if (name === "Free Trial") {
      const response = await axiosPrivate.patch("/api/subscriptions", { name });
      setSubscription(response.data);
    } else {
      const postStripe = async (user, name, amount) => {
        if (user) {
          const apiUrl =
            process.env.REACT_APP_NODE_ENV === "production"
              ? process.env.REACT_APP_BACKEND_URL_PROD
              : process.env.REACT_APP_BACKEND_URL_DEV;

          const response = await fetch(`${apiUrl}api/stripe`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${user.token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ name, amount }),
          });
          const json = await response.json();

          if (response.ok) {
            window.location = json.url;
            return 1;
          }
          return 0;
        }
      };

      await postStripe(user, name);
    }
  };

  return (
    <div className={SubscriptionCSS.main}>
      <div className={SubscriptionCSS.item}>
        <h1>Free Trial</h1>
        <p>14 days free trial of Premium features</p>
        <h2>Free</h2>
        {subscription &&
          (subscription.usedFreeTrial ? (
            <h4 disabled>Claimed!</h4>
          ) : (
            <h4 onClick={() => handleBuy("Free Trial")}>Try out now!</h4>
          ))}
      </div>
      <div className={SubscriptionCSS.item}>
        <h1>Premium</h1>
        <p>Unlimited - access to all functionalities</p>
        <h2>SGD 3,000</h2>
        <h4 onClick={() => handleBuy("Premium")}>Buy now!</h4>
      </div>
      <div className={SubscriptionCSS.item}>
        <h1>Pro</h1>
        <p>Unlimited - Premium features + access to advisors / investors / networking</p>
        <h2>SGD 3,500</h2>
        <h4 onClick={() => handleBuy("Pro")}>Buy now!</h4>
      </div>
    </div>
  );
};

export default Main;
