import { useState } from "react";
import { useSignup } from "../auth/useSignup";

import { styleInput, styleButton, styleError } from "./Login";

const Signup = ({ toLoginPage }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signup, error, isLoading } = useSignup();

  const handleSignup = async (e) => {
    e.preventDefault();
    await signup(email, password);
  };

  const handleClick = (e) => {
    e.preventDefault();
    toLoginPage();
  };

  return (
    <div className="w-96 bg-white absolute p-5 rounded-xl top-28 right-8">
      <p className="font-semibold text-2xl">Sign in to Simplyfi Forecast</p>
      <p className="text-gray-500 mb-8">Create a profile to save your progress!</p>

      <>
        <p>Sign Up</p>
        <input
          className={styleInput}
          type="email"
          id="email"
          autoComplete="email"
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className={styleInput}
          type="password"
          id="password"
          value={password}
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className={styleButton} disabled={isLoading} onClick={handleSignup}>
          Sign Up
        </button>
        {error && <div className={styleError}>{error}</div>}
      </>

      <div className="text-center mt-2">
        <span>Have an account?&nbsp;</span>
        <span className="text-primary cursor-pointer" onClick={handleClick}>
          Login
        </span>
      </div>
    </div>
  );
};

export default Signup;
