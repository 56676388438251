import { useState } from "react";

const videoList = [
  {
    title: "Summary Overview",
    description: "Get a quick update of your overall performance & track key metrics at a glance!",
    videoUrl: "https://www.youtube.com/embed/7xidOWnzSu4",
  },
  {
    title: "Expense Breakdown",
    description: "Tracking your spending, setting your budget goals, and exploring expenses categories!",
    videoUrl: "https://www.youtube.com/embed/hDZ7y8RP5HE",
  },
  {
    title: "Revenue Projection",
    description: "Adjusting our projections to estimate your future earnings based on past data!",
    videoUrl: "https://www.youtube.com/embed/jWM0ct-OLsM",
  },
  {
    title: "Funding Needs",
    description: "A well-rounded budget can help you prepare for unexpected expenses.",
    videoUrl: "https://www.youtube.com/embed/CaimKeDcudo",
  },
  {
    title: "Cashflow Forecast",
    description: "Anticipating cash inflows and outflows & also ensure you maintain a positive cash flow",
    videoUrl: "https://www.youtube.com/embed/mYfJxlgR2jw",
  },
  {
    title: "Funding Sources",
    description: "Considering diverse funding sources-grants,loans and investments",
    videoUrl: "https://www.youtube.com/embed/xOsLIiBStEs",
  },
];

const Help = () => {
  const [video, setVideo] = useState(0);

  return (
    <div className="flex flex-col gap-4 p-6">
      <h1 className="text-xl font-semibold">Get to know our features</h1>

      <div className="flex justify-center">
        <iframe
          className="w-1/2 aspect-video"
          src={videoList[video].videoUrl}
          title={videoList[video].title}
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </div>

      <div className="flex justify-center">
        <div className="grid grid-cols-3 grid-rows-2 gap-4 w-3/4">
          {videoList.map((item, index) => (
            <div //
              key={index}
              className={`flex flex-col gap-2 border-2 rounded-xl p-4 cursor-pointer
              transition duration-300 hover:border-primary hover:bg-primarySuperLight
              ${video === index ? "border-primary bg-primarySuperLight" : "border-gray-300 bg-white"}`}
              onClick={() => setVideo(index)}
            >
              <span className="font-semibold">{item.title}</span>
              <span className="text-xs text-gray-500">{item.description}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Help;
