import { updateStartDate, fillLong } from "./1utilsCalculateMarketing";
import { generateLong } from "./utilsGenerate";

export const calculateContCosts = (nodes, node, edges, assumption) => {
  const { startDate, months } = assumption;

  // inputsLink
  let inputsLink = [];

  const myEdges = edges.filter((edge) => edge.target === node.id);
  myEdges.forEach((edge) => {
    const sourceId = edge.source;
    const sourceHandle = edge.sourceHandle;

    const sourceNode = nodes.find((node) => node.id === sourceId);
    const output = sourceNode.data.outputs.find((output) => output.id === sourceHandle);
    if (output) {
      inputsLink.push({
        id: output.id,
        description: output.description,
        long: output.long,
        impact: "",
      });
    }
  });

  // inputs
  let inputsSelf = [...node.data.inputsSelf];
  inputsSelf = updateStartDate(inputsSelf, startDate);
  inputsSelf = fillLong(inputsSelf, months);
  inputsSelf.forEach((input) => {
    const long = generateLong(input, assumption);
    const roundedLong = long.map((element) => Math.round(element));
    input.long = roundedLong;
  });

  // outputs
  let outputs = [...inputsLink, ...inputsSelf];

  const total = node.data.outputs[node.data.outputs.length - 1];

  const initialTotalLong = Array(months).fill(0);

  total.long = [...inputsLink, ...inputsSelf].reduce((acc, child) => {
    return acc.map((num, idx) => num + child.long[idx]);
  }, initialTotalLong);

  outputs = [...outputs, total];

  return [inputsLink, inputsSelf, outputs];
};
