import { useAssumptionsStore } from "../../stores/useAssumptionsStore";
import { useSaveContext } from "../../saveAndRestore/useSaveContext";

import { InputNumber } from "primereact/inputnumber";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const currencyChoices = [
  { label: "$", value: "$" },
  { label: "€", value: "€" },
  { label: "£", value: "£" },
  { label: "¥", value: "¥" },
];

const monthsChoices = [
  { label: "March", value: 3 },
  { label: "June", value: 6 },
  { label: "September", value: 9 },
  { label: "December", value: 12 },
];

const Settings = ({ contextId }) => {
  const updateAssumptions = useAssumptionsStore.getState().updateAssumptions;
  const assumptions = useAssumptionsStore((state) => state.assumptions);
  const assumption = assumptions.find((item) => item.contextId === contextId);

  // save
  const { autoSave } = useSaveContext();
  const handleSetAssumptions = (key, value) => {
    updateAssumptions(contextId, key, value);
    autoSave();
  };

  const styleGrid = "flex flex-col";
  const styleQues = "text-secondary font-semibold";

  return (
    <div className="m-6">
      <h1 className="text-xl font-semibold mb-6">Edit Assumptions</h1>

      <div className="grid grid-cols-2 gap-4">
        <div className={styleGrid}>
          <span className={styleQues}>Currency</span>
          <select
            className="h-14 w-full px-4 text-gray-500 bg-white border border-gray-300 rounded-md"
            name="select"
            value={assumption.currency}
            onChange={(e) => handleSetAssumptions("currency", e.target.value)}
          >
            {currencyChoices.map((choice, index) => (
              <option key={index} value={choice.value}>
                {choice.label}
              </option>
            ))}
          </select>
        </div>

        <div className={styleGrid}>
          <span className={styleQues}>Start date</span>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={["month", "year"]}
              value={dayjs(assumption.startDate)}
              onChange={(date) => {
                if (date) {
                  const year = date.year();
                  const month = date.month() + 1;
                  handleSetAssumptions("startDate", `${year}-${month}`);
                }
              }}
              sx={{
                "& .MuiInputBase-root": {
                  height: "3.5rem",
                  padding: "0 1rem",
                  backgroundColor: "white",
                  borderRadius: "0.375rem",
                },
                "& .MuiInputBase-input": {
                  padding: "0rem",
                  color: "rgb(107 114 128)",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgb(209 213 219)",
                },
              }}
            />
          </LocalizationProvider>
        </div>

        <div className={styleGrid}>
          <span className={styleQues}>Period end</span>
          <select
            className="h-14 w-full px-4 text-gray-500 bg-white border border-gray-300 rounded-md"
            name="select"
            value={assumption.periodEnd}
            onChange={(e) => handleSetAssumptions("periodEnd", e.target.value)}
          >
            {monthsChoices.map((choice, index) => (
              <option key={index} value={choice.value}>
                {choice.label}
              </option>
            ))}
          </select>
        </div>

        <div className={styleGrid}>
          <span className={styleQues}>Months</span>
          <InputNumber
            inputClassName="h-14 w-full px-4 text-gray-500 bg-white border border-gray-300 rounded-md"
            name="InputNumberSimple"
            value={assumption.months}
            onValueChange={(e) => handleSetAssumptions("months", parseInt(e.value))}
            minFractionDigits={0}
            maxFractionDigits={0}
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;
