import { createContext, useState, useContext } from "react";
import { Snackbar, Alert, Slide } from "@mui/material";

const SlideDown = (props) => {
  return <Slide {...props} direction="down" />;
};

const SnackbarContext = createContext();

export const useSnackbarContext = () => useContext(SnackbarContext);

export const SnackbarContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  // severity - success, info, warning, error

  const showMessage = (msg, severityType = "error") => {
    setMessage(msg);
    setSeverity(severityType);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      {children}
      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionComponent={SlideDown}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert onClose={handleClose} severity={severity}>
          <div className="whitespace-pre-line">{message}</div>
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
