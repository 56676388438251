const NotAvailableView = () => {
  return (
    <div
      style={{ height: "calc(100vh - 4rem)" }}
      className="flex items-center justify-center m-8 p-8 bg-white border rounded-2xl"
    >
      <div className="text-center">
        <p>This application is currently only for desktop use.</p>
        <p>Please visit us on a desktop browser for the best experience.</p>
      </div>
    </div>
  );
};

export default NotAvailableView;
