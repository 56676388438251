import MascotWatering from "./MascotWatering";
import { Link } from "react-router-dom";

const NoContext = () => {
  return (
    <div className="flex flex-grow flex-col items-center justify-center bg-white rounded-3xl">
      <div className="h-60 w-auto">
        <MascotWatering />
      </div>
      <p className="font-semibold text-gray-600 text-lg mt-8 mb-2">No data available yet!</p>
      <p className="font-medium text-gray-500 mb-6">It looks like you don't have any data to display here.</p>
      <Link to="/directory">
        <button className="font-semibold text-primary border border-primary rounded-xl px-8 py-2">
          Manage in Directory
        </button>
      </Link>
    </div>
  );
};

export default NoContext;
