import { nonEditable, editable, textCell, numberCell } from "../0reactgrid/cells";

import {
  ROW_HEIGHT_28,
  // ROW_HEIGHT_34,
  // ROW_HEIGHT_40,
  // ROW_HEIGHT_44
} from "../2projection/getRows";

import {
  getBlankRow,
  getHeaderRow,
  getSubheaderRow,
  getSubSubheaderRow,
  getSubtotalRow,
  getSubSubtotalRow,
  getTotalRow,
  getCheckRow,
} from "../2projection/getRows";

const getContentRow = ({ category, description, longs, editView }) => {
  const className1 = "text-sm font-normal !pl-8";

  const rows = longs.map((long) => ({
    rowId: `${category}|${description}|${long.id}`,
    height: ROW_HEIGHT_28,
    cells: [
      nonEditable(textCell(long.description, className1)),
      ...long.long.map((value) =>
        editView === "edit" ? editable(numberCell(value, className1)) : nonEditable(numberCell(value, className1))
      ),
    ],
  }));

  return rows;
};

const getContentSOPLRow = ({ category, description, longs, editView }) => {
  const className1 = "text-sm font-normal !pl-8";

  const rows = longs.map((long) => ({
    rowId: `${category}|${description}|${long.id}`,
    height: ROW_HEIGHT_28,
    cells: [
      nonEditable(textCell(long.containerDesc, className1)),
      ...long.long.map((value) =>
        editView === "edit" ? editable(numberCell(value, className1)) : nonEditable(numberCell(value, className1))
      ),
    ],
  }));

  return rows;
};

export const getRowsSOFP = ({ assumption, actual, editView }) => {
  const { months } = assumption;

  const longProperties = actual.sofp.reduce((acc, item) => {
    acc[item.description] = item.long;
    return acc;
  }, {});
  const {
    equityLongs,
    nonCurrentAssetsLongs,
    currentAssetsLongs,
    nonCurrentLiabilitiesLongs,
    currentLiabilitiesLongs,
    totalAssetsLong,
    totalNonCurrentAssetsLong,
    totalCurrentAssetsLong,
    totalEquityLong,
    totalLiabilitiesLong,
    totalNonCurrentLiabilitiesLong,
    totalCurrentLiabilitiesLong,
    checkLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthly: true, assumption })];

  // ASSETS
  rows = [...rows, ...getSubheaderRow({ months, title: "ASSETS" })];
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Non-current assets" }),
    ...getContentRow({
      category: "sofp",
      description: "nonCurrentAssetsLongs",
      longs: nonCurrentAssetsLongs,
      editView,
    }),
    ...getSubtotalRow({ title: "Non-current assets", long: totalNonCurrentAssetsLong }),
    ...getBlankRow({ months }),
  ];
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Current assets" }),
    ...getContentRow({
      category: "sofp",
      description: "currentAssetsLongs",
      longs: currentAssetsLongs,
      editView,
    }),
    ...getSubtotalRow({ title: "Current assets", long: totalCurrentAssetsLong }),
  ];
  rows = [...rows, ...getTotalRow({ title: "Total Assets", long: totalAssetsLong }), ...getBlankRow({ months })];

  // EQUITY
  rows = [
    ...rows,
    ...getSubheaderRow({ months, title: "EQUITY" }),
    ...getContentRow({
      category: "sofp",
      description: "equityLongs",
      longs: equityLongs,
      editView,
    }),
    ...getTotalRow({ title: "Total Equity", long: totalEquityLong }),
    ...getBlankRow({ months }),
  ];

  // LIABILITIES
  rows = [...rows, ...getSubheaderRow({ months, title: "LIABILITIES" })];

  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Non-current liabilities" }),
    ...getContentRow({
      category: "sofp",
      description: "nonCurrentLiabilitiesLongs",
      longs: nonCurrentLiabilitiesLongs,
      editView,
    }),
    ...getSubtotalRow({ title: "Non-current liabilities", long: totalNonCurrentLiabilitiesLong }),
    ...getBlankRow({ months }),
  ];
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Current liabilities" }),
    ...getContentRow({
      category: "sofp",
      description: "currentLiabilitiesLongs",
      longs: currentLiabilitiesLongs,
      editView,
    }),
    ...getSubtotalRow({ title: "Current liabilities", long: totalCurrentLiabilitiesLong }),
  ];
  rows = [
    ...rows,
    ...getTotalRow({ title: "Total Liabilities", long: totalLiabilitiesLong }),
    ...getBlankRow({ months }),
  ];

  // OVERALL
  rows = [...rows, ...getCheckRow({ title: "Check", long: checkLong }), ...getBlankRow({ months })];

  return rows;
};

export const getRowsSOPL = ({ assumption, actual, editView }) => {
  const { months } = assumption;

  const longProperties = actual.sopl.reduce((acc, item) => {
    acc[item.description] = item.long;
    return acc;
  }, {});
  const {
    revenueLongs,
    expensesLongs,
    intExpLongs,
    taxLongs,
    totalRevenueLong,
    totalExpensesLong,
    totalIntExpLong,
    totalTaxLong,
    totalPbitLong,
    totalPbtLong,
    totalProfitLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthly: true, assumption })];

  rows = [...rows, ...getSubSubtotalRow({ title: "Revenue", long: totalRevenueLong })];
  rows = [
    ...rows,
    ...getContentSOPLRow({ category: "sopl", description: "revenueLongs", longs: revenueLongs, editView }),
  ];
  rows = [...rows, ...getSubSubtotalRow({ title: "(-)Expenses", long: totalExpensesLong })];
  rows = [
    ...rows,
    ...getContentSOPLRow({ category: "sopl", description: "expensesLongs", longs: expensesLongs, editView }),
  ];
  rows = [...rows, ...getSubtotalRow({ title: "PBIT", long: totalPbitLong })];
  rows = [...rows, ...getSubSubtotalRow({ title: "(-)Interest expenses", long: totalIntExpLong })];
  rows = [
    ...rows,
    ...getContentSOPLRow({ category: "sopl", description: "intExpLongs", longs: intExpLongs, editView }),
  ];
  rows = [...rows, ...getSubtotalRow({ title: "PBT", long: totalPbtLong })];
  rows = [...rows, ...getSubSubtotalRow({ title: "(-)Tax expense", long: totalTaxLong })];
  rows = [...rows, ...getContentSOPLRow({ category: "sopl", description: "taxLongs", longs: taxLongs, editView })];
  rows = [...rows, ...getTotalRow({ title: "Profit/(Loss)", long: totalProfitLong }), ...getBlankRow({ months })];

  return rows;
};

export const getRowsSOCF = ({ assumption, actual, editView }) => {
  const { months } = assumption;

  const longProperties = actual.socf.reduce((acc, item) => {
    acc[item.description] = item.long;
    return acc;
  }, {});
  const {
    operatingLongs,
    investingLongs,
    financingLongs,
    totalOperatingLong,
    totalInvestingLong,
    totalFinancingLong,
    totalLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthly: true, assumption })];

  // OPERATING
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Cash flow from operating activities" }),
    ...getContentRow({
      category: "socf",
      description: "operatingLongs",
      longs: operatingLongs,
      editView,
    }),
    ...getSubtotalRow({ title: "Cash flow from operating activities", long: totalOperatingLong }),
    ...getBlankRow({ months }),
  ];

  // INVESTING
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Cash flow from investing activities" }),
    ...getContentRow({
      category: "socf",
      description: "investingLongs",
      longs: investingLongs,
      editView,
    }),
    ...getSubtotalRow({ title: "Cash flow from investing activities", long: totalInvestingLong }),
    ...getBlankRow({ months }),
  ];

  // FINANCING
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Cash flow from financing activities" }),
    ...getContentRow({
      category: "socf",
      description: "financingLongs",
      longs: financingLongs,
      editView,
    }),
    ...getSubtotalRow({ title: "Cash flow from financing activities", long: totalFinancingLong }),
    ...getBlankRow({ months }),
  ];

  rows = [...rows, ...getTotalRow({ title: "Total cash flow movement", long: totalLong }), ...getBlankRow({ months })];

  return rows;
};
