import { nonEditable, emptyTextCell, textCell, numberCell } from "../0reactgrid/cells";
import { nanoid } from "nanoid";

export const ROW_HEIGHT_28 = 28;
export const ROW_HEIGHT_34 = 34;
export const ROW_HEIGHT_40 = 40;
export const ROW_HEIGHT_44 = 44;

// same as actual

export const getBlankRow = ({ months }) => {
  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_28,
      cells: [...Array.from({ length: months + 1 }, () => nonEditable(emptyTextCell()))],
    },
  ];
};

export const getHeaderRow = ({ monthly, assumption }) => {
  const { priorLength, totalMonths, totalPeriod } = assumption;

  let monthsNames = [];
  let monthsArray = [];
  let monthsNumbers = [];

  if (monthly) {
    monthsNames = assumption.monthsNames.slice(priorLength, totalMonths);
    monthsArray = assumption.monthsArray.slice(priorLength, totalMonths);
    monthsNumbers = assumption.monthsNumbers.slice(priorLength, totalMonths);
  } else {
    monthsNames = assumption.monthsNamesAnnual.slice(priorLength, totalPeriod);
    monthsArray = assumption.monthsArrayAnnual.slice(priorLength, totalPeriod);
    monthsNumbers = assumption.monthsNumbersAnnual.slice(priorLength, totalPeriod);
  }
  const className1 = "font-medium bg-blue-800";
  const className2 = "font-medium bg-blue-800 justify-center";
  const style = { color: "rgb(255 255 255)" };

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell("monthsNames", className1, style)),
        ...monthsNames.map((month) => nonEditable(textCell(month, className2, style))),
      ],
    },
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_28,
      cells: [
        nonEditable(textCell("monthsArray", className1, style)),
        ...monthsArray.map((month) => nonEditable(textCell(month, className2, style))),
      ],
    },
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_28,
      cells: [
        nonEditable(textCell("monthsNumbers", className1, style)),
        ...monthsNumbers.map((month) => nonEditable(textCell(month, className2, style))),
      ],
    },
  ];
};

export const getSubheaderRow = ({ months, title }) => {
  const className1 = "font-semibold";

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell(title, className1)),
        ...Array.from({ length: months }, () => nonEditable(emptyTextCell())),
      ],
    },
  ];
};

export const getSubSubheaderRow = ({ months, title }) => {
  const className1 = "text-sm font-semibold";

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_34,
      cells: [
        nonEditable(textCell(title, className1)),
        ...Array.from({ length: months }, () => nonEditable(emptyTextCell())),
      ],
    },
  ];
};

export const getSubtotalRow = ({ title, long }) => {
  const className1 = "text-sm font-medium bg-blue-100";

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_34,
      cells: [
        nonEditable(textCell(title, className1)),
        ...long.map((value) => nonEditable(numberCell(value, className1))),
      ],
    },
  ];

  return row;
};

export const getSubSubtotalRow = ({ title, long }) => {
  const className1 = "text-sm font-medium bg-blue-50";

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_34,
      cells: [
        nonEditable(textCell(title, className1)),
        ...long.map((value) => nonEditable(numberCell(value, className1))),
      ],
    },
  ];

  return row;
};

export const getTotalRow = ({ title, long }) => {
  const className1 = "font-semibold bg-blue-200";

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell(title, className1)),
        ...long.map((value) => nonEditable(numberCell(value, className1))),
      ],
    },
  ];

  return row;
};

export const getCheckRow = ({ title, long }) => {
  const className1 = "text-sm font-normal bg-green-100";

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_28,
      cells: [
        nonEditable(textCell(title, className1)),
        ...long.map((value) => {
          const isCheck = value !== 0 && "bg-red-100";
          return nonEditable(numberCell(value, `${className1} ${isCheck}`));
        }),
      ],
    },
  ];

  return row;
};

// special

const getContentRow = ({ category, description, longs }) => {
  const className1 = "text-sm font-normal !pl-8";

  const rows = longs.map((long) => ({
    rowId: `${category}|${description}|${long.id}`,
    height: ROW_HEIGHT_28,
    cells: [
      nonEditable(textCell(long.description, className1)),
      ...long.long.map((value) => nonEditable(numberCell(value, className1))),
    ],
  }));

  return rows;
};

const getContentSOPLRow = ({ category, description, longs }) => {
  const className1 = "text-sm font-normal !pl-8";

  const rows = longs.map((long) => ({
    rowId: `${category}|${description}|${long.id}`,
    height: ROW_HEIGHT_28,
    cells: [
      nonEditable(textCell(long.containerDesc, className1)),
      ...long.long.map((value) => nonEditable(numberCell(value, className1))),
    ],
  }));

  return rows;
};

export const getRowsSOFP = ({ monthly, assumption, statement }) => {
  const { priorLength, totalMonths, totalPeriod } = assumption;

  let months = 0;
  if (monthly) {
    months = totalMonths - priorLength;
  } else {
    months = totalPeriod - priorLength;
  }

  const longProperties = statement.sofp.reduce((acc, item) => {
    acc[item.description] = item.long;
    return acc;
  }, {});
  const {
    equityLongs,
    nonCurrentAssetsLongs,
    currentAssetsLongs,
    nonCurrentLiabilitiesLongs,
    currentLiabilitiesLongs,
    totalAssetsLong,
    totalNonCurrentAssetsLong,
    totalCurrentAssetsLong,
    totalEquityLong,
    totalLiabilitiesLong,
    totalNonCurrentLiabilitiesLong,
    totalCurrentLiabilitiesLong,
    checkLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthly, assumption })];

  // ASSETS
  rows = [...rows, ...getSubheaderRow({ months, title: "ASSETS" })];
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Non-current assets" }),
    ...getContentRow({ category: "sofp", description: "nonCurrentAssetsLongs", longs: nonCurrentAssetsLongs }),
    ...getSubtotalRow({ title: "Non-current assets", long: totalNonCurrentAssetsLong }),
    ...getBlankRow({ months }),
  ];
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Current assets" }),
    ...getContentRow({ category: "sofp", description: "currentAssetsLongs", longs: currentAssetsLongs }),
    ...getSubtotalRow({ title: "Current assets", long: totalCurrentAssetsLong }),
  ];
  rows = [...rows, ...getTotalRow({ title: "Total Assets", long: totalAssetsLong }), ...getBlankRow({ months })];

  // EQUITY
  rows = [
    ...rows,
    ...getSubheaderRow({ months, title: "EQUITY" }),
    ...getContentRow({ category: "sofp", description: "equityLongs", longs: equityLongs }),
    ...getTotalRow({ title: "Total Equity", long: totalEquityLong }),
    ...getBlankRow({ months }),
  ];

  // LIABILITIES
  rows = [...rows, ...getSubheaderRow({ months, title: "LIABILITIES" })];
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Non-current liabilities" }),
    ...getContentRow({
      category: "sofp",
      description: "nonCurrentLiabilitiesLongs",
      longs: nonCurrentLiabilitiesLongs,
    }),
    ...getSubtotalRow({ title: "Non-current liabilities", long: totalNonCurrentLiabilitiesLong }),
    ...getBlankRow({ months }),
  ];
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Current liabilities" }),
    ...getContentRow({ category: "sofp", description: "currentLiabilitiesLongs", longs: currentLiabilitiesLongs }),
    ...getSubtotalRow({ title: "Current liabilities", long: totalCurrentLiabilitiesLong }),
  ];
  rows = [
    ...rows,
    ...getTotalRow({ title: "Total Liabilities", long: totalLiabilitiesLong }),
    ...getBlankRow({ months }),
  ];

  // OVERALL
  rows = [...rows, ...getCheckRow({ title: "Check", long: checkLong }), ...getBlankRow({ months })];

  return rows;
};

export const getRowsSOPL = ({ monthly, assumption, statement }) => {
  const { priorLength, totalMonths, totalPeriod } = assumption;

  let months = 0;
  if (monthly) {
    months = totalMonths - priorLength;
  } else {
    months = totalPeriod - priorLength;
  }

  const longProperties = statement.sopl.reduce((acc, item) => {
    acc[item.description] = item.long;
    return acc;
  }, {});
  const {
    revenueLongs,
    expensesLongs,
    intExpLongs,
    taxLongs,
    totalRevenueLong,
    totalExpensesLong,
    totalIntExpLong,
    totalTaxLong,
    totalPbitLong,
    totalPbtLong,
    totalProfitLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthly, assumption })];

  rows = [...rows, ...getSubSubtotalRow({ title: "Revenue", long: totalRevenueLong })];
  rows = [...rows, ...getContentSOPLRow({ category: "sopl", description: "revenueLongs", longs: revenueLongs })];
  rows = [...rows, ...getSubSubtotalRow({ title: "(-)Expenses", long: totalExpensesLong })];
  rows = [...rows, ...getContentSOPLRow({ category: "sopl", description: "expensesLongs", longs: expensesLongs })];
  rows = [...rows, ...getSubtotalRow({ title: "PBIT", long: totalPbitLong })];
  rows = [...rows, ...getSubSubtotalRow({ title: "(-)Interest expenses", long: totalIntExpLong })];
  rows = [...rows, ...getContentSOPLRow({ category: "sopl", description: "intExpLongs", longs: intExpLongs })];
  rows = [...rows, ...getSubtotalRow({ title: "PBT", long: totalPbtLong })];
  rows = [...rows, ...getSubSubtotalRow({ title: "(-)Tax expense", long: totalTaxLong })];
  rows = [...rows, ...getContentSOPLRow({ category: "sopl", description: "taxLongs", longs: taxLongs })];
  rows = [...rows, ...getTotalRow({ title: "Profit/(Loss)", long: totalProfitLong }), ...getBlankRow({ months })];

  return rows;
};

export const getRowsSOCF = ({ monthly, assumption, statement }) => {
  const { priorLength, totalMonths, totalPeriod } = assumption;

  let months = 0;
  if (monthly) {
    months = totalMonths - priorLength;
  } else {
    months = totalPeriod - priorLength;
  }

  const longProperties = statement.socf.reduce((acc, item) => {
    acc[item.description] = item.long;
    return acc;
  }, {});
  const {
    operatingLongs,
    investingLongs,
    financingLongs,
    totalOperatingLong,
    totalInvestingLong,
    totalFinancingLong,
    totalLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthly, assumption })];

  // OPERATING
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Cash flow from operating activities" }),
    ...getContentRow({ category: "socf", description: "operatingLongs", longs: operatingLongs }),
    ...getSubtotalRow({ title: "Cash flow from operating activities", long: totalOperatingLong }),
    ...getBlankRow({ months }),
  ];

  // INVESTING
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Cash flow from investing activities" }),
    ...getContentRow({ category: "socf", description: "investingLongs", longs: investingLongs }),
    ...getSubtotalRow({ title: "Cash flow from investing activities", long: totalInvestingLong }),
    ...getBlankRow({ months }),
  ];

  // FINANCING
  rows = [
    ...rows,
    ...getSubSubheaderRow({ months, title: "Cash flow from financing activities" }),
    ...getContentRow({ category: "socf", description: "financingLongs", longs: financingLongs }),
    ...getSubtotalRow({ title: "Cash flow from financing activities", long: totalFinancingLong }),
    ...getBlankRow({ months }),
  ];

  rows = [...rows, ...getTotalRow({ title: "Total cash flow movement", long: totalLong }), ...getBlankRow({ months })];

  return rows;
};
