export const styleOuter = "h-8 w-auto text-secondary";

const Forecast = () => (
  <svg className={styleOuter} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4503_7263)">
      <g clipPath="url(#clip1_4503_7263)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.2487 0.833008C5.8584 0.833008 3.92505 1.30494 2.61451 2.61548C1.30397 3.92603 0.832031 5.85938 0.832031 8.24967V13.7497C0.832031 16.14 1.30397 18.0733 2.61451 19.3839C3.92505 20.6944 5.8584 21.1663 8.2487 21.1663H13.7487C16.139 21.1663 18.0723 20.6944 19.3829 19.3839C20.6934 18.0733 21.1654 16.14 21.1654 13.7497V8.24967C21.1654 5.85938 20.6934 3.92603 19.3829 2.61548C18.0723 1.30494 16.139 0.833008 13.7487 0.833008H8.2487ZM2.83203 8.24967C2.83203 6.05664 3.27676 4.78166 4.02872 4.0297C4.78068 3.27774 6.05566 2.83301 8.2487 2.83301H13.7487C15.9417 2.83301 17.2167 3.27774 17.9687 4.0297C18.7206 4.78166 19.1654 6.05664 19.1654 8.24967V13.7497C19.1654 15.9427 18.7206 17.2177 17.9687 17.9697C17.2167 18.7216 15.9417 19.1663 13.7487 19.1663H8.2487C6.05566 19.1663 4.78068 18.7216 4.02872 17.9697C3.27676 17.2177 2.83203 15.9427 2.83203 13.7497V8.24967ZM10.3032 5.29965C9.31143 5.29965 8.50818 6.10298 8.50818 7.09467V9.47053H6.83702C5.84534 9.47053 5.04204 10.2737 5.04204 11.2655V15.9588C5.04204 16.373 5.37782 16.7088 5.79204 16.7088H9.26618C9.30609 16.7088 9.34526 16.7057 9.38348 16.6997H12.7324L12.7355 16.6997L12.7387 16.6997H16.2128C16.627 16.6997 16.9628 16.3639 16.9628 15.9497V12.8238H16.9629L16.9627 12.8119C16.9472 11.8361 16.1568 11.0288 15.1678 11.0288H13.4824V7.09467C13.4824 6.11161 12.6968 5.29965 11.6965 5.29965H10.3032ZM8.50818 10.9705V15.2088H6.54204V11.2655C6.54204 11.1022 6.67371 10.9705 6.83702 10.9705H8.50818ZM10.0162 15.1997H11.9824V7.09467C11.9824 6.92272 11.8512 6.79965 11.6965 6.79965H10.3032C10.1399 6.79965 10.0082 6.93135 10.0082 7.09467V10.2205H10.0162V15.1997ZM13.4887 12.5288V15.1997H15.4628V12.8315C15.4578 12.6546 15.3143 12.5288 15.1678 12.5288H13.4887Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4503_7263">
        <rect width="22" height="22" fill="white" />
      </clipPath>
      <clipPath id="clip1_4503_7263">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Forecast;
