import { useStore } from "../../store";
import { OutputsBox } from "../utils/RightBarBox";
import { AssignmentsBoxLoan } from "../utils/RightBarBoxSpecial";
import { nanoid } from "nanoid";

import { colors, descriptions, svgs } from "../../components/Panel/constants";
import { lightenColor } from "../../components/Panel/colorManipulation";
import { DisplayIcon } from "./1ContMarketingDetail";
import { DisplaySection, styleOverall, styleDescription } from "./1ContMarketingDetail";

const selector = (id, data) => (store) => ({
  setGeneral: (key, value) => {
    store.updateNode(id, { general: { ...data.general, [key]: value } });
  },
  setAssigns: (index, key, value) => {
    const updated = [...data.assignments];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    if (key === "start") {
      updated[index] = {
        ...updated[index],
        custom: { ...updated[index].custom, [key]: value },
      };
      store.updateNode(id, { assignments: updated });
      return;
    }

    updated[index] = { ...updated[index], [key]: value };
    store.updateNode(id, { assignments: updated });
  },
  addAssign: () => {
    store.updateNode(id, {
      assignments: [
        ...data.assignments,
        {
          id: nanoid(),
          description: "",
          amount: 0,
          eir: 0,
          repayment: 0,
          custom: {
            start: "",
          },
        },
      ],
    });
  },
  deleteAssign: (index) => {
    const updated = [...data.assignments];
    updated.splice(index, 1);
    store.updateNode(id, { assignments: updated });
  },
});

const ContLoanDetail = ({ node, assumption }) => {
  const { id, data, type } = node;
  const {
    setGeneral, //
    setAssigns,
    addAssign,
    deleteAssign,
  } = useStore(selector(id, data));

  const iconColor = colors[type];
  const bgColor = lightenColor(iconColor, 80);
  const Icon = svgs[type];

  return (
    <div className={styleOverall}>
      <div>
        <DisplayIcon description={descriptions[type]} Icon={Icon} iconColor={iconColor} bgColor={bgColor} />
      </div>

      <div>
        <DisplaySection description="General" />
        <div className={styleDescription}>
          <span className="font-medium">Description</span>
          <input
            type="text"
            name="text"
            className="h-full w-60 px-2 border-b"
            maxLength={20}
            value={data.general.description}
            onChange={(e) => setGeneral("description", e.target.value)}
          />
        </div>
      </div>

      <div>
        <DisplaySection description="Inputs" />
        <AssignmentsBoxLoan
          assignments={data.assignments}
          setAssigns={setAssigns}
          addAssign={addAssign}
          deleteAssign={deleteAssign}
          assumptions={assumption}
          individuals={data.individuals}
        />
      </div>

      <div>
        <DisplaySection description="Outputs" />
        <OutputsBox outputs={data.outputs} assumptions={assumption} />
      </div>
    </div>
  );
};

export default ContLoanDetail;
