import { useState, memo } from "react";
import { useStore } from "../../store";
import { selector } from "../../constants";
import { Panel } from "@xyflow/react";
import { colors, descriptions, adds, svgs } from "./constants";
import { lightenColor } from "./colorManipulation";

const ViewPanel = ({ contextId, onDragStart }) => {
  const [open, setOpen] = useState(true);
  const store = useStore(selector(contextId));

  const togglePanel = () => {
    setOpen((prev) => !prev);
  };

  const Button = ({ type }) => {
    const description = descriptions[type];
    const add = adds[type];
    const iconColor = colors[type];
    const bgColor = lightenColor(iconColor, 80);
    const Icon = svgs[type];

    const renderDescription = (desc) => {
      return desc.split("\n").map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ));
    };

    return (
      <button
        className="flex flex-col items-center justify-center w-32 h-24 border border-gray-200 rounded-xl"
        onClick={() => store[add]()}
        onDragStart={(event) => onDragStart(event, add)}
        draggable
      >
        <div
          className="w-10 h-10 p-2 rounded-full"
          style={{
            color: iconColor,
            backgroundColor: bgColor,
          }}
        >
          <Icon />
        </div>
        <span className="text-sm font-medium">{renderDescription(description)}</span>
      </button>
    );
  };

  return (
    <Panel className="h-full">
      <button
        //
        className={`mb-2 p-3 rounded-full transition-all duration-300
          ${!open ? "bg-secondary text-blue-100 w-14 h-14" : "bg-blue-100 text-secondary w-12 h-12"}`}
        onClick={togglePanel}
      >
        {!open ? (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25zm0 9.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18zM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25zm0 9.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18z"
            />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5"
              d="M11.5 18s-6-4.419-6-6s6-6 6-6m7 12s-6-4.419-6-6s6-6 6-6"
              color="currentColor"
            />
          </svg>
        )}
      </button>

      {open && (
        <div
          className={`grid grid-cols-2 gap-4 p-6 shadow-xl bg-white
            rounded-2xl max-h-[85%] overflow-y-auto
            border border-gray-200
            `}
        >
          <Button type="contBlank" />
          <Button type="contMarketing" />
          <Button type="contSales" />
          <Button type="contRevenue" />
          <Button type="contStaff" />
          <Button type="contDirectCosts" />
          <Button type="contIndirectCosts" />
          <Button type="contExpenses" />
          <Button type="contCapex" />
          <Button type="contLoan" />
          <Button type="contReceivables" />
          <Button type="contPayables" />
          <Button type="contShareCap" />
          <Button type="contRetained" />
          <Button type="contSofp" />
          <Button type="contProfit" />
          <Button type="contCash" />
        </div>
      )}
    </Panel>
  );
};

export default memo(ViewPanel);
