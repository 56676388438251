import { style } from "./1ContNormalNode";
import { Handle } from "@xyflow/react";
import { formatValue } from "../utils/Format";

export const InputNode = ({ name, value, handleId, iconColor }) => {
  const leftStyle = {
    top: "50%",
    left: "-1rem",
    width: "1rem",
    height: "1rem",
    backgroundColor: iconColor,
  };

  return (
    <div className="relative flex flex-col">
      <Handle id={handleId} type="target" position="left" style={leftStyle} />
      <span className="text-center">
        {name} : {formatValue(value)}
      </span>
    </div>
  );
};

export const OutputNode = ({ name, value, handleId, iconColor }) => {
  const rightStyle = {
    top: "50%",
    right: "-1rem",
    width: "1rem",
    height: "1rem",
    backgroundColor: iconColor,
  };

  return (
    <div className="relative flex flex-col">
      <span className="text-center">
        {name}: {formatValue(value)}
      </span>
      <Handle id={handleId} type="source" position="right" style={rightStyle} />
    </div>
  );
};

export const DisplayOutputs = ({ outputs, bgColor, iconColor }) => {
  const outputsNone = outputs.filter((output) => output.impact === "");
  const outputsSOFP = outputs.filter((output) => output.impact === "SOFP");
  const outputsSOPL = outputs.filter((output) => output.impact === "SOPL");
  const outputsSOCF = outputs.filter((output) => output.impact === "SOCF");

  return (
    <>
      <h3 className={style.h3}>Outputs</h3>
      {outputsNone.length > 0 && (
        <>
          <div className={style.innerBox} style={{ backgroundColor: bgColor }}>
            {outputsNone.map((item) => (
              <OutputNode
                key={item.id}
                name={item.description}
                value={item.long[0]}
                handleId={item.id}
                iconColor={iconColor}
              />
            ))}
          </div>
        </>
      )}
      {outputsSOFP.length > 0 && (
        <>
          <h4 className={style.h4}>SOFP</h4>
          <div className={style.innerBox} style={{ backgroundColor: bgColor }}>
            {outputsSOFP.map((item) => (
              <OutputNode
                key={item.id}
                name={item.description}
                value={item.long[0]}
                handleId={item.id}
                iconColor={iconColor}
              />
            ))}
          </div>
        </>
      )}
      {outputsSOPL.length > 0 && (
        <>
          <h4 className={style.h4}>SOPL</h4>
          <div className={style.innerBox} style={{ backgroundColor: bgColor }}>
            {outputsSOPL.map((item) => (
              <OutputNode
                key={item.id}
                name={item.description}
                value={item.long[0]}
                handleId={item.id}
                iconColor={iconColor}
              />
            ))}
          </div>
        </>
      )}
      {outputsSOCF.length > 0 && (
        <>
          <h4 className={style.h4}>SOCF</h4>
          <div className={style.innerBox} style={{ backgroundColor: bgColor }}>
            {outputsSOCF.map((item) => (
              <OutputNode
                key={item.id}
                name={item.description}
                value={item.long[0]}
                handleId={item.id}
                iconColor={iconColor}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};
