import { create } from "zustand";

import { useStore } from "../pages/flow/store";

export const useActualStore = create((set, get) => ({
  actual: [],

  logout() {
    set({ actual: [] });
  },

  updateActualDetail(data) {
    set({ actual: data });

    const updateAll = useStore.getState().updateAll;
    updateAll();
  },
}));
