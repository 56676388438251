import { useState } from "react";
import { ButtonAdd, ButtonEdit, ButtonDelete, ButtonView, ButtonDrop } from "./Button";
import { Table } from "./Table";
import { ModalEditCapex, ModalEditStaff } from "./ModalEdit";
import { formatValue } from "./Format";
import { InputNumberBlank } from "./InputNumber";
import { InputStringBlank } from "./InputStringBlank";
import { MonthPickerBasic } from "./MonthPicker";

export const AssignmentsBoxLoan = ({ assignments, setAssigns, addAssign, deleteAssign, assumptions, individuals }) => {
  const [viewedIndex, setViewedIndex] = useState(null);
  const toggleViewButton = (index) => {
    setViewedIndex(viewedIndex === index ? null : index);
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              <th>Utils</th>
              <th>Description</th>
              <th>Principal Amount</th>
              <th>Annual EIR (%)</th>
              <th>Monthly Repayment</th>
              <th>Start</th>
            </tr>
          </thead>
          <tbody>
            {assignments.map((item, index) => (
              <tr key={index}>
                <td>
                  <ButtonDelete onClick={() => deleteAssign(index)} />
                  <ButtonView onClick={() => toggleViewButton(index)} visibility={viewedIndex === index} />
                </td>
                <td>
                  <InputStringBlank
                    value={item.description}
                    onChange={(e) => setAssigns(index, "description", e.target.value)}
                  />
                </td>
                <td>
                  <InputNumberBlank
                    value={item.amount}
                    onValueChange={(e) => setAssigns(index, "amount", parseFloat(e.value))}
                  />
                </td>
                <td>
                  <InputNumberBlank
                    value={item.eir}
                    onValueChange={(e) => setAssigns(index, "eir", parseFloat(e.value))}
                  />
                </td>
                <td>
                  <InputNumberBlank
                    value={item.repayment}
                    onValueChange={(e) => setAssigns(index, "repayment", parseFloat(e.value))}
                  />
                </td>
                <td>
                  <MonthPickerBasic item={item} index={index} setOutputs={setAssigns} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {viewedIndex !== null && <Table items={individuals[viewedIndex].outputs} assumptions={assumptions} />}
      </div>
      <ButtonAdd onClick={addAssign} description="Add Custom Input" />
    </>
  );
};

export const AssignmentsBoxCapex = ({
  assignments,
  setAssigns,
  setAssignsArray,
  resetAssignsArray,
  addAssign,
  deleteAssign,
  assumptions,
  individuals,
}) => {
  const [viewedIndex, setViewedIndex] = useState(null);
  const toggleViewButton = (index) => {
    setViewedIndex(viewedIndex === index ? null : index);
  };

  const [modalState, setModalState] = useState(null);
  const handleOpenModal = (index) => {
    setModalState(index);
  };
  const handleCloseModal = () => {
    setModalState(null);
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              <th>Utils</th>
              <th>Description</th>
              <th>Type</th>
              <th>Quantity</th>
              <th>Value</th>
              <th>Useful life (months)</th>
              <th>Residual value</th>
              <th>Start</th>
            </tr>
          </thead>
          <tbody>
            {assignments.map((item, index) => (
              <tr key={index}>
                <td>
                  <ButtonDelete onClick={() => deleteAssign(index)} />
                  <ButtonView onClick={() => toggleViewButton(index)} visibility={viewedIndex === index} />
                </td>
                <td>
                  <InputStringBlank
                    value={item.description}
                    onChange={(e) => setAssigns(index, "description", e.target.value)}
                  />
                </td>
                <td>
                  <select
                    className="h-8 w-28 px-2 text-gray-500 bg-white border border-gray-300 rounded-md"
                    name="select"
                    value={item.type}
                    onChange={(e) => setAssigns(index, "type", e.target.value)}
                  >
                    <option value="Single">Single</option>
                    <option value="Multiple">Multiple</option>
                  </select>
                </td>
                <td>
                  {item.type === "Single" && (
                    <InputNumberBlank
                      value={item.quantitySingle}
                      onValueChange={(e) => setAssigns(index, "quantitySingle", parseInt(e.value))}
                    />
                  )}
                  {item.type === "Multiple" && (
                    <div className="flex items-center">
                      <InputNumberBlank value={item.quantityMultiple[0]} disabled />
                      <ButtonEdit //
                        onClick={() => handleOpenModal(index)}
                        disabled={item.linkCustom === "Link"}
                      />
                      {modalState === index && (
                        <ModalEditCapex
                          open={true}
                          handleClose={handleCloseModal}
                          item={item}
                          index={index}
                          setCustomLong={setAssignsArray}
                          resetCustomLong={resetAssignsArray}
                          assumptions={assumptions}
                        />
                      )}
                    </div>
                  )}
                </td>
                <td>
                  <InputNumberBlank
                    value={item.value}
                    onValueChange={(e) => setAssigns(index, "value", parseInt(e.value))}
                  />
                </td>
                <td>
                  <InputNumberBlank
                    value={item.usefulLife}
                    onValueChange={(e) => setAssigns(index, "usefulLife", parseInt(e.value))}
                  />
                </td>
                <td>
                  <InputNumberBlank
                    value={item.residualValue}
                    onValueChange={(e) => setAssigns(index, "residualValue", parseInt(e.value))}
                  />
                </td>
                <td>
                  {item.type === "Single" && <MonthPickerBasic item={item} index={index} setOutputs={setAssigns} />}
                  {item.type === "Multiple" && <MonthPickerBasic item={item} disabled />}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {viewedIndex !== null && <Table items={individuals[viewedIndex].outputs} assumptions={assumptions} />}
      </div>
      <ButtonAdd onClick={addAssign} description="Add Custom Input" />
    </>
  );
};

export const AssignmentsBoxStaff = ({
  assignments,
  setAssigns,
  setAssignsParam,
  setCustomLong,
  resetCustomLong,
  addAssign,
  deleteAssign,
  assumptions,
  individuals,
}) => {
  const { monthsNamesBasic, monthsArrayBasic } = assumptions;

  const [viewedIndex, setViewedIndex] = useState(null);
  const toggleViewButton = (index) => {
    setViewedIndex(viewedIndex === index ? null : index);
  };

  const [modalState, setModalState] = useState(null);
  const handleOpenModal = (index, paramIndex) => {
    setModalState({ index, paramIndex });
  };
  const handleCloseModal = () => {
    setModalState(null);
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              <th>Utils</th>
              <th>Description</th>
              <th>Number of Staff</th>
              <th>Salary</th>
              <th>Burden Rate (%)</th>
              <th>Start</th>
            </tr>
          </thead>
          <tbody>
            {assignments.map((item, index) => (
              <tr key={index}>
                <td>
                  <ButtonDelete onClick={() => deleteAssign(index)} />
                  <ButtonView onClick={() => toggleViewButton(index)} visibility={viewedIndex === index} />
                </td>
                <td>
                  <InputStringBlank
                    value={item.description}
                    onChange={(e) => setAssigns(index, "description", e.target.value)}
                  />
                </td>
                {item.params.map((param, paramIndex) => (
                  <td key={paramIndex}>
                    <div className="flex items-center">
                      <InputNumberBlank
                        value={param.custom.value}
                        onValueChange={(e) => setAssignsParam(index, paramIndex, "value", parseInt(e.value))}
                        disabled={param.linkCustom === "Link"}
                      />
                      <ButtonEdit //
                        onClick={() => handleOpenModal(index, paramIndex)}
                        disabled={param.linkCustom === "Link"}
                      />
                      {modalState && modalState.index === index && modalState.paramIndex === paramIndex && (
                        <ModalEditStaff
                          open={true}
                          handleClose={handleCloseModal}
                          index={index}
                          param={param}
                          paramIndex={paramIndex}
                          setOutputs={setAssignsParam}
                          setCustomLong={setCustomLong}
                          resetCustomLong={resetCustomLong}
                          assumptions={assumptions}
                        />
                      )}
                    </div>
                  </td>
                ))}
                <td>
                  <MonthPickerBasic item={item} index={index} setOutputs={setAssigns} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {viewedIndex !== null && (
          <table className="table">
            <thead>
              <tr>
                <th>Month</th>
                {monthsNamesBasic.map((month, index) => (
                  <th key={index}>{month}</th>
                ))}
              </tr>
              <tr>
                <th>Month</th>
                {monthsArrayBasic.map((month, index) => (
                  <th key={index}>{month}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {assignments[viewedIndex].params.map((param, index) => (
                <tr key={index}>
                  <td>{param.description}</td>
                  {param.long.map((value, index) => (
                    <td key={index}>{formatValue(value)}</td>
                  ))}
                </tr>
              ))}
              <tr>
                <td>Staff costs</td>
                {individuals[viewedIndex].long.map((value, index) => (
                  <td key={index}>{formatValue(value)}</td>
                ))}
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <ButtonAdd onClick={addAssign} description="Add Custom Input" />
    </>
  );
};

export const BreakdownBox = ({ assignments, assumptions, individuals }) => {
  const [dropDowns, setDropDowns] = useState([]);
  const toggleDropDown = (description) => {
    setDropDowns((prevDropDowns) =>
      prevDropDowns.includes(description)
        ? prevDropDowns.filter((item) => item !== description)
        : [...prevDropDowns, description]
    );
  };

  return (
    <>
      <div className="flex items-center">
        <ButtonDrop onClick={() => toggleDropDown("numberOfStaff")} visibility={dropDowns.includes("numberOfStaff")} />
        <h1>Number of Staff</h1>
      </div>
      {dropDowns.includes("numberOfStaff") && (
        <BreakdownBoxTable index={0} assignments={assignments} assumptions={assumptions} />
      )}
      <div className="flex items-center">
        <ButtonDrop onClick={() => toggleDropDown("salary")} visibility={dropDowns.includes("salary")} />
        <h1>Salary</h1>
      </div>
      {dropDowns.includes("salary") && (
        <BreakdownBoxTable index={1} assignments={assignments} assumptions={assumptions} />
      )}
      <div className="flex items-center">
        <ButtonDrop onClick={() => toggleDropDown("burdenRate")} visibility={dropDowns.includes("burdenRate")} />
        <h1>Burden Rate (%)</h1>
      </div>
      {dropDowns.includes("burdenRate") && (
        <BreakdownBoxTable index={2} assignments={assignments} assumptions={assumptions} />
      )}
      <div className="flex items-center">
        <ButtonDrop onClick={() => toggleDropDown("staffCosts")} visibility={dropDowns.includes("staffCosts")} />
        <h1>Staff costs</h1>
      </div>
      {dropDowns.includes("staffCosts") && <Table items={individuals} assumptions={assumptions} />}
    </>
  );
};

const BreakdownBoxTable = ({ index, assignments, assumptions }) => {
  const { monthsNamesBasic, monthsArrayBasic } = assumptions;

  return (
    <div className="overflow-x-auto">
      <table className="table">
        <thead>
          <tr>
            <th>Month</th>
            {monthsNamesBasic.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
          <tr>
            <th>Month</th>
            {monthsArrayBasic.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {assignments.map((assignment, idx) => (
            <tr key={idx}>
              <td>{assignment.description}</td>
              {assignment.params[index].long.map((value, idx) => (
                <td key={idx}>{formatValue(value)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
