import { useEffect } from "react";

const Redirect = () => {
  useEffect(() => {
    const query = new URLSearchParams({
      client_id: process.env.REACT_APP_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      response_type: "code",
      scope: "",
    }).toString();

    // GET /oauth/authorize
    window.location.href = `https://hub.simplyfi.co/oauth/authorize?${query}`;
  }, []);

  return <div>Redirecting to Simplyfi Hub...</div>;
};

export default Redirect;
