import { useState } from "react";
import EditView from "./0components/EditView";
import NoContext from "../../components/noSubscription/NoContext";
import NoContextSelected from "../../components/noSubscription/NoContextSelected";

import { useGeneralStore } from "../../stores/useGeneralStore";
import { useStore } from "../flow/store";
import { useAssumptionsStore } from "../../stores/useAssumptionsStore";
import { useStatementsStore } from "../../stores/useStatementsStore";
import { usePriorYearsStore } from "../../stores/usePriorYearsStore";
import { useStatementsAnnualStore } from "../../stores/useStatementsAnnualStore";
import { useActualStore } from "../../stores/useActualStore";
import { useDifferencesStore } from "../../stores/useDifferencesStore";
import { useValuationStore } from "../../stores/useValuationStore";

import VarianceSOFP from "./1variance/SOFP";
import VarianceSOPL from "./1variance/SOPL";
import VarianceSOCF from "./1variance/SOCF";
import ProjectionSOFP from "./2projection/SOFP";
import ProjectionSOPL from "./2projection/SOPL";
import ProjectionSOCF from "./2projection/SOCF";
import ActualSOFP from "./3actual/SOFP";
import ActualSOPL from "./3actual/SOPL";
import ActualSOCF from "./3actual/SOCF";
import PastDataSOFP from "./4pastdata/SOFP";
import PastDataSOPL from "./4pastdata/SOPL";
import PastDataSOCF from "./4pastdata/SOCF";
import Valuation from "./5valuation/Valuation";

const Route = () => {
  const [editView, setEditView] = useState("edit");

  const { reportType, statementType, monthIndex, monthly } = useGeneralStore();
  const contexts = useStore((state) => state.contexts);
  const contextId = useStore((state) => state.contextId);
  const assumptions = useAssumptionsStore((state) => state.assumptions);
  const statements = useStatementsStore((state) => state.statements);
  const priorYears = usePriorYearsStore((state) => state.priorYears);
  const statementsAnnual = useStatementsAnnualStore((state) => state.statementsAnnual);
  const actuals = useActualStore((state) => state.actual);
  const differencess = useDifferencesStore((state) => state.differences);
  const valuations = useValuationStore((state) => state.valuation);

  const context = contexts.find((item) => item.id === contextId);
  const assumption = assumptions.find((item) => item.contextId === contextId);
  const statement = statements.find((item) => item.contextId === contextId);
  const priorYear = priorYears.find((item) => item.contextId === contextId);
  const annual = statementsAnnual.find((item) => item.contextId === contextId);
  const actual = actuals.find((item) => item.contextId === contextId);
  const differences = differencess.find((item) => item.contextId === contextId);
  const valuation = valuations.find((item) => item.contextId === contextId);

  if (contexts.length === 0) {
    return <NoContext />;
  }
  if (!contextId) {
    return <NoContextSelected />;
  }

  const Header = () => {
    return (
      <div className={`${reportType === "Variance" ? "h-16" : "h-12"} shrink-0 flex justify-between`}>
        <div>
          <h1 className="text-secondary text-lg font-semibold">
            {context.data.name} - {statementMap[statementType]} ({reportMap[reportType]})
          </h1>
          {reportType === "Variance" && (
            <h2 className="text-sm font-semibold">{getFullMonthName(assumption.monthsNamesBasic[monthIndex])}</h2>
          )}
        </div>
        <div className="flex gap-4">
          {reportType !== "Projection" && <EditView editView={editView} setEditView={setEditView} />}
          <button className="h-10 text-white bg-red-500">Download (TODO)</button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-grow overflow-y-auto p-4 bg-white rounded-3xl">
      <div //
        style={{ height: "calc(100vh - 18rem)" }}
        className="w-full overflow-auto"
      >
        {reportType === "Variance" && (
          <>
            <Header />
            {statementType === "SOFP" && (
              <VarianceSOFP
                contextId={contextId}
                monthIndex={monthIndex}
                differences={differences}
                editView={editView}
              />
            )}
            {statementType === "SOPL" && (
              <VarianceSOPL
                contextId={contextId}
                monthIndex={monthIndex}
                differences={differences}
                editView={editView}
              />
            )}
            {statementType === "SOCF" && (
              <VarianceSOCF
                contextId={contextId}
                monthIndex={monthIndex}
                differences={differences}
                editView={editView}
              />
            )}
          </>
        )}
        {reportType === "Projection" && (
          <>
            <Header />
            {statementType === "SOFP" && (
              <ProjectionSOFP monthly={monthly} assumption={assumption} statement={statement} annual={annual} />
            )}
            {statementType === "SOPL" && (
              <ProjectionSOPL monthly={monthly} assumption={assumption} statement={statement} annual={annual} />
            )}
            {statementType === "SOCF" && (
              <ProjectionSOCF monthly={monthly} assumption={assumption} statement={statement} annual={annual} />
            )}
          </>
        )}
        {reportType === "Actual" && (
          <>
            <Header />
            {statementType === "SOFP" && (
              <ActualSOFP contextId={contextId} assumption={assumption} actual={actual} editView={editView} />
            )}
            {statementType === "SOPL" && (
              <ActualSOPL contextId={contextId} assumption={assumption} actual={actual} editView={editView} />
            )}
            {statementType === "SOCF" && (
              <ActualSOCF contextId={contextId} assumption={assumption} actual={actual} editView={editView} />
            )}
          </>
        )}
        {reportType === "PastData" && (
          <>
            <Header />
            {statementType === "SOFP" && (
              <PastDataSOFP contextId={contextId} assumption={assumption} priorYear={priorYear} editView={editView} />
            )}
            {statementType === "SOPL" && (
              <PastDataSOPL contextId={contextId} assumption={assumption} priorYear={priorYear} editView={editView} />
            )}
            {statementType === "SOCF" && (
              <PastDataSOCF contextId={contextId} assumption={assumption} priorYear={priorYear} editView={editView} />
            )}
          </>
        )}
        {reportType === "Valuation" && (
          <>
            <Header />
            <Valuation contextId={contextId} assumption={assumption} valuation={valuation} editView={editView} />
          </>
        )}
      </div>
    </div>
  );
};

export default Route;

const statementMap = {
  SOFP: "Statement of Financial Position",
  SOPL: "Statement of Profit or Loss",
  SOCF: "Statement of Cash Flow",
};

const reportMap = {
  Variance: "Variance",
  Projection: "Projection",
  Actual: "Actual",
  PastData: "Past Data",
  Valuation: "Valuation",
};

const getFullMonthName = (monthWithYear) => {
  const monthMapping = {
    Jan: "January",
    Feb: "February",
    Mar: "March",
    Apr: "April",
    May: "May",
    Jun: "June",
    Jul: "July",
    Aug: "August",
    Sep: "September",
    Oct: "October",
    Nov: "November",
    Dec: "December",
  };

  const [abbrevMonth, year] = monthWithYear.split(" ");
  const fullMonth = monthMapping[abbrevMonth] || abbrevMonth;

  return `${fullMonth} 20${year}`;
};
