import { InputNumber } from "primereact/inputnumber";

const numberStyle = "h-8 w-24 px-2 text-gray-500 bg-white border border-gray-300 rounded-md";

export const InputNumberBlank = ({ value, onValueChange, disabled }) => {
  return (
    <InputNumber
      inputClassName={numberStyle}
      name="InputNumberBlank"
      value={value}
      onValueChange={onValueChange}
      disabled={disabled}
      minFractionDigits={0}
      maxFractionDigits={2}
    />
  );
};

export const InputNumberTable = ({ value, onValueChange, red, disabled }) => {
  return (
    <InputNumber
      inputClassName={`${numberStyle} ${red && "text-red-500"}`}
      name="InputNumberTable"
      value={value}
      onValueChange={onValueChange}
      disabled={disabled}
      minFractionDigits={0}
      maxFractionDigits={2}
    />
  );
};
