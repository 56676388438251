import Chart from "react-apexcharts";

const ChartRevenue = ({ monthIndex, yaxisData, showLabel }) => {
  const colors = ["#845ef7", "#4CAF50", "#ff9800"];

  const revenueLongs = yaxisData.sopl.find((item) => item.description === "revenueLongs").long;
  const yaxis = { revenueLongs };

  if (yaxis.revenueLongs.length === 0) {
    return <p className="text-red-500">Reminder to link some Revenue containers to SOPL's Revenue!</p>;
  }

  const labels = yaxis.revenueLongs.map((item) => item.containerDesc);

  const options = {
    chart: {},
    colors: colors,
    labels: labels,
    dataLabels: {
      enabled: showLabel,
    },
    legend: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: (value) => `${value.toFixed(2)}%`,
      },
    },
  };

  const itemsA = yaxis.revenueLongs.map((item) => item.actual[monthIndex]);
  const revA = yaxisData.sopl.find((item) => item.description === "totalRevenueLong").actual[monthIndex];
  const seriesA = itemsA.map((value) => (value / revA) * 100);

  const itemsS = yaxis.revenueLongs.map((item) => item.statements[monthIndex]);
  const revS = yaxisData.sopl.find((item) => item.description === "totalRevenueLong").statements[monthIndex];
  const seriesS = itemsS.map((value) => (value / revS) * 100);

  return (
    <div className="flex">
      <div className="flex-1">
        <p className="text-secondary font-medium">Actual</p>
        <Chart height={180} options={options} series={seriesA} type="pie" />
      </div>
      <div className="flex-1">
        <p className="text-secondary font-medium">Projection</p>
        <Chart height={180} options={options} series={seriesS} type="pie" />
      </div>
    </div>
  );
};

export default ChartRevenue;
