import { useEffect, useState } from "react";
import { useAuthContext } from "../auth/useAuthContext";
import { useRefreshToken } from "../auth/useRefreshToken";
import { Outlet } from "react-router-dom";

import Loading from "../components/noSubscription/Loading";
import Login from "./Login";
import Signup from "./Signup";

const PersistLogin = () => {
  const { user } = useAuthContext();
  const refresh = useRefreshToken();

  const [isLoading, setIsLoading] = useState(true);
  const [loginPage, setLoginPage] = useState(true);

  useEffect(() => {
    const verifyRefreshToken = async () => {
      await refresh();
      setIsLoading(false);
    };

    if (user) {
      setIsLoading(false);
    } else {
      verifyRefreshToken();
    }
  }, [user, refresh]);

  if (isLoading) {
    return <Loading />;
  }

  if (!user) {
    return loginPage ? (
      <Login toSignupPage={() => setLoginPage(false)} />
    ) : (
      <Signup toLoginPage={() => setLoginPage(true)} />
    );
  }

  return <Outlet />;
};

export default PersistLogin;
