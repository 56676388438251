import { Link, useLocation } from "react-router-dom";
import { useState } from "react";

import Forecast from "./svg/inner/Forecast";
import Expand from "./svg/outer/Expand";
import Connect from "./svg/outer/1Connect";
import Ask from "./svg/outer/2Ask";
import Learn from "./svg/outer/3Learn";
import Match from "./svg/outer/4Match";
import Solution from "./svg/outer/5Solution";
import Setting from "./svg/outer/6Setting";

const styleExpand = "flex items-center h-12 gap-4";
const styleExpand2 = "flex items-center h-12 gap-4 pl-8";
const styleLink =
  "flex items-center cursor-pointer h-12 rounded-lg pl-20 transition duration-500 hover:bg-primaryLight group";
const styleLinkActive = "bg-primaryLight";
const styleSpan = "font-medium text-gray-500 transition duration-500 group-hover:text-orange-500";
const styleSpanActive = "text-orange-500";

const Sidebar = () => {
  const location = useLocation();
  const getLink = (path) => {
    return location.pathname.startsWith(path) ? `${styleLinkActive}` : "";
  };
  const getLinkSpan = (path) => {
    return location.pathname.startsWith(path) ? `${styleSpanActive}` : "";
  };

  const [expand, setExpand] = useState({
    connect: false,
    ask: false,
    learn: false,
    match: false,
    solution: true,
    setting: false,
    forecast: true,
  });
  const toggleExpand = (section) => {
    setExpand((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div
      style={{
        //
        height: "calc(100vh - var(--navbar)",
        width: "var(--sidebar)",
      }}
      className="bg-gray-50 px-4 py-8 box-border border-r-2 overflow-auto"
    >
      <span className="text-lg font-medium text-gray-700">Simplyfi Hub</span>

      <button className={styleExpand} onClick={() => toggleExpand("connect")}>
        <Expand expand={expand.connect} />
        <Connect />
        <span className={styleSpan}>Connect</span>
      </button>
      <button className={styleExpand} onClick={() => toggleExpand("ask")}>
        <Expand expand={expand.ask} />
        <Ask />
        <span className={styleSpan}>Ask</span>
      </button>
      <button className={styleExpand} onClick={() => toggleExpand("learn")}>
        <Expand expand={expand.learn} />
        <Learn />
        <span className={styleSpan}>Learn</span>
      </button>
      <button className={styleExpand} onClick={() => toggleExpand("match")}>
        <Expand expand={expand.match} />
        <Match />
        <span className={styleSpan}>Match</span>
      </button>

      <button className={styleExpand} onClick={() => toggleExpand("solution")}>
        <Expand expand={expand.solution} />
        <Solution />
        <span className={styleSpan}>Solution</span>
      </button>
      {expand.solution && (
        <>
          <button className={styleExpand2} onClick={() => toggleExpand("forecast")}>
            <Expand expand={expand.forecast} />
            <Forecast />
            <span className={styleSpan}>Forecast</span>
          </button>
          {expand.forecast && (
            <>
              <Link to="/dashboard" className={`${styleLink} ${getLink("/dashboard")}`}>
                <span className={`${styleSpan} ${getLinkSpan("/dashboard")}`}>Dashboard</span>
              </Link>
              <Link to="/directory" className={`${styleLink} ${getLink("/directory")}`}>
                <span className={`${styleSpan} ${getLinkSpan("/directory")}`}>Directory</span>
              </Link>
              <Link to="/modeling" className={`${styleLink} ${getLink("/modeling")}`}>
                <span className={`${styleSpan} ${getLinkSpan("/modeling")}`}>Modeling</span>
              </Link>
              <Link to="/report" className={`${styleLink} ${getLink("/report")}`}>
                <span className={`${styleSpan} ${getLinkSpan("/report")}`}>Report</span>
              </Link>
              {/* <Link to="/subscription" className={`${styleLink} ${getLink("/subscription")}`}>
                <span className={`${styleSpan} ${getLinkSpan("/subscription")}`}>Subscription</span>
              </Link> */}
            </>
          )}
        </>
      )}

      <button className={styleExpand} onClick={() => toggleExpand("setting")}>
        <Expand expand={expand.setting} />
        <Setting />
        <span className={styleSpan}>Setting</span>
      </button>
    </div>
  );
};

export default Sidebar;
