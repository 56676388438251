import { useStore } from "../store";
import { useAssumptionsStore } from "../../../stores/useAssumptionsStore";
import { useStatementsStore } from "../../../stores/useStatementsStore";
import { useStatementsAnnualStore } from "../../../stores/useStatementsAnnualStore";

export const updateStatementsAnnual = () => {
  const contexts = useStore.getState().contexts;
  const assumptions = useAssumptionsStore.getState().assumptions;
  const statements = useStatementsStore.getState().statements;
  const annual = useStatementsAnnualStore.getState().statementsAnnual;

  // remove deleted context
  const contextIds = contexts.map((context) => context.id);
  for (let i = annual.length - 1; i >= 0; i--) {
    if (!contextIds.includes(annual[i].contextId)) {
      annual.splice(i, 1);
    }
  }

  // add new context
  contexts.forEach((context) => {
    const index = annual.findIndex((item) => item.contextId === context.id);
    if (index === -1) {
      annual.push({
        contextId: context.id,
        sofp: [],
        sopl: [],
        socf: [],
      });
    }
  });

  // copy over and calculate
  annual.forEach((_, index) => {
    copyOver("sofp", statements[index], annual[index], assumptions[index]);
    copyOver("sopl", statements[index], annual[index], assumptions[index]);
    copyOver("socf", statements[index], annual[index], assumptions[index]);
  });
};

const copyOver = (section, statement, annual, assumption) => {
  const statementSection = statement[section];

  annual[section] = statementSection.map((layer1, layer1Index) => {
    const statementLayer1 = statementSection[layer1Index];

    return {
      description: layer1.description,
      type: layer1.type,

      ...(layer1.type === "array" && {
        long: calculate(section, statementLayer1.long, assumption),
      }),

      ...(layer1.type === "object" && {
        long: layer1.long.map((layer2, layer2Index) => {
          const statementLayer2 = statementLayer1.long[layer2Index];

          return {
            id: layer2.id,
            type: layer2.type,
            containerDesc: layer2.containerDesc,
            description: layer2.description,
            long: calculate(section, statementLayer2.long, assumption),
          };
        }),
      }),
    };
  });
};

const calculate = (section, long, assumption) => {
  const { startDate, periodEnd, months } = assumption;
  const startMonth = parseInt(startDate.split("-")[1]);

  let result = [];
  let index = 0;
  let remaining = months;

  if (section === "sofp") {
    // forecast - first
    if (remaining > 0) {
      let diff = 0;
      if (periodEnd >= startMonth) {
        diff = periodEnd - startMonth + 1;
      } else {
        diff = periodEnd + 12 - startMonth + 1;
      }

      if (remaining > diff) {
        index += diff - 1;
        result.push(long[index]);
        remaining -= diff;
      } else {
        index += remaining - 1;
        result.push(long[index]);
        remaining -= remaining;
      }
    }

    // forecast - middle
    while (remaining >= 12) {
      index += 12;
      result.push(long[index]);
      remaining -= 12;
    }

    // forecast - last
    if (remaining > 0) {
      index += remaining;
      result.push(long[index]);
      remaining -= remaining;
    }
  }

  if (section === "sopl" || section === "socf") {
    // forecast - first
    if (remaining > 0) {
      let diff = 0;
      if (periodEnd >= startMonth) {
        diff = periodEnd - startMonth + 1;
      } else {
        diff = periodEnd + 12 - startMonth + 1;
      }

      if (remaining > diff) {
        const sum = long.slice(index, index + diff).reduce((acc, val) => acc + val, 0);
        index += diff;
        result.push(sum);
        remaining -= diff;
      } else {
        const sum = long.slice(index, index + remaining).reduce((acc, val) => acc + val, 0);
        index += remaining;
        result.push(sum);
        remaining -= remaining;
      }
    }

    // forecast - middle
    while (remaining >= 12) {
      const sum = long.slice(index, index + 12).reduce((acc, val) => acc + val, 0);
      index += 12;
      result.push(sum);
      remaining -= 12;
    }

    // forecast - last
    if (remaining > 0) {
      const sum = long.slice(index, index + remaining).reduce((acc, val) => acc + val, 0);
      index += remaining;
      result.push(sum);
      remaining -= remaining;
    }
  }

  return result;
};
