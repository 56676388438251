import Chart from "react-apexcharts";

const ChartCash = ({ yaxisData, xaxisLabels, showLabel }) => {
  const colors = ["#845ef7", "#4CAF50", "#ff9800"];

  const currentAssetsLongs = yaxisData.sofp.find((item) => item.description === "currentAssetsLongs").long;
  const cashItem = currentAssetsLongs.find((item) => item.description === "Cash c/f");
  if (!cashItem) {
    return <p className="text-red-500">Reminder to link your Cash container to SOFP's Assets!</p>;
  }
  const cashLong = cashItem.statements;

  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: colors,
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: 0,
              to: 999999999,
              color: "#008000",
            },
            {
              from: -999999999,
              to: 0,
              color: "#FF0000",
            },
          ],
        },
      },
    },
    xaxis: {
      categories: xaxisLabels,
    },
    yaxis: [
      {
        labels: {
          minWidth: 50,
          maxWidth: 50,
          formatter: (value) => formatMoney(value),
        },
      },
    ],
    dataLabels: {
      enabled: showLabel,
      formatter: (value) => formatMoney(value),
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      showForSingleSeries: true,
    },
  };

  const series = [
    {
      name: "Cash",
      data: cashLong,
    },
  ];

  return (
    <>
      <Chart height={200} options={options} series={series} type="bar" />
    </>
  );
};

export default ChartCash;

const formatMoney = (value) => {
  if (!value) return 0;

  const absValue = Math.abs(value);
  const sign = value < 0 ? "-" : "";

  if (absValue >= 1000000) {
    return `${sign}$${(absValue / 1000000).toLocaleString()}M`;
  } else if (absValue >= 1000) {
    return `${sign}$${(absValue / 1000).toLocaleString()}k`;
  } else {
    return `${sign}$${absValue.toLocaleString()}`;
  }
};
