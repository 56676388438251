import { memo } from "react";
import { useStore } from "./store";
import { useAssumptionsStore } from "../../stores/useAssumptionsStore";

import ContMarketingDetail from "./containerNodes/details/1ContMarketingDetail";
import ContNormalDetail from "./containerNodes/details/2ContNormalDetail";
import ContCostsDetail from "./containerNodes/details/3ContCostsDetail";
import ContCapexDetail from "./containerNodes/details/4ContCapexDetail";
import ContLoanDetail from "./containerNodes/details/5ContLoanDetail";
import ContStaffDetail from "./containerNodes/details/6ContStaffDetail";

const componentMap = {
  contBlank: ContNormalDetail,
  contMarketing: ContMarketingDetail,
  contSales: ContNormalDetail,
  contRevenue: ContNormalDetail,
  contStaff: ContStaffDetail,
  contDirectCosts: ContCostsDetail,
  contIndirectCosts: ContCostsDetail,
  contExpenses: ContNormalDetail,
  contCapex: ContCapexDetail,
  contLoan: ContLoanDetail,
  contReceivables: ContNormalDetail,
  contPayables: ContNormalDetail,
  contShareCap: ContNormalDetail,
  contRetained: ContNormalDetail,
  contSofp: ContNormalDetail,
  contProfit: ContNormalDetail,
  contCash: ContNormalDetail,
};

const RightBar = ({ contextId, rightBar }) => {
  const nodes = useStore((state) => state.nodes) //
    .filter((item) => item.contextId === contextId);
  const assumption = useAssumptionsStore((state) => state.assumptions) //
    .find((item) => item.contextId === contextId);

  const selectedNode = nodes.find((node) => node.id === rightBar);
  const show = selectedNode ? true : false;

  let SelectedComponent = null;
  if (selectedNode) {
    SelectedComponent = componentMap[selectedNode.type];
  }

  return (
    <div
      className={`flex absolute w-[50%] h-full overflow-y-auto bg-white shadow-2xl z-10
        ${show ? "right-0" : "right-[-50%]"} transition-all duration-500 ease-in-out`}
    >
      {SelectedComponent && <SelectedComponent node={selectedNode} assumption={assumption} />}
    </div>
  );
};

export default memo(RightBar);
