const LogoPic = () => (
  <svg
    className="h-12 w-auto"
    width="22"
    height="36"
    viewBox="0 0 22 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.04041 27.8126L0 23.8291V32.0165L7.04041 36L21.2708 27.8126V19.6233L6.96658 11.3606V0L0 7.52412V15.6399L13.7855 23.6803L7.04041 27.8126Z"
      fill="#EE6E00"
    />
    <path d="M0 23.8271L2.59539 30.7578L0 32.0164V23.8271Z" fill="#003E7D" />
  </svg>
);

export default LogoPic;
