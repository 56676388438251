import { useStore } from "../../store";
import { AssignmentsBox, FormulasBox, OutputsBox } from "../utils/RightBarBox";

import { colors, descriptions, svgs } from "../../components/Panel/constants";
import { lightenColor } from "../../components/Panel/colorManipulation";
import { DisplayIcon } from "./1ContMarketingDetail";
import { selector, DisplaySection, styleOverall, styleDescription } from "./1ContMarketingDetail";

const ContNormalDetail = ({ node, assumption }) => {
  const { id, data, type } = node;
  const {
    setGeneral,
    // setCore,
    setAssignsCustom,
    setAssigns,
    addAssign,
    deleteAssign,
    setCustomLong,
    resetCustomLong,
    setFormulas,
    addFormula,
    deleteFormula,
    addFormulasParam,
    deleteFormulasParam,
    sortParamsIndex,
  } = useStore(selector(id, data));

  const iconColor = colors[type];
  const bgColor = lightenColor(iconColor, 80);
  const Icon = svgs[type];

  return (
    <div className={styleOverall}>
      <div>
        <DisplayIcon description={descriptions[type]} Icon={Icon} iconColor={iconColor} bgColor={bgColor} />
      </div>

      <div>
        <DisplaySection description="General" />
        <div className={styleDescription}>
          <span className="font-medium">Description</span>
          <input
            type="text"
            name="text"
            className="h-full w-60 px-2 border-b"
            maxLength={20}
            value={data.general.description}
            onChange={(e) => setGeneral("description", e.target.value)}
          />
        </div>
      </div>

      <div>
        <DisplaySection description="Inputs" />
        <AssignmentsBox
          assignments={data.assignments}
          setAssignsCustom={setAssignsCustom}
          setAssigns={setAssigns}
          addAssign={addAssign}
          deleteAssign={deleteAssign}
          setCustomLong={setCustomLong}
          resetCustomLong={resetCustomLong}
          assumptions={assumption}
        />
      </div>

      <div>
        <DisplaySection description="Formulas" />
        <FormulasBox
          formulas={data.formulas}
          setFormulas={setFormulas}
          sortParamsIndex={sortParamsIndex}
          addFormula={addFormula}
          deleteFormula={deleteFormula}
          addFormulasParam={addFormulasParam}
          deleteFormulasParam={deleteFormulasParam}
        />
      </div>

      <div>
        <DisplaySection description="Outputs" />
        <OutputsBox outputs={data.outputs} assumptions={assumption} />
      </div>
    </div>
  );
};

export default ContNormalDetail;
