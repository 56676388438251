import { useStore } from "../store";

export const calculateContCapex = (nodes, node, edges, assumption) => {
  const { startDate, months } = assumption;

  let assignments = [...node.data.assignments];
  // let individuals = [...node.data.individuals];
  let outputs = [...node.data.outputs];

  // ASSIGNMENTS
  const myEdges = edges.filter((edge) => edge.target === node.id);
  // 2. update startDate if empty
  assignments = updateStartDate(assignments, startDate);
  // 3. update quantity
  assignments = updatedSingleMultiple(assignments, months);
  // 5. update linked values
  assignments = updateAssignLinked(assignments, nodes, myEdges, months);

  // INDIVIDUALS
  const individuals = calculateIndividuals(assignments, months, startDate);

  // OUTPUTS
  outputs = updateToZero(outputs, months);
  outputs.forEach((output, index) => {
    individuals.forEach((individual) => {
      for (let i = 0; i < months; i++) {
        output.long[i] += individual.outputs[index].long[i];
      }
    });
  });

  return [assignments, individuals, outputs];
};

const updateStartDate = (assignments, startDate) => {
  assignments.forEach((assignment) => {
    if (assignment.type === "Multiple") {
      assignment.custom.start = startDate;
    }
    if (assignment.custom.start === "") {
      assignment.custom.start = startDate;
    }
  });

  return assignments;
};

const updatedSingleMultiple = (assignments, months) => {
  assignments.forEach((assignment) => {
    if (assignment.quantityMultiple.length < months) {
      const zerosToAdd = months - assignment.quantityMultiple.length;
      assignment.quantityMultiple = Array(zerosToAdd).fill(0).concat(assignment.quantityMultiple);
    }
    if (assignment.type === "Multiple") {
      assignment.quantitySingle = 0;
    }
    assignment.linkCustom = "Custom";
  });

  return assignments;
};

const updateAssignLinked = (assignments, nodes, myEdges, months) => {
  const deleteEdge = (id) => {
    const { onEdgesChange } = useStore.getState();
    onEdgesChange([{ id: id, type: "remove" }]);
  };

  // Create a map to accumulate long arrays for each targetHandle
  const accumulatedLongs = {};

  myEdges.forEach((edge) => {
    const sourceId = edge.source;
    const sourceHandle = edge.sourceHandle;
    const sourceNode = nodes.find((node) => node.id === sourceId);
    const output = sourceNode.data.outputs.find((output) => output.id === sourceHandle);

    const assignmentIndex = assignments.findIndex((assignment) => assignment.id === edge.targetHandle);
    if (output && assignmentIndex !== -1) {
      const targetHandle = edge.targetHandle;
      if (!accumulatedLongs[targetHandle]) {
        accumulatedLongs[targetHandle] = Array(output.long.length).fill(0);
      }
      accumulatedLongs[targetHandle] = accumulatedLongs[targetHandle].map((sum, index) => sum + output.long[index]);
    } else {
      deleteEdge(edge.id);
    }
  });

  // Update assignments with the accumulated long arrays
  myEdges.forEach((edge) => {
    const assignmentIndex = assignments.findIndex((assignment) => assignment.id === edge.targetHandle);

    if (assignmentIndex !== -1) {
      const targetHandle = edge.targetHandle;
      if (assignments[assignmentIndex].type === "Multiple") {
        assignments[assignmentIndex] = {
          ...assignments[assignmentIndex],
          quantityMultiple: accumulatedLongs[targetHandle],
          linkCustom: "Link",
        };
      } else if (assignments[assignmentIndex].type === "Single") {
        assignments[assignmentIndex] = {
          ...assignments[assignmentIndex],
          quantityMultiple: Array(months).fill(0),
          linkCustom: "Custom",
        };
        deleteEdge(edge.id);
      }
    }
  });

  return assignments;
};

const calculateIndividuals = (assignments, months, startDate) => {
  let individuals = [];

  assignments.forEach((assignment) => {
    const { type } = assignment;

    let individual = [];
    if (type === "Single") {
      individual = calculateIndividual(months, assignment, startDate);
    }
    if (type === "Multiple") {
      individual = calculateIndividualMultiple(months, assignment);
    }

    individuals.push(individual);
  });

  return individuals;
};

const calculateIndividual = (months, assignment, startDate) => {
  const { quantitySingle, value, usefulLife, residualValue, custom } = assignment;
  const { start } = custom;
  const diff = calculateMonthDiff(startDate, start);
  const diffWithPrior = diff;

  let cost = Array(months).fill(value);
  let accDep = [];
  let carryingAmt = [];
  let depExp = [];
  let addBackDep = [];
  let purchaseOfCapex = Array(months).fill(0);
  purchaseOfCapex[0] = -value;

  for (let i = 0; i < months - diff; i++) {
    const powerFactor = Math.min((i + 1) / usefulLife, 1);
    const currentAccDep = Math.round((value - residualValue) * Math.pow(powerFactor, 1) * -1);
    accDep.push(currentAccDep);
    carryingAmt.push(value + currentAccDep);

    if (i === 0) {
      const dep = Math.abs(currentAccDep);
      depExp.push(dep);
      addBackDep.push(dep);
    } else {
      const currentDepExp = accDep[i] - accDep[i - 1];
      const dep = Math.abs(currentDepExp);
      depExp.push(dep);
      addBackDep.push(dep);
    }
  }

  // feb
  if (diffWithPrior > 0) {
    cost = adjustArray(cost, diffWithPrior, months);
    accDep = adjustArray(accDep, diffWithPrior, months);
    carryingAmt = adjustArray(carryingAmt, diffWithPrior, months);
    depExp = adjustArray(depExp, diffWithPrior, months);
    addBackDep = adjustArray(addBackDep, diffWithPrior, months);
    purchaseOfCapex = adjustArray(purchaseOfCapex, diffWithPrior, months);
  }
  // dec
  if (diffWithPrior < 0) {
    accDep = adjustArray(accDep, diffWithPrior, months);
    carryingAmt = adjustArray(carryingAmt, diffWithPrior, months);
    depExp = adjustArray(depExp, diffWithPrior, months);
    addBackDep = adjustArray(addBackDep, diffWithPrior, months);
    purchaseOfCapex[0] = 0;
  }

  const individual = {
    id: assignment.id,
    description: assignment.description,
    outputs: [
      { description: "Cost", long: cost.map((num) => num * quantitySingle) },
      { description: "Accumulated Depreciation", long: accDep.map((num) => num * quantitySingle) },
      { description: "CAPEX c/f", long: carryingAmt.map((num) => num * quantitySingle) },
      { description: "Depreciation Expense", long: depExp.map((num) => num * quantitySingle) },
      { description: "Add back depreciation", long: addBackDep.map((num) => num * quantitySingle) },
      { description: "Purchase of CAPEX", long: purchaseOfCapex.map((num) => num * quantitySingle) },
    ],
  };

  return individual;
};

const calculateIndividualMultiple = (months, assignment) => {
  const { quantityMultiple, value, usefulLife, residualValue } = assignment;

  let individual = {
    id: assignment.id,
    description: assignment.description,
    outputs: [
      { description: "Cost", long: Array(months).fill(0) },
      { description: "Accumulated Depreciation", long: Array(months).fill(0) },
      { description: "CAPEX c/f", long: Array(months).fill(0) },
      { description: "Depreciation Expense", long: Array(months).fill(0) },
      { description: "Add back depreciation", long: Array(months).fill(0) },
      { description: "Purchase of CAPEX", long: Array(months).fill(0) },
    ],
  };

  quantityMultiple.forEach((quantity, index) => {
    const diff = index;

    let cost = Array(months).fill(value);
    let accDep = [];
    let carryingAmt = [];
    let depExp = [];
    let addBackDep = [];
    let purchaseOfCapex = Array(months).fill(0);
    purchaseOfCapex[0] = -value;

    for (let i = 0; i < months - diff; i++) {
      const powerFactor = Math.min((i + 1) / usefulLife, 1);
      const currentAccDep = Math.round((value - residualValue) * Math.pow(powerFactor, 1) * -1);
      accDep.push(currentAccDep);
      carryingAmt.push(value + currentAccDep);

      if (i === 0) {
        const dep = Math.abs(currentAccDep);
        depExp.push(dep);
        addBackDep.push(dep);
      } else {
        const currentDepExp = accDep[i] - accDep[i - 1];
        const dep = Math.abs(currentDepExp);
        depExp.push(dep);
        addBackDep.push(dep);
      }
    }

    // feb
    if (diff > 0) {
      cost = adjustArray(cost, diff, months);
      accDep = adjustArray(accDep, diff, months);
      carryingAmt = adjustArray(carryingAmt, diff, months);
      depExp = adjustArray(depExp, diff, months);
      addBackDep = adjustArray(addBackDep, diff, months);
      purchaseOfCapex = adjustArray(purchaseOfCapex, diff, months);
    }
    // dec
    if (diff < 0) {
      accDep = adjustArray(accDep, diff, months);
      carryingAmt = adjustArray(carryingAmt, diff, months);
      depExp = adjustArray(depExp, diff, months);
      addBackDep = adjustArray(addBackDep, diff, months);
      purchaseOfCapex[0] = 0;
    }

    for (let i = 0; i < months; i++) {
      individual.outputs[0].long[i] += cost[i] * quantity;
      individual.outputs[1].long[i] += accDep[i] * quantity;
      individual.outputs[2].long[i] += carryingAmt[i] * quantity;
      individual.outputs[3].long[i] += depExp[i] * quantity;
      individual.outputs[4].long[i] += addBackDep[i] * quantity;
      individual.outputs[5].long[i] += purchaseOfCapex[i] * quantity;
    }
  });

  return individual;
};

const calculateMonthDiff = (start, end) => {
  const [startYear, startMonth] = start.split("-").map(Number);
  const [endYear, endMonth] = end.split("-").map(Number);
  return (endYear - startYear) * 12 + (endMonth - startMonth);
};

const adjustArray = (arr, diff, months) => {
  if (diff > 0) {
    if (diff > months) {
      return Array(months).fill(0);
    } else {
      return Array(diff)
        .fill(0)
        .concat(arr.slice(0, months - diff));
    }
  } else {
    return arr.slice(-diff).concat().slice(0, months);
  }
};

const updateToZero = (outputs, months) => {
  outputs.forEach((output) => (output.long = Array(months).fill(0)));
  return outputs;
};
