import { useAuthContext } from "./useAuthContext";
import { useSnackbarContext } from "../hooks/SnackbarContext";

import axios from "../axios/axios";

export const useRefreshToken = () => {
  const { dispatch } = useAuthContext();
  const { showMessage } = useSnackbarContext();

  const refresh = async () => {
    try {
      const response = await axios.get("/api/user/refreshToken", {
        withCredentials: true,
      });

      const { email, accessToken } = response.data;
      dispatch({ type: "LOGIN", payload: { email, accessToken } });

      return response.data.accessToken;
    } catch (err) {
      console.log("useRefreshToken", err);
      if (err.code === "ERR_NETWORK") {
        showMessage("Unable to connect to the server. Please try again later.", "error");
      }
      if (err.code === "ERR_BAD_REQUEST") {
        if (err.response.data.message === "refresh token expired") {
          dispatch({ type: "LOGOUT" });
        }
      }
    }
  };

  return refresh;
};
