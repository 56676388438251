import Tooltip from "@mui/material/Tooltip";

export const ButtonAdd = ({ onClick, disabled, description }) => {
  return (
    <button //
      className="w-full pt-2 flex items-center justify-center gap-4"
      onClick={onClick}
      disabled={disabled}
    >
      <svg className="w-4 h-4 text-secondary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path fill="currentColor" d="M11 13H5v-2h6V5h2v6h6v2h-6v6h-2z" />
      </svg>
      <span className="font-medium text-secondary">{description}</span>
    </button>
  );
};

export const ButtonEdit = ({ onClick, disabled }) => {
  return (
    <Tooltip title="Edit">
      <span>
        <button
          className={`w-10 h-10 p-2 rounded-full
            ${disabled ? "text-gray-300 cursor-not-allowed" : "text-gray-500 hover:bg-gray-100"}`}
          disabled={disabled}
          onClick={onClick}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M3 21v-4.25L16.2 3.575q.3-.275.663-.425t.762-.15t.775.15t.65.45L20.425 5q.3.275.438.65T21 6.4q0 .4-.137.763t-.438.662L7.25 21zM17.6 7.8L19 6.4L17.6 5l-1.4 1.4z"
            />
          </svg>
        </button>
      </span>
    </Tooltip>
  );
};

export const ButtonDelete = ({ onClick, disabled }) => {
  return (
    <Tooltip title="Delete">
      <span>
        <button
          className={`w-10 h-10 p-2 rounded-full
          ${disabled ? "text-gray-300 cursor-not-allowed" : "text-gray-500 hover:bg-gray-100"}`}
          disabled={disabled}
          onClick={onClick}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zm2-4h2V8H9zm4 0h2V8h-2z"
            />
          </svg>
        </button>
      </span>
    </Tooltip>
  );
};

export const ButtonDrag = () => {
  return (
    <Tooltip title="Drag">
      <button className="w-10 h-10 p-2 rounded-full text-gray-500 hover:bg-gray-100">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2s.9-2 2-2s2 .9 2 2m-2-8c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2m0-6c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2m6 4c1.1 0 2-.9 2-2s-.9-2-2-2s-2 .9-2 2s.9 2 2 2m0 2c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2m0 6c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2"
          />
        </svg>
      </button>
    </Tooltip>
  );
};

export const ButtonView = ({ onClick, visibility }) => {
  return (
    <>
      {visibility ? (
        <Tooltip title="Hide">
          <button className="w-10 h-10 p-2 rounded-full text-gray-500 hover:bg-gray-100" onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M11.83 9L15 12.16V12a3 3 0 0 0-3-3zm-4.3.8l1.55 1.55c-.05.21-.08.42-.08.65a3 3 0 0 0 3 3c.22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53a5 5 0 0 1-5-5c0-.79.2-1.53.53-2.2M2 4.27l2.28 2.28l.45.45C3.08 8.3 1.78 10 1 12c1.73 4.39 6 7.5 11 7.5c1.55 0 3.03-.3 4.38-.84l.43.42L19.73 22L21 20.73L3.27 3M12 7a5 5 0 0 1 5 5c0 .64-.13 1.26-.36 1.82l2.93 2.93c1.5-1.25 2.7-2.89 3.43-4.75c-1.73-4.39-6-7.5-11-7.5c-1.4 0-2.74.25-4 .7l2.17 2.15C10.74 7.13 11.35 7 12 7"
              />
            </svg>
          </button>
        </Tooltip>
      ) : (
        <Tooltip title="View">
          <button className="w-10 h-10 p-2 rounded-full text-gray-500 hover:bg-gray-100" onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
              />
            </svg>
          </button>
        </Tooltip>
      )}
    </>
  );
};

export const ButtonDrop = ({ onClick, visibility }) => {
  return (
    <>
      {visibility ? (
        <Tooltip title="Hide">
          <button className="w-10 h-10 p-2 rounded-full text-gray-500 hover:bg-gray-100" onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path fill="currentColor" d="m7 15l5-5l5 5z" />
            </svg>
          </button>
        </Tooltip>
      ) : (
        <Tooltip title="View">
          <button className="w-10 h-10 p-2 rounded-full text-gray-500 hover:bg-gray-100" onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path fill="currentColor" d="m7 10l5 5l5-5z" />
            </svg>
          </button>
        </Tooltip>
      )}
    </>
  );
};
