import { useState } from "react";
import { useAuthContext } from "./useAuthContext";

import axios from "../axios/axios";

export const useSignup = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useAuthContext();

  const signup = async (email, password) => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        "/api/user/signup",
        { email, password },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      const { accessToken } = response.data;
      dispatch({ type: "LOGIN", payload: { email, accessToken } });

      setError(null);
      setIsLoading(false);
    } catch (err) {
      console.log("useSignup - error", err);

      setError("TODO - useSignup error");
      setIsLoading(false);
    }
  };

  return { signup, isLoading, error };
};
