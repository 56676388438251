import { useEffect, useMemo, useState } from "react";
import { useStore } from "./store";
import { useSaveContext } from "../../saveAndRestore/useSaveContext";
import Panel from "./components/Panel/Panel";
import FlowHandlers from "./FlowHandlers";

import "@xyflow/react/dist/style.css";
import { ReactFlowProvider, ReactFlow, Background, MiniMap } from "@xyflow/react";
import { useNodesState, useEdgesState } from "@xyflow/react";
import { selector, createNodeTypes } from "./constants";

import ButtonEdge from "./edges/ButtonEdge";
import { getColor } from "./components/Panel/colorManipulation";
import { onDragStart, onDragOver, onDrop } from "./utilsFunctions/dragAndDrop";
import RightBar from "./RightBar";

const View = ({ contextId, editView }) => {
  const store = useStore(selector(contextId));
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const { autoSave } = useSaveContext();

  const rightBar = useStore((state) => state.rightBar);
  const setRightBar = useStore.getState().setRightBar;

  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();

  const {
    //
    filteredNodes,
    filteredEdges,
    handleNodesChange,
    handleEdgesChange,
    handleDelete,
    handleConnect,
  } = FlowHandlers({
    contextId,
    onNodesChange,
    onEdgesChange,
    autoSave,
    rightBar,
    setRightBar,
  });

  useEffect(() => {
    setNodes(filteredNodes);
    setEdges(filteredEdges);
  }, [filteredNodes, filteredEdges, setNodes, setEdges]);

  const nodeTypes = useMemo(
    () => createNodeTypes(editView, handleDelete), //
    [editView, handleDelete]
  );

  const edgeTypes = useMemo(
    () => ({
      buttonedge: (props) => <ButtonEdge {...props} editView={editView} handleDelete={handleDelete} />,
    }),
    [editView, handleDelete]
  );

  return (
    <ReactFlowProvider>
      <ReactFlow
        // normal
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={handleNodesChange}
        onEdgesChange={handleEdgesChange}
        onDelete={handleDelete}
        onConnect={handleConnect}
        // dnd
        onInit={setReactFlowInstance}
        onDrop={(event) => onDrop(event, reactFlowInstance, store)}
        onDragOver={onDragOver}
        // ui/ux
        fitView
        deleteKeyCode={editView === "edit" ? ["Backspace", "Delete"] : null}
        nodesDraggable={editView === "edit"}
        nodesConnectable={editView === "edit"}
      >
        {editView === "edit" && <Panel contextId={contextId} onDragStart={onDragStart} />}
        <Background />
        <MiniMap nodeColor={(n) => getColor(n.type, 80)} />
        {editView === "edit" && <RightBar contextId={contextId} rightBar={rightBar} />}
      </ReactFlow>
    </ReactFlowProvider>
  );
};

export default View;
