import { useStore } from "../../pages/flow/store";

const SelectContextId = () => {
  const contextId = useStore((state) => state.contextId);
  const contexts = useStore((state) => state.contexts);
  const setContextId = useStore.getState().setContextId;

  const handleSelect = (contextId) => {
    setContextId(contextId === "" ? null : contextId);
  };

  return (
    <div className="flex h-16">
      {contexts.length === 0 ? (
        <div className="flex flex-col h-full">
          <p className="text-secondary font-semibold text-sm">Company/Project</p>
          <span className="flex items-center min-w-56 h-full px-2 text-gray-700 bg-white border border-gray-300 rounded-xl">
            Create One!
          </span>
        </div>
      ) : (
        <div className="flex flex-col h-full">
          <p className="text-secondary font-semibold text-sm">Company/Project</p>
          <div className="h-full relative">
            <select
              className="min-w-64 h-full px-2 text-gray-700 bg-white border border-gray-300 rounded-xl appearance-none cursor-pointer"
              name="select"
              value={contextId || ""}
              onChange={(e) => handleSelect(e.target.value)}
            >
              <option value="">Select Company/Project</option>
              {contexts.map((context, index) => (
                <option key={index} value={context.id}>
                  {context.data.name}
                </option>
              ))}
            </select>
            <svg
              className="h-5 w-5 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-700"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g fill="none" fillRule="evenodd">
                <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                <path
                  fill="currentColor"
                  d="M13.06 16.06a1.5 1.5 0 0 1-2.12 0l-5.658-5.656a1.5 1.5 0 1 1 2.122-2.121L12 12.879l4.596-4.596a1.5 1.5 0 0 1 2.122 2.12l-5.657 5.658Z"
                />
              </g>
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectContextId;
