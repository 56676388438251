import SelectContextId from "../../../../components/selectStore/SelectContextId";
import SelectMonthIndex from "../../../../components/selectStore/SelectMonthIndex";
import OnOffShowLabel from "../../../../components/selectStore/OnOffShowLabel";
import OnOffShowPastData from "../../../../components/selectStore/OnOffShowPastData";

const Header = () => {
  return (
    <div
      //
      style={{ height: "var(--header)" }}
      className="shrink-0 flex justify-between items-center bg-white border px-4 rounded-2xl"
    >
      <div className="flex items-center gap-4">
        <div className="flex h-6">
          <svg className="text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M15 19.88c.04.3-.06.62-.29.83a.996.996 0 0 1-1.41 0L9.29 16.7a.99.99 0 0 1-.29-.83v-5.12L4.21 4.62a1 1 0 0 1 .17-1.4c.19-.14.4-.22.62-.22h14c.22 0 .43.08.62.22a1 1 0 0 1 .17 1.4L15 10.75zM7.04 5L11 10.06v5.52l2 2v-7.53L16.96 5z"
            />
          </svg>
        </div>
        <SelectContextId />
        <SelectMonthIndex />
        <OnOffShowLabel />
        <OnOffShowPastData />
      </div>
    </div>
  );
};

export default Header;
