import { generateLong } from "./utilsGenerateStaff";
import { useStore } from "../store";

export const calculateContStaff = (nodes, node, edges, assumption) => {
  const { startDate, months } = assumption;

  let assignments = [...node.data.assignments];
  // let individuals = [...node.data.individuals];
  let outputs = [...node.data.outputs];

  // ASSIGNMENTS
  // 0. find edges connecting to us
  const myEdges = edges.filter((edge) => edge.target === node.id);
  // 1. update startDate if empty
  assignments = updateStartDate(assignments, startDate);
  // 2. update linked values
  assignments = updateAssignLinked(assignments, nodes, myEdges);
  // 3. fill zero
  assignments.forEach((assignment) => {
    assignment = fillLong(assignment, months);
  });
  // 4. update custom values
  assignments.forEach((assignment) => {
    assignment = updateAssignCustom(assignment, myEdges, assumption);
  });

  // INDIVIDUALS
  const individuals = calculateIndividuals(assignments, months);

  // OUTPUTS
  outputs = calculateOutputs(outputs, individuals, months);

  return [assignments, individuals, outputs];
};

const updateStartDate = (assignments, startDate) => {
  assignments.forEach((assignment) => {
    if (assignment.custom.start === "") {
      assignment.custom.start = startDate;
    }
  });

  return assignments;
};

const updateAssignLinked = (assignments, nodes, myEdges) => {
  const deleteEdge = (id) => {
    const { onEdgesChange } = useStore.getState();
    onEdgesChange([{ id: id, type: "remove" }]);
  };

  const splitLast = (str) => {
    const lastIndex = str.lastIndexOf("_");
    const targetHandle = str.substring(0, lastIndex);
    const paramIndex = parseInt(str.substring(lastIndex + 1));
    return [targetHandle, paramIndex];
  };

  // Create a map to accumulate long arrays for each targetHandle
  const accumulatedLongs = {};

  myEdges.forEach((edge) => {
    const sourceId = edge.source;
    const sourceHandle = edge.sourceHandle;
    const sourceNode = nodes.find((node) => node.id === sourceId);
    const output = sourceNode.data.outputs.find((output) => output.id === sourceHandle);

    const [targetHandle] = splitLast(edge.targetHandle);
    const assignmentIndex = assignments.findIndex((assignment) => assignment.id === targetHandle);

    if (output && assignmentIndex !== -1) {
      const targetHandle = edge.targetHandle;
      if (!accumulatedLongs[targetHandle]) {
        accumulatedLongs[targetHandle] = Array(output.long.length).fill(0);
      }
      accumulatedLongs[targetHandle] = accumulatedLongs[targetHandle].map((sum, index) => sum + output.long[index]);
    } else {
      deleteEdge(edge.id);
    }
  });

  // Update assignments with the accumulated long arrays
  myEdges.forEach((edge) => {
    const [targetHandle, paramIndex] = splitLast(edge.targetHandle);
    const assignmentIndex = assignments.findIndex((assignment) => assignment.id === targetHandle);

    if (assignmentIndex !== -1) {
      const targetHandle = edge.targetHandle;
      assignments[assignmentIndex].params[paramIndex] = {
        ...assignments[assignmentIndex].params[paramIndex],
        linkCustom: "Link",
        custom: {
          value: 0,
          min: 0,
          max: 0,
          change: 0,
        },
        long: accumulatedLongs[targetHandle],
      };
    }
  });

  return assignments;
};

const fillLong = (assignment, months) => {
  assignment.params.forEach((param) => {
    let { long, longCustom } = param;

    if (!long) long = [];
    long = long.map((value) => (value === undefined ? 0 : value));

    const filledLong =
      long.length >= months ? long.slice(0, months) : [...long, ...Array(months - long.length).fill(0)];

    const filledLongCustom =
      longCustom.length >= months
        ? longCustom.slice(0, months)
        : [...longCustom, ...Array(months - longCustom.length).fill(null)];

    param.long = filledLong;
    param.longCustom = filledLongCustom;
  });

  return assignment;
};

const updateAssignCustom = (assignment, myEdges, assumption) => {
  const linkedAssign = myEdges.map((edge) => edge.targetHandle);

  assignment.params.forEach((param) => {
    if (!linkedAssign.includes(param.id)) {
      param.linkCustom = "Custom";
      const long = generateLong(param, assignment.custom.start, assumption);
      const roundedLong = long.map((element) => Math.round(element));
      param.long = roundedLong;
    }
  });

  return assignment;
};

const calculateIndividuals = (assignments, months) => {
  let individuals = [];

  assignments.forEach((assignment) => {
    const numberOfStaffLong = assignment.params[0].long;
    const salaryLong = assignment.params[1].long;
    const burdenRateLong = assignment.params[2].long;

    const long = [];
    for (let i = 0; i < months; i++) {
      const result = numberOfStaffLong[i] * salaryLong[i] * (1 + burdenRateLong[i] / 100);
      long.push(Math.round(result));
    }

    individuals.push({ description: assignment.description, long: long });
  });

  return individuals;
};

const calculateOutputs = (outputs, individuals, months) => {
  let long = Array(months).fill(0);
  individuals.forEach((individual) => {
    for (let i = 0; i < individual.long.length; i++) {
      long[i] += individual.long[i];
    }
  });

  outputs[0].long = long;
  return outputs;
};
