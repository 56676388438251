import { useStore } from "../store";
import { useAssumptionsStore } from "../../../stores/useAssumptionsStore";

import { topologicalSortNodes } from "../utilsFunctions/topologicalSortNodes";

import { calculateContMarketing } from "../utils/1utilsCalculateMarketing";
import { calculateContNormal } from "../utils/2utilsCalculateNormal";
import { calculateContCosts } from "../utils/3utilsCalculateCosts";
import { calculateContCapex } from "../utils/4utilsCalculateCapex";
import { calculateContLoan } from "../utils/5utilsCalculateLoan";
import { calculateContStaff } from "../utils/6utilsCalculateStaff";

export const updateFlow = () => {
  const { nodes, edges } = useStore.getState();
  const assumptions = useAssumptionsStore.getState().assumptions;

  const sortedNodes = topologicalSortNodes(nodes, edges).nodes;

  sortedNodes.forEach((node) => {
    const assumption = assumptions.find((assumption) => assumption.contextId === node.contextId);
    if (node.type === "contMarketing") {
      const [assignments, formulas, outputs] = calculateContMarketing(nodes, node, edges, assumption);
      node.data.assignments = assignments;
      node.data.formulas = formulas;
      node.data.outputs = outputs;
    } else if (node.type === "contDirectCosts" || node.type === "contIndirectCosts") {
      const [inputsLink, inputsSelf, outputs] = calculateContCosts(nodes, node, edges, assumption);
      node.data.inputsLink = inputsLink;
      node.data.inputsSelf = inputsSelf;
      node.data.outputs = outputs;
    } else if (node.type === "contCapex") {
      const [assignments, individuals, outputs] = calculateContCapex(nodes, node, edges, assumption);
      node.data.assignments = assignments;
      node.data.individuals = individuals;
      node.data.outputs = outputs;
    } else if (node.type === "contLoan") {
      const [assignments, individuals, outputs] = calculateContLoan(nodes, node, edges, assumption);
      node.data.assignments = assignments;
      node.data.individuals = individuals;
      node.data.outputs = outputs;
    } else if (node.type === "contStaff") {
      const [assignments, individuals, outputs] = calculateContStaff(nodes, node, edges, assumption);
      node.data.assignments = assignments;
      node.data.individuals = individuals;
      node.data.outputs = outputs;
    } else {
      const [assignments, formulas, outputs] = calculateContNormal(nodes, node, edges, assumption);
      node.data.assignments = assignments;
      node.data.formulas = formulas;
      node.data.outputs = outputs;
    }
  });
};
