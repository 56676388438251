import axios from "../axios/axios";

import { useAuthContext } from "./useAuthContext";
import { useGeneralStore } from "../stores/useGeneralStore";
import { useStore } from "../pages/flow/store";
import { usePriorYearsStore } from "../stores/usePriorYearsStore";
import { useAssumptionsStore } from "../stores/useAssumptionsStore";
import { useStatementsStore } from "../stores/useStatementsStore";
import { useStatementsAnnualStore } from "../stores/useStatementsAnnualStore";
import { useActualStore } from "../stores/useActualStore";
import { useDifferencesStore } from "../stores/useDifferencesStore";
import { useValuationStore } from "../stores/useValuationStore";

export const useLogout = () => {
  const { dispatch } = useAuthContext();
  const generalLogout = useGeneralStore.getState().logout;
  const storeLogout = useStore.getState().logout;
  const priorYearsStoreLogout = usePriorYearsStore.getState().logout;
  const assumptionsStoreLogout = useAssumptionsStore.getState().logout;
  const statementsStoreLogout = useStatementsStore.getState().logout;
  const statementsAnnualStoreLogout = useStatementsAnnualStore.getState().logout;
  const actualStoreLogout = useActualStore.getState().logout;
  const differencesStoreLogout = useDifferencesStore.getState().logout;
  const valuationStoreLogout = useValuationStore.getState().logout;

  const logout = async (broadcast) => {
    try {
      await axios.get("/api/user/logout", { withCredentials: true });
    } catch (err) {
      console.log("useLogout - error");
    }

    dispatch({ type: "LOGOUT" });
    generalLogout(null);
    storeLogout();
    priorYearsStoreLogout();
    assumptionsStoreLogout();
    statementsStoreLogout();
    statementsAnnualStoreLogout();
    actualStoreLogout();
    differencesStoreLogout();
    valuationStoreLogout();

    if (broadcast) {
      const channel = new BroadcastChannel("logout");
      channel.postMessage("logout");
      channel.close();
    }
  };

  return { logout };
};
