import { SaveContext } from "./SaveContext";
import { useContext } from "react";

export const useSaveContext = () => {
  const context = useContext(SaveContext);

  if (!context) {
    throw Error("useSaveContext must be used inside a SaveContextProvider");
  }

  return context;
};
