// Topological sort (Kahn's algorithm)
export const topologicalSortNodes = (nodes, edges) => {
  // me = node
  // in front ->
  // behind <-
  const graph = {};
  const inDegree = {};

  nodes.forEach((node) => {
    graph[node.id] = [];
    inDegree[node.id] = 0;
  });

  edges.forEach((edge) => {
    const { source, target } = edge;
    // list of ppl in front of me
    graph[source].push(target);
    // number of ppl behind me
    inDegree[target] += 1;
  });

  const sortedNodes = [];
  // add to queue if no ppl behind me
  const queue = nodes.filter((node) => inDegree[node.id] === 0);

  while (queue.length > 0) {
    // remove first item from queue
    const current = queue.shift();
    // add item to sorted
    sortedNodes.push(current);

    graph[current.id].forEach((target) => {
      // number of ppl left behind me
      inDegree[target] -= 1;
      if (inDegree[target] === 0) {
        // update queue
        queue.push(nodes.find((node) => node.id === target));
      }
    });
  }

  if (sortedNodes.length === nodes.length) {
    // success
    return { success: true, nodes: sortedNodes };
  } else {
    // error - cycle detected
    return { success: false, nodes: nodes };
  }
};
