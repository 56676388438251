import { create } from "zustand";

import { updateValuation } from "../pages/flow/update/8updateValuation";

export const useValuationStore = create((set, get) => ({
  valuation: [],

  logout() {
    set({ valuation: [] });
  },

  updateValuationDetail(data) {
    set({ valuation: data });

    updateValuation();
  },
}));
