const Icon = () => (
  <svg
    className="h-4 w-auto text-gray-400"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.4"
      d="m9 6l6 6l-6 6"
    />
  </svg>
);

export default Icon;
