import { useState } from "react";

const Header = ({ setPage }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleAdd = (value) => {
    setPage(value);
    setIsOpen(false);
  };

  return (
    <div
      //
      style={{ height: "var(--header2)" }}
      className="shrink-0 flex justify-between items-center"
    >
      <div></div>
      <div className="h-full relative">
        <div
          className={`h-full w-24 gap-2 rounded-xl text-white font-semibold transition duration-300
              bg-primaryStrong hover:bg-primary flex items-center justify-center cursor-pointer`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <AddSvg classNameSvg="h-6 w-6" />
          <span>ADD</span>
        </div>

        {isOpen && (
          <div className="absolute w-48 bg-white border border-gray-100 mt-1 rounded-xl shadow-md right-0">
            <div
              className="flex items-center gap-2 px-6 py-4 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleAdd("addCompany")}
            >
              <span className="text-gray-700">Add Company</span>
            </div>
            <div
              className="flex items-center gap-2 px-6 py-4 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleAdd("addProject")}
            >
              <span className="text-gray-700">Add Project</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;

const AddSvg = ({ classNameSvg }) => {
  return (
    <svg className={classNameSvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="currentColor" d="M19 12.998h-6v6h-2v-6H5v-2h6v-6h2v6h6z" />
    </svg>
  );
};
