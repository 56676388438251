import { useValuationStore } from "../../../stores/useValuationStore";
import { useSaveContext } from "../../../saveAndRestore/useSaveContext";

import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "../0reactgrid/styles.css";
import { getColumns, getColumns2 } from "./getColumns";
import { getRowsFcf, getRowsWacc, getRowsDiscounted, getRowsTv, getRowsEnterprise } from "./getRows";

export const selector = (contextId) => (store) => ({
  updateValuationDetail: (changes) => {
    const updated = store.valuation.map((layer1) => {
      if (layer1.contextId !== contextId) return layer1;

      return {
        ...layer1,
        ...changes.reduce(
          (layer1Acc, change) => {
            const [section1, section2] = change.rowId.split(".");
            const value = change.newCell.value;
            layer1Acc[section1][section2] = value;
            return layer1Acc;
          },
          { ...layer1 }
        ),
      };
    });

    store.updateValuationDetail(updated);
  },
});

const Valuation = ({ contextId, assumption, valuation, editView }) => {
  const columns = getColumns({ assumption });
  const columns2 = getColumns2();
  const { updateValuationDetail } = useValuationStore(selector(contextId));

  const rowsFcf = getRowsFcf({ assumption, valuation });
  const rowsWacc = getRowsWacc({ valuation, editView });
  const rowsDiscounted = getRowsDiscounted({ assumption, valuation });
  const rowsTv = getRowsTv({ valuation, editView });
  const rowsEnterprise = getRowsEnterprise({ valuation });

  const { autoSave } = useSaveContext();
  const handleChanges = (changes) => {
    updateValuationDetail(changes);
    autoSave();
  };

  return (
    <>
      <h1 className="font-semibold text-2xl">Calculating Free Cash Flow</h1>
      <ReactGrid rows={rowsFcf} columns={columns} enableRangeSelection />

      <h1 className="font-semibold text-2xl">Calculating WACC</h1>
      <ReactGrid rows={rowsWacc} columns={columns2} onCellsChanged={handleChanges} enableRangeSelection />

      <h1 className="font-semibold text-2xl">Calculating Discounted FCF</h1>
      <ReactGrid rows={rowsDiscounted} columns={columns} onCellsChanged={handleChanges} enableRangeSelection />

      <h1 className="font-semibold text-2xl">Calculating Terminal Value</h1>
      <ReactGrid rows={rowsTv} columns={columns2} onCellsChanged={handleChanges} enableRangeSelection />

      <h1 className="font-semibold text-2xl">Calculating Enterprise Value</h1>
      <ReactGrid rows={rowsEnterprise} columns={columns2} onCellsChanged={handleChanges} enableRangeSelection />
    </>
  );
};

export default Valuation;
