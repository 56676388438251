import { useState } from "react";

const HeaderEditView = ({ editView, setEditView }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleEditView = (value) => {
    setEditView(value);
    setIsOpen(false);
  };

  const classNameSvg = "h-6 w-6 text-gray-500";
  const classNameSpan = "text-gray-700";

  return (
    <div className="relative">
      <button className="h-10 bg-white flex items-center justify-between gap-2" onClick={() => setIsOpen(!isOpen)}>
        {editView === "edit" && (
          <div className="flex items-center gap-2">
            <EditSvg classNameSvg={classNameSvg} />
            <span className={classNameSpan}>Edit</span>
          </div>
        )}
        {editView === "view" && (
          <div className="flex items-center gap-2">
            <ViewSvg classNameSvg={classNameSvg} />
            <span className={classNameSpan}>View Only</span>
          </div>
        )}
        <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M13.06 16.06a1.5 1.5 0 0 1-2.12 0l-5.658-5.656a1.5 1.5 0 1 1 2.122-2.121L12 12.879l4.596-4.596a1.5 1.5 0 0 1 2.122 2.12l-5.657 5.658Z"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute min-w-max bg-white border border-gray-100 rounded-md shadow-md z-30">
          <div
            className="flex items-center gap-2 p-2 hover:bg-gray-100 cursor-pointer"
            onClick={() => handleEditView("edit")}
          >
            <EditSvg classNameSvg={classNameSvg} />
            <span className={classNameSpan}>Edit</span>
          </div>
          <div
            className="flex items-center gap-2 p-2 hover:bg-gray-100 cursor-pointer"
            onClick={() => handleEditView("view")}
          >
            <ViewSvg classNameSvg={classNameSvg} />
            <span className={classNameSpan}>View Only</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderEditView;

const EditSvg = ({ classNameSvg }) => {
  return (
    <svg className={classNameSvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3 21h18M12.222 5.828L15.05 3L20 7.95l-2.828 2.828m-4.95-4.95l-5.607 5.607a1 1 0 0 0-.293.707v4.536h4.536a1 1 0 0 0 .707-.293l5.607-5.607m-4.95-4.95l4.95 4.95"
      />
    </svg>
  );
};

const ViewSvg = ({ classNameSvg }) => {
  return (
    <svg className={classNameSvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M3 13c3.6-8 14.4-8 18 0" />
        <path fill="currentColor" d="M12 17a3 3 0 1 1 0-6a3 3 0 0 1 0 6" />
      </g>
    </svg>
  );
};
