import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useStore } from "../../../flow/store";
import { useAssumptionsStore } from "../../../../stores/useAssumptionsStore";
import { useSaveContext } from "../../../../saveAndRestore/useSaveContext";
import DeleteModal from "./DeleteModal";

const Table = ({ tab, filteredContexts, setPage }) => {
  const navigate = useNavigate();
  const { onSave } = useSaveContext();

  const [modalDelete, setModalDelete] = useState(false);
  const [deleteContextItem, setDeleteContextItem] = useState(null);

  const contextId = useStore((state) => state.contextId);
  const { setContextId, deleteContext } = useStore.getState();
  const assumptions = useAssumptionsStore((state) => state.assumptions);

  const handleNavigateToFlow = (contextId) => {
    setContextId(contextId);
    navigate("/modeling");
  };

  const handleNavigateToEdit = (context) => {
    setContextId(context.id);
    if (context.data.type === "company") {
      setPage("editCompany");
    }
    if (context.data.type === "project") {
      setPage("editProject");
    }
  };

  const handleDelete = () => {
    deleteContext(deleteContextItem.id);
    setModalDelete(false);
    if (contextId === deleteContextItem.id) {
      setContextId(null);
    }
    onSave();
  };

  const handleOpenModal = (context) => {
    setDeleteContextItem(context);
    setModalDelete(true);
  };

  const handleCloseModal = () => {
    setModalDelete(false);
    setDeleteContextItem(null);
  };

  return (
    <div className="overflow-hidden rounded-2xl border border-gray-300">
      <table className="w-full text-left">
        <thead className="bg-gray-100">
          <tr className="border-b border-gray-300">
            <th className="p-4 font-medium border-r border-gray-300">Name</th>
            {tab === "company" && <th className="p-4 font-medium border-r border-gray-300 w-52">Type</th>}
            {tab === "project" && <th className="p-4 font-medium border-r border-gray-300 w-52">Owner</th>}
            <th className="p-4 font-medium border-r border-gray-300 w-36">Start Date</th>
            <th className="p-4 font-medium border-r border-gray-300 w-36">End Date</th>
            <th className="p-4 font-medium w-36">Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredContexts.map((context, index) => {
            const assumption = assumptions.find((item) => item.contextId === context.id);
            const startDate = assumption.monthsNamesBasic[0];
            const endDate = assumption.monthsNamesBasic[assumption.monthsNamesBasic.length - 1];

            return (
              <tr
                className={`${index !== filteredContexts.length - 1 ? "border-b border-gray-300" : ""} hover:bg-background`}
                key={context.id}
              >
                <td
                  className="px-8 py-4 border-r border-gray-300 cursor-pointer"
                  onClick={() => handleNavigateToFlow(context.id)}
                >
                  <span className="text-secondary font-semibold underline">{context.data.name}</span>
                </td>
                {tab === "company" && (
                  <>
                    {context.data.companyType === "parent" && (
                      <td className="p-4 text-gray-500 border-r border-gray-300">Parent Company</td>
                    )}
                    {context.data.companyType === "subsidiary" && (
                      <td className="p-4 text-gray-500 border-r border-gray-300">Subsidiary</td>
                    )}
                  </>
                )}
                {tab === "project" && (
                  <td className="p-4 text-gray-500 border-r border-gray-300">{context.data.owner}</td>
                )}
                <td className="p-4 text-gray-500 border-r border-gray-300">{startDate}</td>
                <td className="p-4 text-gray-500 border-r border-gray-300">{endDate}</td>
                <td className="p-2 flex">
                  <svg
                    className="h-10 w-auto text-gray-400 p-2 rounded-full hover:bg-gray-100 hover:text-secondary cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    onClick={() => handleNavigateToEdit(context)}
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 21h18M12.222 5.828L15.05 3L20 7.95l-2.828 2.828m-4.95-4.95l-5.607 5.607a1 1 0 0 0-.293.707v4.536h4.536a1 1 0 0 0 .707-.293l5.607-5.607m-4.95-4.95l4.95 4.95"
                    />
                  </svg>
                  <svg
                    className="h-10 w-auto text-gray-400 p-2 rounded-full hover:bg-gray-100 hover:text-secondary cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    onClick={() => handleOpenModal(context)}
                  >
                    <path
                      fill="currentColor"
                      d="M7 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h4a1 1 0 1 1 0 2h-1.069l-.867 12.142A2 2 0 0 1 17.069 22H6.93a2 2 0 0 1-1.995-1.858L4.07 8H3a1 1 0 0 1 0-2h4zm2 2h6V4H9zM6.074 8l.857 12H17.07l.857-12zM10 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1m4 0a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1"
                    />
                  </svg>
                  <DeleteModal
                    open={modalDelete}
                    onClose={handleCloseModal}
                    onConfirm={handleDelete}
                    name={deleteContextItem && deleteContextItem.data.name}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
